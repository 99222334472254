import Button from '@seaweb/coral/components/Button';
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import systemErrorImg from 'images/system_error.png';

import messages from './messages';

const ErrorContainer = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ErrorImg = styled.img`
  width: 550px;
`;

const ErrorText = styled.div`
  margin-top: 24px;
  max-width: 650px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #202325;
`;

const ErrorButton = styled(Button)`
  margin-top: 24px;
  width: 80px;
`;

export default function ErrorPage() {
  const navigate = useNavigate();
  return (
    <ErrorContainer>
      <ErrorImg src={systemErrorImg} />
      <ErrorText>
        <FormattedHTMLMessage tagName="span" {...messages.errPageText} />
        &nbsp;
        <a
          href="https://help.seatalk.io/contact"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
      </ErrorText>
      <ErrorButton onClick={() => navigate(0)}>Refresh</ErrorButton>
    </ErrorContainer>
  );
}
