import { captureException } from '@sentry/react';
import React from 'react';

import ErrorPage from 'components/Common/ErrorPage';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.hasError = false;
  }

  componentDidCatch(error) {
    this.hasError = true;
    // to render error page
    this.setState({});
    captureException(error);
  }

  render() {
    if (this.hasError) {
      // reset system error status
      this.hasError = false;
      return <ErrorPage />;
    }
    /* eslint-disable */
    return this.props.children;
  }
}

export default ErrorBoundary;
