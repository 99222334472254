/// <reference types="vite-plugin-svgr/client" />
import 'react-app-polyfill/stable';

// TODO: check this can be remove?
import 'wicg-inert';
// TODO: check this can be upgrade?
import 'sanitize.css/sanitize.css';

import { render } from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import AppLanguage from 'containers/AppLanguage';
import history from 'utils/history';
import reportWebVitals from 'utils/reportWebVitals';
import 'utils/sentry';

// Import root app based on language

import store from './configureStore';

// Import i18n messages
import './i18n';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      keepPreviousData: true,
    },
  },
});

render(
  // <React.StrictMode>
  <Provider store={store}>
    <HistoryRouter history={history}>
      <QueryClientProvider client={queryClient}>
        <AppLanguage />
      </QueryClientProvider>
    </HistoryRouter>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('app')
);

reportWebVitals();
