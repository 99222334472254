import MultiSelect, {
  MultiSelectInput,
  MultiSelectList,
  MultiSelectOption,
  MultiSelectChip,
} from '@seaweb/coral/components/MultiSelect';
import useControlProp from '@seaweb/coral/hooks/useControlProp';
import { func, arrayOf, shape, number, string, bool } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useGetRequestCamelized } from 'hooks/useGetRequest';

import messages from './messages';

const MAX_TAG = 2;

function MultiSelectPayrollCompany({
  value: valueProp,
  initialValue = [],
  onChange,
  singleLineMode,
  ...props
}) {
  const [value, setValue] = useControlProp(valueProp, initialValue, onChange);

  const [payrollsRes] = useGetRequestCamelized(
    `/org/payroll_companies/v3/dropdown`
  );
  const payrollMap = {};
  if (payrollsRes) {
    payrollsRes.payrollCompanies.forEach((pay) => {
      payrollMap[pay.id] = pay;
    });
  }

  return (
    payrollsRes && (
      <MultiSelect
        value={value.map((ele) => ele.id)}
        onChange={(val) => setValue(val.map((id) => payrollMap[id]))}
      >
        <MultiSelectInput
          data-multi-select-payroll-company-button=""
          searchElement={null}
          placeholder={<FormattedMessage {...messages.selectPayrollCompany} />}
          {...props}
        >
          {singleLineMode && (
            <>
              {value.slice(0, MAX_TAG).map((val) => (
                <MultiSelectChip key={val.id} value={val.id}>
                  {val.name}
                </MultiSelectChip>
              ))}
              {value.length > MAX_TAG && (
                <MultiSelectChip
                  index={value.length - 1}
                  value={value[value.length - 1].id}
                >
                  {`+ ${value.length - MAX_TAG}`}
                </MultiSelectChip>
              )}
            </>
          )}
        </MultiSelectInput>
        <MultiSelectList>
          {payrollsRes.payrollCompanies.map((ele) => (
            <MultiSelectOption key={ele.id} value={ele.id}>
              {ele.name}
            </MultiSelectOption>
          ))}
        </MultiSelectList>
      </MultiSelect>
    )
  );
}

const valuePropType = arrayOf(
  shape({ id: number.isRequired, name: string.isRequired })
);

MultiSelectPayrollCompany.propTypes = {
  value: valuePropType,
  initialValue: valuePropType,
  onChange: func.isRequired,
  singleLineMode: bool,
};

export default MultiSelectPayrollCompany;
