import { memo, useState, type FC, type ReactNode } from 'react';
import { InputChip, InputChipList } from '@seaweb/coral/components/Chip';
import Tooltip from '@seaweb/coral/components/Tooltip';
import ChevronDownIcon from '@seaweb/coral/icons/ChevronDown';
import ChevronUpIcon from '@seaweb/coral/icons/ChevronUp';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const StyledChip = styled(InputChip)`
  height: 32px;
  margin: 0 8px 8px 0 !important;
  padding: 0 12px;
  gap: 4px;

  > :nth-child(1) {
    margin-left: 0;
  }
`;

interface Props {
  data: {
    name: string;
    tooltip?: string;
    leftElement?: ReactNode;
  }[];
  collapseCount?: number;
}

const ChipList: FC<Props> = memo(({ data, collapseCount = 6 }) => {
  const exceedCount = data.length > collapseCount;

  const [collapsed, setCollapsed] = useState(exceedCount);

  const displayData = collapsed ? data.slice(0, collapseCount) : data;

  return (
    <InputChipList>
      {displayData.map((item, index) => {
        const chip = (
          <StyledChip key={index} index={index} leftElement={item.leftElement}>
            {item.name}
          </StyledChip>
        );
        return item.tooltip ? (
          <Tooltip key={index} title={item.tooltip}>
            {chip}
          </Tooltip>
        ) : (
          chip
        );
      })}
      {exceedCount && (
        <Tooltip
          title={
            collapsed ? (
              <FormattedMessage
                id="containers.OrgConfiguration.UserGroup.expand"
                defaultMessage="expand"
              />
            ) : (
              <FormattedMessage
                id="containers.OrgConfiguration.UserGroup.collapse"
                defaultMessage="collapse"
              />
            )
          }
        >
          <StyledChip
            index={displayData.length}
            onClick={() => setCollapsed((v) => !v)}
            style={{ cursor: 'pointer', padding: '10px' }}
          >
            {collapsed ? (
              <ChevronDownIcon size={14} />
            ) : (
              <ChevronUpIcon size={14} />
            )}
          </StyledChip>
        </Tooltip>
      )}
    </InputChipList>
  );
});

export default ChipList;
