import { init } from '@sentry/react';

import { IS_LIVE } from 'consts';

try {
  if (import.meta.env.NODE_ENV === 'production') {
    init({
      // https://sentry.io/settings/sea-fd/projects/oa-fe-live/keys/
      dsn: IS_LIVE
        ? 'https://c675d9ce5b31419baa31dd6d359bb834@o506518.ingest.sentry.io/5888451'
        : 'https://a1004ecd3d11460da96090d81140c20a@o506518.ingest.sentry.io/5888453',
      tracesSampleRate: 1.0,
      release: import.meta.env.BUILD_RELEASE,
      environment: import.meta.env.BUILD_ENV,
    });
  }
} catch (error) {
  /* eslint-disable no-console */
  console.error(error);
}
