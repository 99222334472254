import { string, number } from 'prop-types';
import React from 'react';

import useWindowSize from 'hooks/useWindowSize';

const PdfWrapper = ({ filename, width, height, ...rest }) => {
  const winWidth = useWindowSize()[0];
  const actualWidth = width || Math.min(winWidth - 600, 595);
  const actualHeight = height || actualWidth * 1.4; // by default assume portrait A4 is rendered
  return (
    <embed
      src={`${filename}#toolbar=0&scrollbar=0&page=1&view=FitH}`}
      type="application/pdf"
      width={actualWidth}
      height={actualHeight}
      {...rest}
    />
  );
};

PdfWrapper.propTypes = {
  filename: string.isRequired,
  width: number,
  height: number,
};

export default PdfWrapper;
