import createIcon from '@seaweb/coral/icons';

const Sort = createIcon(
  'Sort',
  ({ color }) => (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 4.25C3 3.83579 3.33579 3.5 3.75 3.5H12.25C12.6642 3.5 13 3.83579 13 4.25C13 4.66421 12.6642 5 12.25 5H3.75C3.33579 5 3 4.66421 3 4.25ZM3 8.25C3 7.83579 3.33579 7.5 3.75 7.5H12.25C12.6642 7.5 13 7.83579 13 8.25C13 8.66421 12.6642 9 12.25 9H3.75C3.33579 9 3 8.66421 3 8.25ZM3.75 11.5C3.33579 11.5 3 11.8358 3 12.25C3 12.6642 3.33579 13 3.75 13H12.25C12.6642 13 13 12.6642 13 12.25C13 11.8358 12.6642 11.5 12.25 11.5H3.75Z"
      fill={color}
    />
  ),
  { size: 16, viewBox: '0 0 16 16' }
);

export default Sort;
