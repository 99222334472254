import { SelectArrowIcon } from '@seaweb/coral/components/Select';
import TreeSelect, {
  TreeSelectList,
  TreeSelectSearchableNodes,
  TreeSelectSearch,
  TreeSelectHighlighter,
} from '@seaweb/coral/components/TreeSelect';
import { func, oneOf, bool, string } from 'prop-types';
import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { useTutorialContext } from 'components/Tutorial';
import { DEPARTMENT_SCOPE_PURPOSE } from 'consts';
import { useGetRequest } from 'hooks/useGetRequest';

import messages from './messages';

const tlc = (str) => str.toLocaleLowerCase();

function SelectDepartment2({
  value,
  onChange,
  purpose = DEPARTMENT_SCOPE_PURPOSE.DEFAULT,
  clearable,
  ...props
}) {
  const tutorialContext = useTutorialContext();
  const [departmentsRes] = useGetRequest(
    `/org/companies/v3/departments/tree/dropdown`,
    { purpose },
    { skip: !!tutorialContext }
  );

  const namesMap = useRef({});
  const transform = (dept) => {
    namesMap.current[dept.code] = dept.name;
    return {
      value: dept.code,
      label: <TreeSelectHighlighter text={dept.name} />,
      'data-tree-name': dept.name,
      children: dept.children.map((child) => transform(child)),
    };
  };
  const { departments = [] } = tutorialContext || departmentsRes || {};
  const treeData = departments.map(transform);

  return (
    <TreeSelect value={value} onChange={onChange}>
      <TreeSelectSearch
        placeholder={<FormattedMessage {...messages.searchDepartment} />}
        rightElement={<SelectArrowIcon clearable={clearable} />}
        {...props}
      >
        {namesMap.current[value]}
      </TreeSelectSearch>
      {treeData && (
        <TreeSelectList>
          <TreeSelectSearchableNodes
            data={treeData}
            filter={(node, query) =>
              tlc(node['data-tree-name']).includes(tlc(query))
            }
            skip={(query) => query.length < 2}
            resetExpandedTo={null}
          />
        </TreeSelectList>
      )}
    </TreeSelect>
  );
}

SelectDepartment2.propTypes = {
  value: string,
  onChange: func,
  purpose: oneOf(Object.values(DEPARTMENT_SCOPE_PURPOSE)),
  clearable: bool,
};

export default SelectDepartment2;
