import Empty from '@seaweb/coral/components/Empty';
import imgAnnouncement from '@seaweb/coral/images/Announcement.png';
import imgCalendar from '@seaweb/coral/images/Calendar.png';
import imgCompany from '@seaweb/coral/images/Company.png';
import imgEmployee from '@seaweb/coral/images/Employee.png';
import imgEmpty from '@seaweb/coral/images/Empty.png';
import imgJobTitle from '@seaweb/coral/images/JobTitle.png';
import imgLeave from '@seaweb/coral/images/Leave.png';
import imgNoData from '@seaweb/coral/images/NoData.png';
import imgOffice from '@seaweb/coral/images/Office.png';
import imgSearch from '@seaweb/coral/images/Search.png';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import messages from './messages';

const StyledEmpty = styled(Empty)`
  min-height: 300px;
`;

function EmptyPlaceholder({
  buttons,
  description,
  text,
  type,
  verticalOffset,
  ...otherProps
}) {
  const getImageSource = () => {
    switch (type) {
      case 'announcement':
        return imgAnnouncement;
      case 'calendar':
        return imgCalendar;
      case 'company':
        return imgCompany;
      case 'employee':
        return imgEmployee;
      case 'jobTitle':
        return imgJobTitle;
      case 'leave':
        return imgLeave;
      case 'office':
        return imgOffice;
      case 'empty':
      default:
        return imgEmpty;
    }
  };

  if (type === 'noData') {
    return (
      <StyledEmpty
        image={imgNoData}
        description={description || <FormattedMessage {...messages.noData} />}
        imageSize={64}
        {...otherProps}
      />
    );
  }

  if (type === 'search') {
    return (
      <StyledEmpty
        image={imgSearch}
        description={description || <FormattedMessage {...messages.noSearch} />}
        height={`calc(100vh - ${verticalOffset + 500}px)`}
        {...otherProps}
      />
    );
  }

  return (
    <StyledEmpty
      image={getImageSource()}
      title={text}
      description={description}
      // 500 is navigation height 60px + mainwrapper margin 60px + page header height 70px + buffer
      height={`calc(100vh - ${verticalOffset + 500}px)`}
      {...otherProps}
    >
      {buttons}
    </StyledEmpty>
  );
}

EmptyPlaceholder.propTypes = {
  type: PropTypes.oneOf([
    'announcement',
    'calendar',
    'company',
    'employee',
    'jobTitle',
    'leave',
    'office',
    'empty',
    'noData',
    'search',
  ]).isRequired,
  text: PropTypes.node,
  description: PropTypes.node,
  buttons: PropTypes.node,
  verticalOffset: PropTypes.number,
};

EmptyPlaceholder.defaultProps = {
  verticalOffset: 0,
};

export default EmptyPlaceholder;
