/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const STATE_KEY = 'App';

export const SET_USER_PROFILE = 'App/SET_USER_PROFILE';
export const SET_CURRENT_COMPANY = 'App/SET_CURRENT_COMPANY';
export const SET_COMPANY_INFO = 'App/SET_COMPANY_INFO';
export const SET_LANGUAGE_LIST = 'App/SET_LANGUAGE_LIST';
export const SET_LOCATION_LIST = 'App/SET_LOCATION_LIST';
export const SET_USER_ID = 'App/SET_USER_ID';
export const SET_TOAST = 'App/SET_TOAST';
export const SET_SUBMITTING = 'App/SET_SUBMITTING';

export const LOAD_LANGUAGE_LIST = 'App/LOAD_LANGUAGE_LIST';
export const LOAD_LOCATION_LIST = 'App/LOAD_LOCATION_LIST';
export const LOAD_USER_ID = 'App/LOAD_USER_ID';
