import Select, {
  SelectSearch,
  SelectArrowIcon,
  SelectList,
  SelectOption,
  SelectHighlighter,
} from '@seaweb/coral/components/Select';
import { func, number, arrayOf, shape, string } from 'prop-types';
import React, { forwardRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { STYLE } from 'components/Common/constants';
import { PERMISSION } from 'consts';
import { makeSelectPermissions } from 'containers/App/selectors';
import useErrorToast from 'hooks/useErrorToast';
import useGetCache from 'hooks/useGetCache';
import { useLoadingRequest } from 'hooks/useSafeRequest';

import EmptyPlaceholder from './EmptyPlaceholder';
import messages from './messages';

import { useTutorialContext } from '../Tutorial';

const tlc = (str) => str.toLocaleLowerCase();
const selectPermissions = makeSelectPermissions();
const usePermissions = () => useSelector(selectPermissions);

const CreatableSelectOption = styled(SelectOption)`
  display: flex;
  align-items: center;
  padding-right: 12px;
`;

const CreatableText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${STYLE.colorClick};
`;

const AddItemText = styled.div`
  white-space: nowrap;
  margin-left: 8px;
`;

const BaseSelect = forwardRef(
  ({ value, onChange, onCreate, list, ...props }, ref) => {
    const [query, setQuery] = useState('');

    const handleChange = (val) => {
      if (val === 'create') {
        onCreate(query);
      } else {
        onChange(val && Number(val.replace(/^item-/, '')));
      }
    };

    // If the value not exists in list, display placeholder
    const selectedValue =
      (list && list.some(({ id }) => id === value) && `item-${value}`) || null;

    return (
      <Select
        value={selectedValue}
        onChange={handleChange}
        searchValue={query}
        onSearch={setQuery}
      >
        <SelectSearch
          ref={ref}
          rightElement={<SelectArrowIcon clearable />}
          placeholder={<FormattedMessage {...messages.searchRank} />}
          // This is to prevent Address autocomplete (even after "off").
          // In https://stackoverflow.com/questions/59250835/google-chrome-disable-auto-completion-menu-for-manage-addresses
          // the top comments seems infeasible in this case.
          // From https://stackoverflow.com/questions/30053167/autocomplete-off-vs-false
          // the solution is to provide invalid value like "nofill".
          autoComplete="nofill"
          {...props}
        />
        {list && (
          <SelectList emptyPlaceholder={<EmptyPlaceholder type="noData" />}>
            {list
              .filter((item) => tlc(item.name).includes(tlc(query)))
              .map((item) => (
                <SelectOption key={item.id} value={`item-${item.id}`}>
                  <SelectHighlighter text={item.name} />
                </SelectOption>
              ))}

            {!!query &&
              !!onCreate &&
              !list.find((item) => tlc(item.name) === tlc(query)) && (
                <CreatableSelectOption value="create">
                  <div>&quot;</div>
                  <CreatableText>{query}</CreatableText>
                  <div>&quot;</div>
                  <AddItemText>
                    (<FormattedMessage {...messages.addItem} />)
                  </AddItemText>
                </CreatableSelectOption>
              )}
          </SelectList>
        )}
      </Select>
    );
  }
);

BaseSelect.propTypes = {
  value: number,
  onChange: func.isRequired,
  onCreate: func,
  list: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired,
    })
  ),
};

export function SelectRank({ value, onChange, ...props }) {
  const [ranksRes, refresh] = useGetCache(`/org/ranks/v3/dropdown`);

  const permissions = usePermissions();
  const canCreate = permissions.includes(PERMISSION.RANK_ADD);

  const { request, status } = useLoadingRequest();
  const errorToast = useErrorToast();

  const handleCreate = async (name) => {
    if (status === 'pending') return;

    try {
      const res = await request('/org/ranks/v3', 'POST', { name });
      refresh();
      onChange(res.rank_id);
    } catch (err) {
      errorToast(err.code);
    }
  };

  return (
    <BaseSelect
      value={value}
      onChange={onChange}
      list={ranksRes && ranksRes.ranks}
      placeholder={<FormattedMessage {...messages.searchRank} />}
      onCreate={canCreate ? handleCreate : null}
      {...props}
    />
  );
}

SelectRank.propTypes = { value: number, onChange: func };

export function SelectJobTitle({ value, onChange, ...props }) {
  const tutorialContext = useTutorialContext();

  const [jobTitleRes, refresh] = useGetCache(
    `/org/job_titles/v3/dropdown`,
    {},
    { skip: !!tutorialContext }
  );
  const { job_titles: jobTitles = [] } = tutorialContext || jobTitleRes || {};

  const permissions = usePermissions();
  const canCreate = permissions.includes(PERMISSION.JOB_TITLE_ADD);

  const { request, status } = useLoadingRequest();
  const errorToast = useErrorToast();

  const handleCreate = async (name) => {
    if (status === 'pending') return;

    try {
      const res = await request('/org/job_titles/v3', 'POST', { name });
      refresh();
      onChange(res.job_title_id);
    } catch (err) {
      errorToast(err.code);
    }
  };

  return (
    <BaseSelect
      id="select-job-title-input"
      value={value}
      onChange={onChange}
      list={jobTitles}
      placeholder={<FormattedMessage {...messages.searchJobTitle} />}
      onCreate={canCreate ? handleCreate : null}
      {...props}
    />
  );
}

SelectJobTitle.propTypes = { value: number, onChange: func };

export function SelectPayrollCompany({ value, onChange, ...props }) {
  const [payrollsRes, refresh] = useGetCache(
    `/org/payroll_companies/v3/dropdown`
  );

  const permissions = usePermissions();
  const canCreate = permissions.includes(PERMISSION.PAYROLL_ADD);

  const { request, status } = useLoadingRequest();
  const errorToast = useErrorToast();

  const handleCreate = async (name) => {
    if (status === 'pending') return;

    try {
      const res = await request('/org/payroll_companies/v3', 'POST', {
        name,
      });
      refresh();
      onChange(res.payroll_company_id);
    } catch (err) {
      errorToast(err.code);
    }
  };

  return (
    <BaseSelect
      value={value}
      onChange={onChange}
      list={payrollsRes && payrollsRes.payroll_companies}
      placeholder={<FormattedMessage {...messages.searchPayroll} />}
      onCreate={canCreate ? handleCreate : null}
      {...props}
    />
  );
}

SelectPayrollCompany.propTypes = { value: number, onChange: func };

export function SelectOfficeLocation({ value, onChange, ...props }) {
  const tutorialContext = useTutorialContext();

  const [officesRes] = useGetCache(
    `/org/offices/v3/dropdown`,
    {},
    { skip: !!tutorialContext }
  );

  const { offices = [] } = tutorialContext || officesRes || {};

  return (
    <BaseSelect
      value={value}
      onChange={onChange}
      placeholder={<FormattedMessage {...messages.searchOffice} />}
      list={offices}
      {...props}
    />
  );
}

SelectOfficeLocation.propTypes = { value: number, onChange: func };
