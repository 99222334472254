import axios from 'axios';
import { getAllTimezones as allTimeZones } from 'countries-and-timezones';

import store from 'configureStore';
import { SETTING } from 'consts';

function selectFilterOption(input, option) {
  return option.props.children.toLowerCase().includes(input.toLowerCase());
}

function getFileSize(size) {
  let convertedSize = size / 1024;
  if (convertedSize < 1000) {
    return `${Math.round(convertedSize * 10) / 10}KB`;
  }
  convertedSize /= 1024;
  return `${Math.round(convertedSize * 10) / 10}MB`;
}

function resizeImage(settings) {
  const { file, canvasWidth = 1200, canvasHeight = 1200 } = settings;

  const reader = new FileReader();
  const image = new Image();

  const canvas = document.createElement('canvas');

  const dataURItoBlob = (dataURI) => {
    const bytes = dataURI.split(',')[0].includes('base64')
      ? atob(dataURI.split(',')[1])
      : unescape(dataURI.split(',')[1]);
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);

    return new Blob([ia], { type: mime });
  };

  const resize = () => {
    let { width, height } = image;

    if (width > height) {
      if (width > canvasWidth) {
        height *= canvasWidth / width;
        width = canvasWidth;
      }
    } else if (height > canvasHeight) {
      width *= canvasHeight / height;
      height = canvasHeight;
    }

    const scale = Math.max(canvasWidth / width, canvasHeight / height);
    const scaledWidth = width * scale;
    const scaledHeight = height * scale;

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    canvas
      .getContext('2d')
      .drawImage(
        image,
        (canvas.width - scaledWidth) / 2,
        (canvas.height - scaledHeight) / 2,
        scaledWidth,
        scaledHeight
      );
    const dataUrl = canvas.toDataURL(file.type);
    return dataURItoBlob(dataUrl);
  };

  try {
    return new Promise((ok, no) => {
      if (!file.type.match(/image.*/)) {
        no(new Error('Not an image'));
        return;
      }
      reader.onload = (readerEvent) => {
        image.onload = () => ok(resize());
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
    });
  } catch (err) {
    throw new Error(err);
  }
}

async function uploadImage(type, imageFile) {
  let typeInfo;
  let resizedImage;
  switch (type) {
    case 'company': {
      typeInfo = { url: '/org/companies/logos', msg: 'company logo' };
      resizedImage = await resizeImage({ file: imageFile });
      break;
    }
    case 'announcement': {
      typeInfo = {
        url: `/announcement/attachments?type=avatar`,
        msg: 'announcement attachments',
      };
      const { width, height } = await getImageDimensions(imageFile);
      const minLength = Math.min(width, height);
      resizedImage = await cropToSquare(
        imageFile,
        minLength > 400 ? 400 : minLength
      );
      break;
    }
    default:
      throw new TypeError('This type is not supported');
  }

  const organizationCode = store
    .getState()
    .getIn(['global', 'organizationCode']);

  const formdata = new FormData();
  formdata.append('file', resizedImage);

  const response = await fetch(
    joinParameterString(`${SETTING.apiUrlPrefix}${typeInfo.url}`, {
      organization_code: organizationCode,
    }),
    {
      credentials: 'include',
      method: 'POST',
      body: formdata,
    }
  );

  if (response.status !== 200) {
    // eslint-disable-next-line no-console
    console.log(
      `Looks like there was a problem uploading ${typeInfo.msg}. Status Code: ${response.status}`
    );
  }

  return response.json();
}

async function uploadFile(url, file, setProgress) {
  const formdata = new FormData();
  formdata.append('file', file);

  try {
    const organizationCode = store
      .getState()
      .getIn(['global', 'organizationCode']);
    const response = await axios.post(
      `${SETTING.apiUrlPrefix}${url}?organization_code=${organizationCode}`,
      formdata,
      {
        withCredentials: true,
        onUploadProgress: (data) => {
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      }
    );

    return response.data;
  } catch (err) {
    const error = new Error(err.response.data);
    error.code = err.response.data.error;
    throw error;
  }
}

function getImageDimensions(file) {
  return new Promise((resolve, reject) => {
    const filereader = new FileReader();
    filereader.onload = (e) => {
      const src = e.target.result;
      const image = new Image();
      image.onload = function onload() {
        resolve({ width: image.width, height: image.height });
      };
      image.onerror = reject;
      image.src = src;
    };
    filereader.readAsDataURL(file);
  });
}

function setPageSize(size) {
  localStorage.setItem('page_size', size);
}

function getPageSize() {
  return Number(localStorage.getItem('page_size')) || SETTING.defaultPageSize;
}

function copyToClipboard(str) {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
  el.style.position = 'absolute';
  el.style.left = '-9999px'; // Move outside the screen to make it invisible
  document.body.appendChild(el);
  const selected =
    document.getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0)
      : false;
  el.select();
  document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el);
  if (selected) {
    // If a selection existed before copying
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
}

function getDeepTreeItem(tree, id, results = [], path = '') {
  tree.forEach((obj) => {
    const fullPath = path === '' ? obj.name : `${path} > ${obj.name}`;
    if (obj.id === id) {
      results.push({
        id: obj.id,
        name: obj.name,
        path: fullPath,
        in_scope: obj.in_scope,
        show_add_employee_button: obj.show_add_employee_button,
        show_export_button: obj.show_export_button,
      });
    } else if (obj.children && obj.children.length) {
      getDeepTreeItem(obj.children, id, results, fullPath);
    }
  });
  return results;
}

function getGfsLink(filename) {
  const token = store.getState().getIn(['global', 'profile', 'fs_token']);
  return `${SETTING.gfsUrlPrefix}/download/${filename}?userid=1&token=${token}`;
}

function convertMinutesToHours(val) {
  const hours = val / 60;
  const roundedHours = Math.floor(hours);
  const minutes = (hours - roundedHours) * 60;
  const roundedMinutes = Math.round(minutes);

  return { h: roundedHours, m: roundedMinutes };
}

function currencyDisplay(val, decimalPlace = 2, thousandSeparator = ',') {
  const factor = 10 ** decimalPlace;
  const roundValue = (
    Math.round((val + Number.EPSILON) * factor) / factor
  ).toFixed(decimalPlace);
  const [whole, decimal] = roundValue.split('.');
  const decimalPoint = thousandSeparator !== '.' ? '.' : ',';

  return decimal === undefined
    ? whole.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${thousandSeparator}`)
    : `${whole.replace(
        /(\d)(?=(\d{3})+(?!\d))/g,
        `$1${thousandSeparator}`
      )}${decimalPoint}${decimal}`;
}

function getAllTimeZones() {
  return Object.values(allTimeZones())
    .sort((a, b) => a.utcOffset - b.utcOffset)
    .filter(({ name }) => /^[a-zA-Z]+\/[a-zA-Z_]+$/.test(name))
    .map(({ name, utcOffsetStr }) => ({
      name,
      utcOffsetStr,
      value: name,
    }));
}

function getHelpCenterUrl() {
  const locale = localStorage.getItem('locale');
  switch (locale) {
    case 'yue':
    case 'zh':
      return 'https://help.seatalk.tw';
    case 'vi':
      return 'https://help.seatalk.vn';
    case 'id':
      return 'https://help.seatalk.co.id';
    case 'th':
      return 'https://help.seatalk.in.th';
    default:
      return 'https://help.seatalk.io';
  }
}

function encodeHTML(val) {
  const temp = document.createElement('div');
  if (temp.textContent != null) {
    temp.textContent = val;
  } else {
    temp.innerText = val;
  }
  return temp.innerHTML;
}

function decodeHTML(val) {
  const reg = /(&lt;)|(&gt;)|(&amp;)|(&quot;)|(&nbsp;)/g;
  return val.replace(reg, (match) => {
    let res = match;
    switch (match) {
      case '&lt;':
        res = '<';
        break;
      case '&gt;':
        res = '>';
        break;
      case '&amp;':
        res = '&';
        break;
      case '&quot;':
        res = '"';
        break;
      case '&nbsp;':
        res = ' ';
        break;
      default:
        break;
    }
    return res;
  });
}

function cropToSquare(
  rawImage,
  squareLength,
  outputType = 'image/jpeg',
  outputQuality = 1
) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onerror = reject;
    image.onload = () => {
      // if squareLength === 400
      // width: 1000, height: 800 -> scaledWidth: 500, scaledHeight: 400
      // width: 800, height: 1000 -> scaledWidth: 400, scaledHeight: 500
      // width: 800, height: 800 -> scaledWidth: 400, scaledHeight: 400
      // width: 600, height: 200 -> scaledWidth: 1200, scaledHeight: 400
      // width: 200, height: 600 -> scaledWidth: 400, scaledHeight: 1200
      // width: 200, height: 100 -> scaledWidth: 800, scaledHeight: 400
      // width: 100, height: 200 -> scaledWidth: 400, scaledHeight: 800
      // width: 200, height: 200 -> scaledwidth: 400, scaledHeight: 400
      const { width, height } = image;
      let scaledWidth;
      let scaledHeight;
      if (width > height) {
        const scale = height / squareLength;
        scaledHeight = squareLength;
        scaledWidth = width / scale;
      } else {
        const scale = width / squareLength;
        scaledWidth = squareLength;
        scaledHeight = height / scale;
      }

      const canvas = document.createElement('canvas');
      canvas.width = squareLength;
      canvas.height = squareLength;

      try {
        canvas.getContext('2d').drawImage(
          image,
          // 图片绘制时的 X 坐标
          ((scaledWidth - squareLength) / 2) * -1,
          // 图片绘制时的 Y 坐标
          ((scaledHeight - squareLength) / 2) * -1,
          // 该图片在 canvas 内要绘制的宽
          scaledWidth,
          // 该图片在 canvas 内要绘制的高
          scaledHeight
        );
      } catch (err) {
        return reject(err);
      }

      canvas.toBlob(
        (blob) => {
          resolve(blob);
          URL.revokeObjectURL(image.src);
        },
        outputType,
        outputQuality
      );
    };
    image.src = URL.createObjectURL(rawImage);
  });
}

function joinParameterString(url, params) {
  const appendedStr = Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
  return !appendedStr
    ? url
    : `${url}${url.includes('?') ? '&' : '?'}${appendedStr}`;
}

export const Utils = {
  selectFilterOption,
  getFileSize,
  resizeImage,
  uploadImage,
  uploadFile,
  getImageDimensions,
  setPageSize,
  getPageSize,
  copyToClipboard,
  getDeepTreeItem,
  getGfsLink,
  convertMinutesToHours,
  currencyDisplay,
  getAllTimeZones,
  getHelpCenterUrl,
  encodeHTML,
  decodeHTML,
  cropToSquare,
  joinParameterString,
};
