import useEventCallback from '@seaweb/coral/hooks/useEventCallback';
import { useState, useRef, useMemo } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { camelizeKeys } from 'utils/request';

import { useBaseSafeRequest } from './useSafeRequest';

const Cancelled = Symbol('cancelled');

/**
 * Note params default to empty object because:
 * - request.js error converting params
 * - useDeepCompareEffect warn
 *
 * This hook also handles race condition.
 *
 * @param {string} url
 * @param {Object} params
 * @param {Object} options
 */
export const useGetRequest = (url, params = {}, { skip } = {}) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const safeRequest = useBaseSafeRequest();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const cancelOngoingRef = useRef(() => {});

  const get = useEventCallback(async () => {
    if (!skip) {
      cancelOngoingRef.current();
      try {
        const res = await Promise.race([
          safeRequest(url, 'GET', params),
          new Promise((resolve, reject) => {
            cancelOngoingRef.current = () => reject(Cancelled);
          }),
        ]);
        setResponse(res);
        setError(null);
      } catch (e) {
        if (e !== Cancelled) {
          setError(e);
        }
      }
    }
  });

  useDeepCompareEffect(() => {
    get();
  }, [params, url]);

  return [response, get, error, setResponse];
};

/**
 * A custom hook used for sending GET request to server
 *
 * @usage const [response, refresh, error, setResponse] = useGetRequestCamelized(url, params, options)
 *
 * @param {string} url: The API url of the request. If url changes, the request will be triggered again to get a new response
 * @param {object} params: Object contains all parameters of the request. If any parameter changes, the request will be triggered again to get a new response
 * @param {object} options: Object contains other options for the hook. For example: { skip: true }
 *
 * @returns [{object} response, {function} refresh, {object} error, {function} setResponse]
 * - response: Camelized response, it can be a success or error response
 * - refresh: Function which is used to manually trigger the request again
 * - error: Error of the hook, not the error response
 * - setResponse: Function which is used to manually set the response
 */
export const useGetRequestCamelized = (...args) => {
  const [response, ...rets] = useGetRequest(...args);
  const camelizeResponse = useMemo(() => camelizeKeys(response), [response]);
  return [camelizeResponse, ...rets];
};

/** Retrieving data from server using 'POST' method
 * -- normally used when parameters are not primitive values */
export const usePostRequest = (url, body = {}, { skip } = {}) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const safeRequest = useBaseSafeRequest();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const cancelOngoingRef = useRef(() => {});

  const get = useEventCallback(async () => {
    if (!skip) {
      cancelOngoingRef.current();
      try {
        const res = await Promise.race([
          safeRequest(url, 'POST', body),
          new Promise((resolve, reject) => {
            cancelOngoingRef.current = () => reject(Cancelled);
          }),
        ]);
        setResponse(res);
      } catch (e) {
        if (e !== Cancelled) {
          setError(e);
        }
      }
    }
  });

  useDeepCompareEffect(() => {
    get();
  }, [body, url]);

  return [response, get, error, setResponse];
};

/**
 * A custom hook used for sending POST request to server
 *
 * @usage const [response, refresh, error, setResponse] = usePostRequestCamelized(url, body, options)
 *
 * @param {string} url: The API url of the request. If url changes, the request will be triggered again to get a new response
 * @param {object} body: Object body of API. If body changes, the request will be triggered again to get a new response
 * - Note: If needed to send parameters, do not put them in `body`. Instead, manully concat them into `url`
 * @param {object} options: Object contains other options for the hook. For example: { skip: true }
 *
 * @returns [{object} response, {function} refresh, {object} error, {function} setResponse]
 * - response: Camelized response, it can be a success or error response
 * - refresh: Function which is used to manually trigger the request again
 * - error: Error of the hook, not the error response
 * - setResponse: Function which is used to manually set the response
 */
export const usePostRequestCamelized = (...args) => {
  const [response, ...rets] = usePostRequest(...args);
  return [camelizeKeys(response), ...rets];
};
