import { InputChip } from '@seaweb/coral/components/Chip';
import MultiSelect, {
  MultiSelectInput,
  MultiSelectOption,
  MultiSelectSearch,
  Placements,
} from '@seaweb/coral/components/MultiSelect';
import { SelectVirtualizedList } from '@seaweb/coral/components/Select';
import { arrayOf, func, object } from 'prop-types';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';

import { PREDEFINED_ROLE_TYPE } from 'consts';
import { useGetRequestCamelized } from 'hooks/useGetRequest';

import EmptyPlaceholder from './EmptyPlaceholder';
import HighlightSearch from './HighlightSearch';
import messages from './messages';

const StyledInputChip = styled(InputChip)`
  max-width: 100%;
`;

const StyledMultiSelectInput = styled(MultiSelectInput)`
  width: 100%;
`;

const StyledSelectVirtualizedList = styled(SelectVirtualizedList)`
  height: fit-content !important;
  padding: 0;

  ul {
    margin-bottom: 0;

    li {
      display: flex;
      align-items: center;
      padding-right: ${({ scrollbarWidth }) => scrollbarWidth + 32}px;
    }
  }
`;

/**
 * An upgrade version for SelectRole
 */
const MultiSelectRole = forwardRef(
  ({ value, onChange, params, style }, ref) => {
    const [query, setQuery] = useState('');
    const [keyword] = useDebounce(query, 200);
    const scrollbarWidth = useMemo(getScrollbarWidth, []);

    const [roleRes] = useGetRequestCamelized(`/auth/v3/companies/roles`, {
      keyword,
      perPage: 500,
      ...params,
    });
    const { roles: allRoles = [] } = roleRes || {};

    // Exclude Super Admin role
    const roles = allRoles.filter(
      (role) => role.predefinedRoleType !== PREDEFINED_ROLE_TYPE.SUPER_ADMIN
    );
    const rolesMap = useRef({});
    roles.forEach((role) => {
      rolesMap.current[role.id] = role;
    });

    // For multi mode
    const searchRef = useRef();
    const [searchEl, setSearchEl] = useState();
    useEffect(() => setSearchEl(searchRef.current), []);

    // Remove 1 role from list
    const omitRole = (roleId) => {
      onChange(value.filter((role) => role.id !== roleId));
    };

    return (
      <MultiSelect
        value={value.map((role) => role.id)}
        onChange={(roleIds) => {
          onChange(roleIds.map((id) => rolesMap.current[id]));
        }}
        searchValue={query}
        onSearch={setQuery}
      >
        <StyledMultiSelectInput
          ref={ref}
          placeholder={<FormattedMessage {...messages.searchRole} />}
          searchElement={<MultiSelectSearch ref={searchRef} />}
          style={style}
        >
          {value &&
            value.map((role) => (
              <StyledInputChip
                key={role.id}
                onDelete={() => {
                  omitRole(role.id);
                  searchRef.current.focus();
                }}
              >
                {role.name}
              </StyledInputChip>
            ))}
        </StyledMultiSelectInput>

        <StyledSelectVirtualizedList
          key={roles}
          width={360}
          overscanCount={4}
          scrollbarWidth={scrollbarWidth}
          itemSize={(idx) => getItemSize(roles[idx]?.name, 360, scrollbarWidth)}
          emptyPlaceholder={<EmptyPlaceholder type="noData" />}
          placement={Placements.BottomStart}
          popperReference={searchEl}
          popperUpdateKey={`${value.length}`}
        >
          {roles.map((record) => (
            <MultiSelectOption key={record.id} value={record.id}>
              <HighlightSearch text={record.name} keyword={keyword} />
            </MultiSelectOption>
          ))}
        </StyledSelectVirtualizedList>
      </MultiSelect>
    );
  }
);

MultiSelectRole.propTypes = {
  value: arrayOf(object).isRequired,
  onChange: func.isRequired,
  params: object,
  style: object,
};

MultiSelectRole.defaultProps = {
  params: {},
  style: {},
};

export default MultiSelectRole;

function getScrollbarWidth() {
  const scrollEle = document.createElement('div');
  scrollEle.style.overflow = 'scroll';
  document.body.appendChild(scrollEle);
  const scrollWidth = scrollEle.offsetWidth - scrollEle.clientWidth;
  document.body.removeChild(scrollEle);
  return scrollWidth;
}

function getItemSize(content, width, scrollbarWidth) {
  if (!content) return 0;
  const ele = document.createElement('div');
  ele.style.padding = `8px ${scrollbarWidth + 32}px 8px 12px`;
  ele.style.width = `${width - scrollbarWidth}px`;
  ele.innerText = content;
  document.body.appendChild(ele);
  const height = ele.clientHeight;
  document.body.removeChild(ele);
  return height;
}
