import Button from '@seaweb/coral/components/Button';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import messages from './messages';

export function NextButton({ onClick }) {
  return (
    <Button colorType="background" onClick={onClick}>
      <FormattedMessage {...messages.next} />
    </Button>
  );
}

NextButton.propTypes = {
  onClick: PropTypes.func,
};

const CenterContentWrapper = styled.div`
  z-index: 103;
  width: 480px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px;
  background: rgba(32, 35, 37, 0.9);
  border-radius: 4px;
  margin: auto;
  color: white;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
`;

const Content = styled.div`
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
`;

const ActionWrapper = styled.div`
  text-align: center;
  margin-top: 24px;
`;

function CenterContent({ title, content, actions }) {
  useEffect(() => {
    const handleMutation = () => {
      document.activeElement.blur(); // to prevent user use tab
    };
    const observer = new MutationObserver(handleMutation);
    observer.observe(document, {
      attributes: true,
      childList: true,
      characterData: true,
      subtree: true,
    });
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <CenterContentWrapper>
      {title && <Title>{title}</Title>}
      <Content>{content}</Content>
      {actions && <ActionWrapper>{actions}</ActionWrapper>}
    </CenterContentWrapper>
  );
}

CenterContent.propTypes = {
  title: PropTypes.node,
  content: PropTypes.node.isRequired,
  actions: PropTypes.node,
};

export default CenterContent;
