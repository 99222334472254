import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { useUpdateTooltip } from './utils';

const ContentWrapper = styled.div`
  z-index: 102;
  position: fixed;
  white-space: pre-line;
`;

const StyledBox = styled.div`
  position: absolute;
  padding: 16px;
  background: rgba(32, 35, 37, 0.9);
  border-radius: 4px;
  width: 280px;

  ${({ placement }) => {
    switch (placement) {
      case TooltipContentPlacement.BottomStart:
        return css`
          left: 0;
          top: 16px;
        `;
      case TooltipContentPlacement.RightStart:
        return css`
          left: 16px;
          top: 0;
        `;
      case TooltipContentPlacement.TopStart:
        return css`
          left: 0;
          bottom: 16px;
        `;
      case TooltipContentPlacement.BottomEnd:
        return css`
          right: 0;
          top: 16px;
        `;
      case TooltipContentPlacement.LeftStart:
        return css`
          right: 16px;
          top: 0;
        `;
      case TooltipContentPlacement.TopEnd:
        return css`
          right: 0;
          bottom: 16px;
        `;
      default:
        return null;
    }
  }}
`;

const Center = styled.div`
  position: absolute;
  ${({ placement }) => {
    switch (placement) {
      case TooltipContentPlacement.BottomStart:
        return css`
          left: 0;
          bottom: 0;
        `;
      case TooltipContentPlacement.RightStart:
        return css`
          right: 0;
          top: 0;
        `;
      case TooltipContentPlacement.TopStart:
        return css`
          left: 0;
          top: 0;
        `;
      case TooltipContentPlacement.BottomEnd:
        return css`
          right: 0;
          bottom: 0;
        `;
      case TooltipContentPlacement.LeftStart:
        return css`
          left: 0;
          top: 0;
        `;
      case TooltipContentPlacement.TopEnd:
        return css`
          right: 0;
          top: 0;
        `;
      default:
        return null;
    }
  }}
`;

const Triangle = styled.div`
  position: absolute;
  ${({ placement }) => {
    const arrowDir1 = '6px solid transparent';
    const arrowDir2 = '8px solid rgba(32, 35, 37, 0.9)';
    switch (placement) {
      case TooltipContentPlacement.BottomStart:
        return css`
          left: 10px;
          top: 8px;
          border-left: ${arrowDir1};
          border-right: ${arrowDir1};
          border-bottom: ${arrowDir2};
        `;
      case TooltipContentPlacement.RightStart:
        return css`
          left: 8px;
          top: 10px;
          border-top: ${arrowDir1};
          border-bottom: ${arrowDir1};
          border-right: ${arrowDir2};
        `;
      case TooltipContentPlacement.TopStart:
        return css`
          left: 10px;
          bottom: 8px;
          border-left: ${arrowDir1};
          border-right: ${arrowDir1};
          border-top: ${arrowDir2};
        `;
      case TooltipContentPlacement.BottomEnd:
        return css`
          right: 10px;
          top: 8px;
          border-left: ${arrowDir1};
          border-right: ${arrowDir1};
          border-bottom: ${arrowDir2};
        `;
      case TooltipContentPlacement.LeftStart:
        return css`
          right: 8px;
          top: 10px;
          border-top: ${arrowDir1};
          border-bottom: ${arrowDir1};
          border-left: ${arrowDir2};
        `;
      case TooltipContentPlacement.TopEnd:
        return css`
          right: 10px;
          bottom: 8px;
          border-left: ${arrowDir1};
          border-right: ${arrowDir1};
          border-top: ${arrowDir2};
        `;
      default:
        return null;
    }
  }}
`;

const Body = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: white;
`;

export const TooltipContentPlacement = {
  BottomStart: 'bottom-start',
  BottomEnd: 'bottom-end',
  TopStart: 'top-start',
  TopEnd: 'top-end',
  LeftStart: 'left-start',
  RightStart: 'right-start',
};

const ActionWrapper = styled.div`
  text-align: right;
  margin-top: 32px;
`;

function TooltipContent({
  content,
  placement = TooltipContentPlacement.RightStart,
  actions,
  selector,
  centerStyle,
  contentStyle,
  immediate,
}) {
  const clickRef = useUpdateTooltip(selector, immediate, placement);

  return (
    <ContentWrapper ref={clickRef}>
      <Center placement={placement} style={centerStyle}>
        <StyledBox
          id="tooltip-content"
          placement={placement}
          style={contentStyle}
        >
          <Body>{content}</Body>
          {actions && <ActionWrapper>{actions}</ActionWrapper>}
        </StyledBox>
        <Triangle placement={placement} />
      </Center>
    </ContentWrapper>
  );
}

TooltipContent.propTypes = {
  content: PropTypes.node.isRequired,
  actions: PropTypes.node,
  placement: PropTypes.string,
  centerStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  selector: PropTypes.string.isRequired,
  immediate: PropTypes.bool,
};

export default TooltipContent;
