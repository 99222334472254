import MultiSelect, {
  MultiSelectInput,
  MultiSelectList,
  MultiSelectOption,
} from '@seaweb/coral/components/MultiSelect';
import useControlProp from '@seaweb/coral/hooks/useControlProp';
import { func, arrayOf, shape, number, string } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useGetRequestCamelized } from 'hooks/useGetRequest';

import messages from './messages';

function MultiSelectJobTitle({
  value: valueProp,
  initialValue = [],
  onChange,
  ...props
}) {
  const [value, setValue] = useControlProp(valueProp, initialValue, onChange);

  const [jobTitleRes] = useGetRequestCamelized(`/org/job_titles/v3/dropdown`);
  const { jobTitles = [] } = jobTitleRes || {};

  const jobTitlesMap = {};
  if (jobTitles) {
    jobTitles.forEach((ele) => {
      jobTitlesMap[ele.id] = ele;
    });
  }

  return (
    jobTitleRes && (
      <MultiSelect
        value={value.map((ele) => ele.id)}
        onChange={(val) => setValue(val.map((id) => jobTitlesMap[id]))}
      >
        <MultiSelectInput
          searchElement={null}
          placeholder={<FormattedMessage {...messages.selectJobTitle} />}
          {...props}
        />
        <MultiSelectList>
          {jobTitles.map((ele) => (
            <MultiSelectOption key={ele.id} value={ele.id}>
              {ele.name}
            </MultiSelectOption>
          ))}
        </MultiSelectList>
      </MultiSelect>
    )
  );
}

const valuePropType = arrayOf(
  shape({ id: number.isRequired, name: string.isRequired })
);

MultiSelectJobTitle.propTypes = {
  value: valuePropType,
  initialValue: valuePropType,
  onChange: func.isRequired,
};

export default MultiSelectJobTitle;
