import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { makeSelectOrganizationCode } from 'containers/App/selectors';
import OrgPlaceholder from 'images/default_company.png';
import AvatarPlaceholder from 'images/default_employee_square.png';

import { STYLE } from './constants';
import { Utils } from './Utils';

const StyledImg = styled.img`
  display: inline-block;
  margin-right: 10px;

  ${({ size, placeholder }) =>
    size !== null &&
    placeholder !== 'attachment' &&
    css`
      width: ${size}px;
      height: ${size}px;
    `}

  ${({ size, placeholder }) =>
    placeholder === 'attachment' &&
    css`
      width: ${size}px;
    `}

  background-color: ${(props) => (props.bgColor ? props.bgColor : '#f0f6fe')};
  border: ${STYLE.styleBorder};
  border-radius: ${STYLE.borderRadius};
  object-fit: ${(props) =>
    ['company', 'attachment'].includes(props.placeholder)
      ? 'contain'
      : 'cover'};
`;

function ImgWrapper(props) {
  const {
    isGfs,
    placeholder,
    size = 34,
    src,
    noParams,
    organizationCode,
    ...otherProps
  } = props;
  const currentOrganizationCode = useSelector(makeSelectOrganizationCode());

  const getDefaultPlaceholder = (placeholderType) => {
    switch (placeholderType) {
      case 'account':
      case 'company':
        return OrgPlaceholder;
      case 'avatar':
        return AvatarPlaceholder;
      default:
        return 'https://i.postimg.cc/4dBh8QY3/placeholder.png';
    }
  };

  const getImageSource = () => {
    if (noParams) return src;
    if (!src) return getDefaultPlaceholder(placeholder);

    const dimension = size * (window.devicePixelRatio || 1);
    if (isGfs) return `${Utils.getGfsLink(src)}${dimension}`;

    const code = organizationCode || currentOrganizationCode;
    return `${src}&dimension=${Math.ceil(dimension)}&organization_code=${code}`;
  };

  const handleError = (e, placeholderType) => {
    const placeholderToShow = getDefaultPlaceholder(placeholderType);
    e.target.src = placeholderToShow;
  };

  return (
    <StyledImg
      src={getImageSource()}
      onError={(e) => handleError(e, placeholder)}
      placeholder={placeholder}
      size={size}
      {...otherProps}
    />
  );
}

ImgWrapper.propTypes = {
  src: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object,
  placeholder: PropTypes.any,
  isGfs: PropTypes.bool,
  noParams: PropTypes.bool,
  organizationCode: PropTypes.string,
};

export default ImgWrapper;
