import { FormattedMessage } from 'react-intl';
import { ReactNode } from 'react';

import commonMessages from 'components/Common/messages';

export const getSelectOptions = <T extends Record<string, ReactNode>>(
  originData: T
) =>
  Object.entries(originData).map(([key, value]) => ({
    label: value,
    value: key,
  }));

/**
 * 表单必填校验通用文案
 * @param fieldName
 * @returns 'fieldName is required'
 */
export const getCommonFieldRequiredText = (fieldName: ReactNode) => (
  <FormattedMessage
    {...commonMessages.errFieldRequired}
    values={{
      fieldName,
    }}
  />
);

/**
 * 将Feature Toggle中常见的表示 开/关 的值转为 boolean
 */
export const formatToggleValToBoolean = (toggleVal) => {
  const trueValueSource = ['1', 'true', 1];
  const falseValueSource = ['0', 'false', 0];
  if (trueValueSource.includes(toggleVal)) {
    return true;
  } else if (falseValueSource.includes(toggleVal)) {
    return false;
  } else {
    throw new Error('不支持的toggleVal');
  }
};
