import Select, {
  SelectHighlighter,
  SelectList,
  SelectOption,
  SelectSearch,
} from '@seaweb/coral/components/Select';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDebounce } from 'use-debounce';

import { useGetRequestCamelized } from 'hooks/useGetRequest';

const upperCase = (str) => str.toLocaleUpperCase();

function SelectCurrency({ value, onChange, placeholder }) {
  const [response] = useGetRequestCamelized('/claim/currencies');
  const { currencies = [] } = response || {};

  const [filter, setFilter] = useState('');
  const [debouncedFilter] = useDebounce(filter, 300);

  return (
    <Select
      value={value}
      onSearch={setFilter}
      onChange={(currencyId) => {
        const { code, name } = currencies.filter(
          ({ id }) => id === currencyId
        )[0];
        onChange(currencyId, code, name);
      }}
    >
      <SelectSearch
        placeholder={placeholder}
        aria-autocomplete="none"
        fullWidth
      />
      <SelectList>
        {currencies
          .filter(
            ({ name, code }) =>
              upperCase(name).includes(upperCase(debouncedFilter)) ||
              upperCase(code).includes(upperCase(debouncedFilter))
          )
          .map((ele) => (
            <SelectOption key={ele.id} value={ele.id}>
              <SelectHighlighter text={`${ele.code} - ${ele.name}`} />
            </SelectOption>
          ))}
      </SelectList>
    </Select>
  );
}

SelectCurrency.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.node,
};

export default SelectCurrency;
