import axios from 'axios';
import { node } from 'prop-types';
import React, { useContext, createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { makeSelectUserProfile } from 'containers/App/selectors';
import { formatToggleValToBoolean } from 'utils';
import { FEATURE_TOGGLE } from 'consts';

const Context = createContext({ features: {} });

export const useFeatureToggleContext = () => useContext(Context);

export const useFeatures = (featureIds) => {
  const { features } = useFeatureToggleContext();
  return featureIds.map((id) => features[id]);
};

export const useBooleanFeatures = (featureIds) => {
  const { features } = useFeatureToggleContext();
  return featureIds.map((id) => {
    if (features[id]) {
      return formatToggleValToBoolean(features[id]);
    } else {
      return undefined;
    }
  });
};

export const useGovernanceToggle = () => {
  const [isGovernanceToggleOpen] = useBooleanFeatures([
    FEATURE_TOGGLE.CAN_SEE_GOVERNANCE,
  ]);
  return isGovernanceToggleOpen;
};

export const useCustomFieldToggle = () => {
  const [canSeeCustomField] = useBooleanFeatures([
    FEATURE_TOGGLE.CAN_SEE_CUSTOM_FIELD,
  ]);
  return canSeeCustomField;
};

export const useAvatarBadgeToggle = () => {
  const [canSeeAvatarBadge] = useBooleanFeatures([
    FEATURE_TOGGLE.CAN_SEE_AVATAR_BADGE,
  ]);
  return canSeeAvatarBadge;
};

const selectUserProfile = makeSelectUserProfile();

export const FeatureToggleProvider = ({ children }) => {
  const [features, setFeatures] = useState({});
  const { organizationCode } = useParams();
  const { employee_id: employeeId } = useSelector(selectUserProfile);

  useEffect(() => {
    if (!employeeId) return;
    axios
      // feature toggle
      .post('/api/c3dpdGNocHJv', {
        employee_code: employeeId.toString(),
        organization_code: organizationCode,
      })
      .then((res) => res.data)
      .then(({ tegglos }) => {
        if (!Array.isArray(tegglos)) throw new Error();
        const featureMap = {};
        for (const { tegglo_id, tegglo_val } of tegglos) {
          // eslint-disable-next-line camelcase
          featureMap[tegglo_id] = tegglo_val;
        }
        setFeatures(featureMap);
      })
      .catch(() => {
        /* do nothing */
      });
  }, [employeeId, organizationCode]);

  return <Context.Provider value={{ features }}>{children}</Context.Provider>;
};

FeatureToggleProvider.propTypes = {
  children: node.isRequired,
};
