import React, { lazy, Suspense } from 'react';

import { LoadingIcon } from 'components/Common';

const AppEn = lazy(() => import(/* webpackChunkName: "ENApp" */ './AppEn'));
const AppId = lazy(() => import(/* webpackChunkName: "IDApp" */ './AppId'));
const AppVi = lazy(() => import(/* webpackChunkName: "VIApp" */ './AppVi'));
const AppTh = lazy(() => import(/* webpackChunkName: "THApp" */ './AppTh'));
const AppSCn = lazy(() => import(/* webpackChunkName: "SCApp" */ './AppSCn'));
const AppTCn = lazy(() => import(/* webpackChunkName: "TWApp" */ './AppTCn'));

function AppLanguage() {
  const locale = localStorage.getItem('locale');

  const getAppLanguage = (lang) => {
    switch (lang) {
      case 'en':
        return <AppEn />;
      case 'id':
        return <AppId />;
      case 'vi':
        return <AppVi />;
      case 'th':
        return <AppTh />;
      case 'zh':
        return <AppSCn />;
      case 'yue':
        return <AppTCn />;

      default:
        return <AppEn />;
    }
  };

  return (
    <Suspense fallback={<LoadingIcon />}>{getAppLanguage(locale)}</Suspense>
  );
}

export default AppLanguage;
