import styled from 'styled-components';

import { THEME } from 'colorTheme';

const Divider = styled.div`
  margin: 24px 0;
  width: 100%;
  height: 1px;
  background-color: ${THEME.colorBorder};
`;

export default Divider;
