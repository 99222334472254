import { fromJS } from 'immutable';

import {
  SET_USER_PROFILE,
  SET_CURRENT_COMPANY,
  SET_COMPANY_INFO,
  SET_LANGUAGE_LIST,
  SET_LOCATION_LIST,
  SET_USER_ID,
  SET_TOAST,
  SET_SUBMITTING,
} from './constants';

export const initialState = fromJS({
  profile: {
    permissions: [],
    roles: [],
  },
  organizationCode: '',
  companyInfo: {},
  languageList: false,
  locationList: false,
  uid: false,
  toast: false,
  isSubmitting: false,
});

function appReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_PROFILE:
      return state.set('profile', fromJS(action.profile));
    case SET_CURRENT_COMPANY:
      return state.set('organizationCode', action.organizationCode);
    case SET_COMPANY_INFO:
      return state.set('companyInfo', action.companyInfo);
    case SET_LANGUAGE_LIST:
      return state.set('languageList', action.languageList);
    case SET_LOCATION_LIST:
      return state.set('locationList', action.locationList);
    case SET_USER_ID:
      return state.set('uid', action.uid);
    case SET_TOAST:
      return state.set('toast', {
        info: action.info,
        status: action.status,
        timestamp: action.timestamp,
      });
    case SET_SUBMITTING:
      return state.set('isSubmitting', action.bool);
    default:
      return state;
  }
}

export default appReducer;
