import Button from '@seaweb/coral/components/Button';
import IconButton from '@seaweb/coral/components/IconButton';
import CoralLinkButton from '@seaweb/coral/components/LinkButton';
import ChevronLeftIcon from '@seaweb/coral/icons/ChevronLeft';
import DeleteIcon from '@seaweb/coral/icons/Delete';
import EditIcon from '@seaweb/coral/icons/Edit';
import { object, string } from 'prop-types';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { THEME } from 'colorTheme';

import messages from './messages';

export function EditIconButton({ ...props }) {
  return (
    <IconButton tooltip={<FormattedMessage {...messages.edit} />} {...props}>
      <EditIcon />
    </IconButton>
  );
}

export function DeleteIconButton({ ...props }) {
  return (
    <IconButton tooltip={<FormattedMessage {...messages.delete} />} {...props}>
      <DeleteIcon />
    </IconButton>
  );
}

export function OutlinedLinkButton({ style, ...props }) {
  return (
    <Button
      variant="outlined"
      as={Link}
      style={{ color: THEME.colorText, ...style }}
      {...props}
    />
  );
}

OutlinedLinkButton.propTypes = {
  style: object,
};

export function ContainedLinkButton({ style, ...props }) {
  return (
    <Button as={Link} style={{ color: THEME.colorBg, ...style }} {...props} />
  );
}

ContainedLinkButton.propTypes = {
  style: object,
};

export const LinkButton = forwardRef(({ to, ...props }, ref) => {
  return to ? (
    <CoralLinkButton as={Link} to={to} ref={ref} {...props} />
  ) : (
    <CoralLinkButton ref={ref} {...props} />
  );
});

LinkButton.propTypes = {
  to: string,
};

export function BackButton({ style, ...props }) {
  return (
    <Button
      forwardedAs={Link}
      variant="text"
      colorType="text"
      leftIcon={<ChevronLeftIcon />}
      {...props}
    >
      <FormattedMessage {...messages.back} />
    </Button>
  );
}

BackButton.propTypes = {
  style: object,
};
