import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

export const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => props.maxWidth || 1176}px;
`;

class ContentWrapper extends PureComponent {
  render() {
    return <Content {...this.props}>{this.props.children}</Content>;
  }
}

ContentWrapper.propTypes = {
  children: PropTypes.any,
};

export default ContentWrapper;
