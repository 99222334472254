import Chip from '@seaweb/coral/components/Chip';
import styled from 'styled-components';

import Avatar from './Avatar';
import { type FC, type ReactNode } from 'react';

const StyledChip = styled(Chip)`
  margin: 0 16px 0 0;
  padding: 0 12px;
  background-color: #f2f3f4;
  border: none;
  max-width: 100%;

  > :nth-child(1) {
    margin-left: 0;
    margin-right: 8px;
  }

  img {
    margin: 0;
  }
`;

interface Props {
  name: ReactNode;
  avatar: string;
}

const ProfileChip: FC<Props> = ({ avatar, name }) => {
  return (
    <StyledChip
      clickable={false}
      leftElement={<Avatar size={22} src={avatar} type="round" />}
    >
      {name}
    </StyledChip>
  );
};

export default ProfileChip;
