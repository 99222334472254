import MultiSelect, {
  MultiSelectInput,
  MultiSelectList,
  MultiSelectOption,
} from '@seaweb/coral/components/MultiSelect';
import useControlProp from '@seaweb/coral/hooks/useControlProp';
import { func, arrayOf, shape, number, string } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useGetRequest } from 'hooks/useGetRequest';

import messages from './messages';

function MultiSelectRank({
  value: valueProp,
  initialValue = [],
  onChange,
  ...props
}) {
  const [value, setValue] = useControlProp(valueProp, initialValue, onChange);

  const [ranksRes] = useGetRequest(`/org/ranks/v3/dropdown`);
  const rankMap = {};
  if (ranksRes) {
    ranksRes.ranks.forEach((pay) => {
      rankMap[pay.id] = pay;
    });
  }
  return (
    ranksRes && (
      <MultiSelect
        value={value.map((ele) => ele.id)}
        onChange={(rankIds) => setValue(rankIds.map((id) => rankMap[id]))}
      >
        <MultiSelectInput
          searchElement={null}
          placeholder={<FormattedMessage {...messages.selectRank} />}
          {...props}
        />
        <MultiSelectList>
          {ranksRes.ranks.map((rank) => (
            <MultiSelectOption key={rank.id} value={rank.id}>
              {rank.name}
            </MultiSelectOption>
          ))}
        </MultiSelectList>
      </MultiSelect>
    )
  );
}

const valuePropType = arrayOf(
  shape({ id: number.isRequired, name: string.isRequired })
);

MultiSelectRank.propTypes = {
  value: valuePropType,
  initialValue: valuePropType,
  onChange: func.isRequired,
};

export default MultiSelectRank;
