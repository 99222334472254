import { createSelector } from 'reselect';

const selectRouter = (state) => state.get('router');

const selectGlobal = (state) => state.get('global');

export const makeSelectLocation = () =>
  createSelector(selectRouter, (state) => state.get('location').toJS());

export const makeSelectUserProfile = () =>
  createSelector(selectGlobal, (globalState) =>
    globalState.get('profile').toJS()
  );

export const makeSelectPermissions = () => {
  const selectUserProfile = makeSelectUserProfile();
  return createSelector(selectUserProfile, (profile) => profile.permissions);
};

export const makeUserHasUnlimitedScope = () => {
  const selectUserProfile = makeSelectUserProfile();
  return createSelector(
    selectUserProfile,
    (profile) => profile.has_unlimited_scope
  );
};

export const makeSelectOrganizationCode = () =>
  createSelector(selectGlobal, (globalState) =>
    globalState.get('organizationCode')
  );

export const makeSelectCompanyInfo = () =>
  createSelector(selectGlobal, (globalState) => globalState.get('companyInfo'));

export const makeSelectLanguageList = () =>
  createSelector(selectGlobal, (globalState) =>
    globalState.get('languageList')
  );

export const makeSelectLocationList = () =>
  createSelector(selectGlobal, (globalState) =>
    globalState.get('locationList')
  );

export const makeSelectUserId = () =>
  createSelector(selectGlobal, (state) => state.get('uid'));

export const makeSelectToast = () =>
  createSelector(selectGlobal, (state) => state.get('toast'));

export const makeSelectIsSubmitting = () =>
  createSelector(selectGlobal, (state) => state.get('isSubmitting'));
