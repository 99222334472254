import Button from '@seaweb/coral/components/Button';
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@seaweb/coral/components/Dialog';
import LargeCompleteIcon from '@seaweb/coral/components/icons/LargeComplete';
import { ProgressLine } from '@seaweb/coral/components/Progress';
import UploadFile, {
  UploadFileChildren,
  UploadFileProgress,
} from '@seaweb/coral/components/UploadFile';
import download from 'downloadjs';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { SETTING, MB } from 'consts';
import useErrorToast from 'hooks/useErrorToast';
import { useGetRequest } from 'hooks/useGetRequest';
import { useInterval } from 'hooks/useInterval';
import { useBaseSafeRequest } from 'hooks/useSafeRequest';

import { LinkButton } from './Button';
import { STYLE } from './constants';
import messages from './messages';
import { Utils } from './Utils';

const Strong = styled.p`
  font-weight: 500;
`;

const TextSuccess = styled.span`
  color: ${STYLE.colorClick};
`;

const TextFail = styled.span`
  color: ${STYLE.colorError};
`;

function DownloadFile({ jobId, prefixUrl, successCnt, failCnt, onClose }) {
  const { organizationCode } = useParams();

  return (
    <>
      <DialogContent>
        <div style={{ textAlign: 'center' }}>
          <LargeCompleteIcon style={{ opacity: 0.5 }} />
          <p style={{ margin: '10px 0 8px' }}>
            <FormattedMessage
              {...messages.importDialogImportStatus}
              values={{
                success: <TextSuccess>{successCnt}</TextSuccess>,
                fail: <TextFail>{failCnt}</TextFail>,
              }}
            />
          </p>
          {failCnt > 0 && (
            <a
              href={`${SETTING.apiUrlPrefix}${prefixUrl}/import/download?organization_code=${organizationCode}&job_id=${jobId}`}
            >
              <FormattedMessage {...messages.importDialogDownloadFailed} />
            </a>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage {...messages.done} />
        </Button>
      </DialogActions>
    </>
  );
}
DownloadFile.propTypes = {
  prefixUrl: PropTypes.string,
  jobId: PropTypes.number,
  successCnt: PropTypes.number,
  failCnt: PropTypes.number,
  onClose: PropTypes.func,
};

function ProgressPolling({ prefixUrl, jobId, onImportEnd }) {
  const toast = useErrorToast();
  const [progressRes, refresh, error] = useGetRequest(
    `${prefixUrl}/import/progress?job_id=${jobId}`,
    {}
  );
  const {
    imported_count: successCnt = 0,
    failed_count: failCnt = 0,
    percentage: importPercentage = -1,
  } = progressRes || {};
  const [isFetching, setIsFetching] = useState(false);

  // progess polling logic:
  // when there is progress percentage change sent another request immediately to get progess update
  // when no progress percentage change mean the task is queing or slow on server side, sent another request after 300ms
  useEffect(() => {
    setIsFetching(false);
  }, [progressRes]);

  useInterval(() => {
    if (!isFetching) {
      setIsFetching(true);
      refresh();
    }
  }, 300);

  useEffect(() => {
    if (error) {
      toast(error.code);
      onImportEnd(successCnt, failCnt);
    }
    if (importPercentage !== 100 && importPercentage !== -1) {
      setIsFetching(true);
      refresh();
    }
    if (importPercentage >= 100) {
      onImportEnd(successCnt, failCnt);
    }
  }, [
    importPercentage,
    refresh,
    onImportEnd,
    successCnt,
    failCnt,
    toast,
    error,
  ]);

  return (
    <DialogContent>
      <div style={{ textAlign: 'center' }}>
        <Strong style={{ marginBottom: 10 }}>
          <FormattedMessage {...messages.importingData} />
        </Strong>
        <ProgressLine value={Math.max(Math.round(importPercentage), 0)} />
        <p style={{ marginTop: 20 }}>
          <FormattedMessage
            {...messages.importDialogImportStatus}
            values={{
              success: <TextSuccess>{successCnt}</TextSuccess>,
              fail: <TextFail>{failCnt}</TextFail>,
            }}
          />
        </p>
      </div>
    </DialogContent>
  );
}
ProgressPolling.propTypes = {
  prefixUrl: PropTypes.string,
  jobId: PropTypes.number,
  onImportEnd: PropTypes.func,
};

function FileUploader({
  value,
  prefixUrl,
  onFileChange,
  onImport,
  onClose,
  instruction,
  children,
  templateUrl,
}) {
  const toast = useErrorToast();
  const [file, setFile] = useState(false);
  const [progress, setProgress] = useState(0);

  const request = useBaseSafeRequest();

  const handleDownloadTemplate = async () => {
    try {
      const res = await request(templateUrl || `${prefixUrl}/import/template`);
      const filename = res.headers
        .get('content-disposition')
        .split(';')
        .map((str) => str.trim().split('='))
        .find(([key]) => key === 'filename')[1];
      download(await res.blob(), filename, 'application/vnd.ms-excel');
    } catch (err) {
      toast(err.code);
    }
  };

  return (
    <>
      <DialogContent>
        <UploadFile
          files={file ? [file] : []}
          accept={[
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
          ]}
          maxSize={MB}
          onChange={async (files) => {
            if (files.length > 0) {
              const targetFile = files[0];
              setFile(targetFile);
              try {
                const { attachment } = await Utils.uploadFile(
                  `${prefixUrl}/upload`,
                  targetFile,
                  setProgress
                );
                onFileChange(attachment);
              } catch (err) {
                toast(err.code);
              }
            } else {
              setFile();
              onFileChange();
            }
          }}
          style={{ marginBottom: 24 }}
        >
          {(() =>
            progress > 0 && progress < 100 ? (
              <UploadFileProgress progress={progress} />
            ) : (
              <UploadFileChildren
                placeholderProps={{
                  text: <FormattedMessage {...messages.importDialogUpload} />,
                  description: (
                    <FormattedMessage {...messages.importDialogSupportType} />
                  ),
                }}
              />
            ))()}
        </UploadFile>
        <FormattedMessage {...messages.notice} />
        <ul>
          <li style={{ listStylePosition: 'inside', margin: '8px 0' }}>
            <FormattedMessage
              {...messages.importDialogMsg1}
              values={{
                download: (
                  <LinkButton
                    onClick={handleDownloadTemplate}
                    style={{ marginRight: 4 }}
                  >
                    <FormattedMessage {...messages.downloadTemplate} />
                  </LinkButton>
                ),
              }}
            />
          </li>
          {instruction.map((e, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li
              style={{ listStylePosition: 'inside', margin: '8px 0' }}
              key={index}
            >
              {e}
            </li>
          ))}
          <li style={{ listStylePosition: 'inside', margin: '8px 0' }}>
            <FormattedMessage {...messages.importDialogMsg2} />
          </li>
        </ul>
        {children}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          <FormattedMessage {...messages.cancel} />
        </Button>
        <Button onClick={onImport} disabled={!value}>
          <FormattedMessage {...messages.import} />
        </Button>
      </DialogActions>
    </>
  );
}
FileUploader.propTypes = {
  value: PropTypes.string,
  prefixUrl: PropTypes.string,
  onFileChange: PropTypes.func,
  onImport: PropTypes.func,
  onClose: PropTypes.func,
  instruction: PropTypes.node,
  children: PropTypes.node,
  templateUrl: PropTypes.string,
};

function ImportDialog(props) {
  const request = useBaseSafeRequest();
  const toast = useErrorToast();

  const {
    visible,
    title,
    prefixUrl,
    setVisible,
    onImportSuccess,
    instruction,
    templateUrl,
  } = props;
  const [attachment, setAttachment] = useState('');
  const [jobId, setJobId] = useState(false);
  const [importResult, setImportResult] = useState();
  const [isImporting, setIsImporting] = useState(false);

  return (
    <Dialog isOpen={visible} style={{ width: 480 }}>
      <DialogTitle>{title}</DialogTitle>
      {!isImporting ? (
        <FileUploader
          value={attachment}
          prefixUrl={prefixUrl}
          onFileChange={(val) => setAttachment(val)}
          onImport={async () => {
            try {
              const { job_id: newJobId } = await request(
                `${prefixUrl}/import`,
                'POST',
                {
                  attachment,
                }
              );
              setJobId(newJobId);
            } catch (err) {
              toast(err.code);
              setImportResult({ successCnt: 0, failCnt: 0 });
            }
            setIsImporting(true);
          }}
          onClose={() => setVisible(false)}
          instruction={instruction}
          templateUrl={templateUrl}
        />
      ) : (
        <>
          {!importResult ? (
            <ProgressPolling
              prefixUrl={prefixUrl}
              jobId={jobId}
              onImportEnd={(successCnt, failCnt) => {
                if (successCnt > 0) {
                  onImportSuccess(jobId);
                }
                setImportResult({ successCnt, failCnt });
              }}
            />
          ) : (
            <DownloadFile
              jobId={jobId}
              prefixUrl={prefixUrl}
              successCnt={importResult.successCnt}
              failCnt={importResult.failCnt}
              onClose={() => setVisible(false)}
            />
          )}
        </>
      )}
    </Dialog>
  );
}

ImportDialog.defaultProps = {
  instruction: [],
};

ImportDialog.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.node,
  prefixUrl: PropTypes.string,
  setVisible: PropTypes.func,
  onImportSuccess: PropTypes.func,
  instruction: PropTypes.arrayOf(PropTypes.node),
  templateUrl: PropTypes.string,
};

export default ImportDialog;
