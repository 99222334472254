import { defineMessages } from 'react-intl';

export const scope = 'components.Common';

export default defineMessages({
  total: {
    id: `${scope}.total`,
    defaultMessage: 'Total',
  },
  perPage: {
    id: `${scope}.perPage`,
    defaultMessage: 'per Page',
  },
  ok: {
    id: `${scope}.ok`,
    defaultMessage: 'OK',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Search',
  },
  enter: {
    id: `${scope}.enter`,
    defaultMessage: 'Enter',
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: 'Confirm',
  },
  noSearch: {
    id: `${scope}.noSearch`,
    defaultMessage: 'No Result',
  },
  noData: {
    id: `${scope}.noData`,
    defaultMessage: 'No data',
  },
  noEmployeeAvailable: {
    id: `${scope}.noEmployeeAvailable`,
    defaultMessage: 'No Employee Available',
  },
  addEmployeeFirst: {
    id: `${scope}.addEmployeeFirst`,
    defaultMessage: 'Add employee in the organization first',
  },
  enterPhoneNo: {
    id: `${scope}.enterPhoneNo`,
    defaultMessage: 'Enter Phone No.',
  },
  enterOfficeLocation: {
    id: `${scope}.enterOfficeLocation`,
    defaultMessage: 'Enter Office Location',
  },
  enterPayrollCompany: {
    id: `${scope}.enterPayrollCompany`,
    defaultMessage: 'Enter Payroll Company',
  },
  searchDepartment: {
    id: `${scope}.searchDepartment`,
    defaultMessage: 'Search Department',
  },
  searchJobTitle: {
    id: `${scope}.searchJobTitle`,
    defaultMessage: 'Search Job Title',
  },
  searchOffice: {
    id: `${scope}.searchOffice`,
    defaultMessage: 'Search Office',
  },
  searchPayroll: {
    id: `${scope}.searchPayroll`,
    defaultMessage: 'Search Payroll Company',
  },
  searchRank: {
    id: `${scope}.searchRank`,
    defaultMessage: 'Search Rank',
  },
  searchEmployee: {
    id: `${scope}.searchEmployee`,
    defaultMessage: 'Search Employee',
  },
  searchRole: {
    id: `${scope}.searchRole`,
    defaultMessage: 'Search Role',
  },
  errFieldRequired: {
    id: `${scope}.errFieldRequired`,
    defaultMessage: '{fieldName} is required',
  },
  errPageText: {
    id: `${scope}.errPageText`,
    defaultMessage:
      'Opps! Seems like something went wrong with our system. <br /> Please refresh the page to try again. If the problem persists, please feel free to reach out to us',
  },
  errFieldInvalid: {
    id: `${scope}.errFieldInvalid`,
    defaultMessage: '{fieldName} is invalid',
  },
  errDateFieldAfter: {
    id: `${scope}.errDateFieldAfter`,
    defaultMessage: '{fieldName} must be after {baseDate}',
  },
  errDateFieldBefore: {
    id: `${scope}.errDateFieldBefore`,
    defaultMessage: '{fieldName} must be before {baseDate}',
  },
  errDateFieldBetween: {
    id: `${scope}.errDateFieldBetween`,
    defaultMessage: '{fieldName} must be between {baseDate} and {baseDateTill}',
  },
  upload: {
    id: `${scope}.upload`,
    defaultMessage: 'Upload',
  },
  add: {
    id: `${scope}.add`,
    defaultMessage: 'Add',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit',
  },
  view: {
    id: `${scope}.view`,
    defaultMessage: 'View',
  },
  back: {
    id: `${scope}.back`,
    defaultMessage: 'Back',
  },
  bell: {
    id: `${scope}.bell`,
    defaultMessage: 'Remind user',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Invite by email',
  },
  transferAdmin: {
    id: `${scope}.transferAdmin`,
    defaultMessage: 'Admin Transfer',
  },
  defaultCalendar: {
    id: `${scope}.defaultCalendar`,
    defaultMessage: 'Set as Company Default',
  },
  approve: {
    id: `${scope}.approve`,
    defaultMessage: 'Approve',
  },
  reject: {
    id: `${scope}.reject`,
    defaultMessage: 'Reject',
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Delete',
  },
  selectCountry: {
    id: `${scope}.selectCountry`,
    defaultMessage: 'Select Country',
  },
  selectRank: {
    id: `${scope}.selectRank`,
    defaultMessage: 'Select Rank',
  },
  selectJobTitle: {
    id: `${scope}.selectJobTitle`,
    defaultMessage: 'Select Job Title',
  },
  selectOffice: {
    id: `${scope}.selectOffice`,
    defaultMessage: 'Select Office',
  },
  selectPayrollCompany: {
    id: `${scope}.selectPayrollCompany`,
    defaultMessage: 'Select Payroll Company',
  },
  filters: {
    id: `${scope}.filters`,
    defaultMessage: 'Filters',
  },
  searchAndFilters: {
    id: `${scope}.searchAndFilters`,
    defaultMessage: 'Search & Filters',
  },
  clearAll: {
    id: `${scope}.clearAll`,
    defaultMessage: 'Clear All',
  },
  filterPanelDetailsOn: {
    id: `${scope}.filterPanelDetailsOn`,
    defaultMessage: 'Details On',
  },
  filterPanelExportReport: {
    id: `${scope}.filterPanelExportReport`,
    defaultMessage: 'Export Report',
  },
  filterPanelDisplayFields: {
    id: `${scope}.filterPanelDisplayFields`,
    defaultMessage: 'Display Fields',
  },
  filterPanelFilters: {
    id: `${scope}.filterPanelFilters`,
    defaultMessage: 'Filter By',
  },
  filterPanelSelectDepartment: {
    id: `${scope}.filterPanelSelectDepartment`,
    defaultMessage: 'Department',
  },
  filterPanelSelectPayrollCompany: {
    id: `${scope}.filterPanelSelectPayrollCompany`,
    defaultMessage: 'Payroll Company',
  },
  filterPanelSelectOfficeLocation: {
    id: `${scope}.filterPanelSelectOfficeLocation`,
    defaultMessage: 'Office Location',
  },
  filterPanelSelectLeavePolicy: {
    id: `${scope}.filterPanelSelectLeavePolicy`,
    defaultMessage: 'Leave Policy',
  },
  filterPanelSelectLeaveType: {
    id: `${scope}.filterPanelSelectLeaveType`,
    defaultMessage: 'Leave Type',
  },
  filterPanelSelectStatus: {
    id: `${scope}.filterPanelSelectStatus`,
    defaultMessage: 'Status',
  },
  filterPanelSelectAll: {
    id: `${scope}.filterPanelSelectAll`,
    defaultMessage: 'All',
  },
  filterPanelSelectSelected: {
    id: `${scope}.filterPanelSelectSelected`,
    defaultMessage: '{count} Selected',
  },
  filterPanelSelectWorkGroup: {
    id: `${scope}.filterPanelSelectWorkGroup`,
    defaultMessage: 'Work Group',
  },
  preparingReport: {
    id: `${scope}.preparingReport`,
    defaultMessage: 'Report data is preparing',
  },
  downloadingReport: {
    id: `${scope}.downloadingReport`,
    defaultMessage: 'Start to download the report',
  },
  firstHalf: {
    id: `${scope}.firstHalf`,
    defaultMessage: '1st Half',
  },
  secondHalf: {
    id: `${scope}.secondHalf`,
    defaultMessage: '2nd Half',
  },
  notice: {
    id: `${scope}.notice`,
    defaultMessage: 'Notice',
  },
  done: {
    id: `${scope}.done`,
    defaultMessage: 'Done',
  },
  downloadTemplate: {
    id: `${scope}.downloadTemplate`,
    defaultMessage: 'Download Template',
  },
  import: {
    id: `${scope}.import`,
    defaultMessage: 'Import',
  },
  importingData: {
    id: `${scope}.importingData`,
    defaultMessage: 'Importing data',
  },
  importDialogImportStatus: {
    id: `${scope}.importDialogImportStatus`,
    defaultMessage: 'Import sucessfully {success} items, failed {fail} items.',
  },
  importDialogSupportType: {
    id: `${scope}.importDialogSupportType`,
    defaultMessage: 'Only support xls/xlsx, max 1MB',
  },
  importDialogUpload: {
    id: `${scope}.importDialogUpload`,
    defaultMessage: 'Browse or drag a file to this area to upload',
  },
  importDialogMsg1: {
    id: `${scope}.importDialogMsg1`,
    defaultMessage: '{download} and fill in.',
  },
  importDialogMsg2: {
    id: `${scope}.importDialogMsg2`,
    defaultMessage:
      'Upon data import failure, the system will return an excel file, which you can download to check the reason of failure.',
  },
  importDialogDownloadFailed: {
    id: `${scope}.importDialogDownloadFailed`,
    defaultMessage: 'Click to download failed data',
  },
  selectTimezone: {
    id: `${scope}.selectTimezone`,
    defaultMessage: 'Select Timezone',
  },
  leaving: {
    id: `${scope}.leaving`,
    defaultMessage: 'Leaving',
  },
  addItem: {
    id: `${scope}.addItem`,
    defaultMessage: 'Add Item',
  },
  skip: {
    id: `${scope}.skip`,
    defaultMessage: 'Skip',
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: 'Next',
  },
  gotIt: {
    id: `${scope}.gotIt`,
    defaultMessage: 'Got it',
  },
  locationSingapore: {
    id: `${scope}.locationSingapore`,
    defaultMessage: 'Singapore',
  },
  locationIndonesia: {
    id: `${scope}.locationIndonesia`,
    defaultMessage: 'Indonesia',
  },
  locationThailand: {
    id: `${scope}.locationThailand`,
    defaultMessage: 'Thailand',
  },
  locationVietnam: {
    id: `${scope}.locationVietnam`,
    defaultMessage: 'Vietnam',
  },
  locationPhilippines: {
    id: `${scope}.locationPhilippines`,
    defaultMessage: 'Philippines',
  },
  locationMalaysia: {
    id: `${scope}.locationMalaysia`,
    defaultMessage: 'Malaysia',
  },
  locationTaiwan: {
    id: `${scope}.locationTaiwan`,
    defaultMessage: 'Taiwan',
  },
  locationChina: {
    id: `${scope}.locationChina`,
    defaultMessage: 'China',
  },
  locationOthers: {
    id: `${scope}.locationOthers`,
    defaultMessage: 'Others',
  },
  employeeStatus: {
    id: `${scope}.employeeStatus`,
    defaultMessage: 'Employee Status',
  },
  new: {
    id: `${scope}.new`,
    defaultMessage: 'New',
  },
  reset: {
    id: `${scope}.reset`,
    defaultMessage: 'Reset',
  },
  month: {
    id: `${scope}.month`,
    defaultMessage: 'Month',
  },
  day: {
    id: `${scope}.day`,
    defaultMessage: 'Day',
  },
  placeholderSelect: {
    id: 'components.AppCenter.CustomApp.placeholderSelect',
    defaultMessage: 'Select',
  },
  placeholderEnter: {
    id: 'components.AppCenter.CustomApp.placeholderEnter',
    defaultMessage: 'Enter',
  },
  fieldCanNotBeEmpty: {
    id: `${scope}.fieldCanNotBeEmpty`,
    defaultMessage: '{fieldName} cannot be empty',
  },
  userIsExistingSuperAdmin: {
    id: `${scope}.userIsExistingSuperAdmin`,
    defaultMessage: 'User is an existing Super Admin',
  },
});
