import { message as AntdMessage } from 'antd';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { injectIntl, intlShape } from 'react-intl';

import messages from './messages';

import { STYLE } from '../constants';
import Icon from '../Icon';

export class ToastMessageHandler extends PureComponent {
  componentDidUpdate(prevProps) {
    const { message } = this.props;

    if (message && message.timestamp !== prevProps.message.timestamp) {
      const config = {
        content: this.messageType(),
        icon: this.iconType(message.status),
        duration: 3,
      };

      AntdMessage.open(config);
    }
  }

  messageType = () => {
    const { intl, message } = this.props;

    if (message.info && message.status === 'error') {
      if (message.info.code) {
        if (messages[`e${message.info.code}`]) {
          return intl.formatMessage(messages[`e${message.info.code}`]);
        }
        return `Error Code: ${message.info.code}`;
      }
      return intl.formatMessage(messages.accountBroadcastUnknownError);
    }
    return intl.formatMessage(messages[message.info]);
  };

  iconType = (type) => {
    switch (type) {
      case 'success':
        return <Icon name="toast_success" color={STYLE.colorSuccess} />;
      case 'info':
        return <Icon name="toast_info" color={STYLE.colorNavText} />;
      case 'warning':
        return <Icon name="toast_warning" color={STYLE.colorWarning} />;
      case 'error':
      default:
        return <Icon name="toast_error" color={STYLE.colorError} />;
    }
  };

  render() {
    return null;
  }
}

ToastMessageHandler.propTypes = {
  intl: intlShape.isRequired,
  message: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

export default injectIntl(ToastMessageHandler);
