import TextField, { TextFieldClear } from '@seaweb/coral/components/TextField';
import SearchIcon from '@seaweb/coral/icons/Search';
import { func, bool } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

function SearchBox({ onSearch, fullWidth, ...otherProps }) {
  return (
    <TextField
      style={{ width: !fullWidth && 304 }}
      leftElement={<SearchIcon />}
      placeholder={<FormattedMessage {...messages.search} />}
      onChange={(e) => onSearch(e.target.value)}
      rightElement={<TextFieldClear />}
      fullWidth={fullWidth}
      {...otherProps}
    />
  );
}

SearchBox.propTypes = {
  onSearch: func.isRequired,
  fullWidth: bool,
};

export default SearchBox;
