import { getCLS, getFID, getLCP, getFCP, getTTFB, Metric } from 'web-vitals';

import gtagHelper from 'utils/gtag';

const sendToGoogleAnalytics = ({ id, name, value }: Metric) => {
  // Assumes the global `gtag()` function exists, see:
  // https://developers.google.com/analytics/devguides/collection/gtagjs
  gtagHelper.gtag('event', name, {
    event_category: 'Web Vitals',
    // The `id` value will be unique to the current page load. When sending
    // multiple values from the same page (e.g. for CLS), Google Analytics can
    // compute a total by grouping on this ID (note: requires `eventLabel` to
    // be a dimension in your report).
    event_label: id,
    // Google Analytics metrics must be integers, so the value is rounded.
    // For CLS the value is first multiplied by 1000 for greater precision
    // (note: increase the multiplier for greater precision if needed).
    value: Math.round(name === 'CLS' ? value * 1000 : value),
    // Use a non-interaction event to avoid affecting bounce rate.
    non_interaction: true,

    // OPTIONAL: any additional params or debug info here.
    // See: https://web.dev/debug-web-vitals-in-the-field/
    // metric_rating: 'good' | 'ni' | 'poor',
    // debug_info: '...',
    // ...
  });
};

// https://web.dev/vitals/
const reportWebVitals = () => {
  const reportHandler = sendToGoogleAnalytics;
  getCLS(reportHandler);
  getFID(reportHandler);
  getFCP(reportHandler);
  getLCP(reportHandler);
  getTTFB(reportHandler);
};

export default reportWebVitals;
