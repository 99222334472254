// Cookie name used for authorization in FE and BE
export const LOGIN_COOKIE = 'portal_session';

// Session storage name for tracking login success
export const SHOULD_TRACK_SUCCESS_LOGIN_SESSION =
  'oa_should_track_login_success';

// Session storage value for tracking login success
export enum ESelectedLoginMethod {
  QR = 'qr',
  Google = 'google',
}

export const getIsTrackSuccessfulLogin = () =>
  sessionStorage.getItem(SHOULD_TRACK_SUCCESS_LOGIN_SESSION);

export const removeIsTrackSuccessfulLogin = () =>
  sessionStorage.removeItem(SHOULD_TRACK_SUCCESS_LOGIN_SESSION);
