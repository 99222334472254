import { string, number, oneOf } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { FILE_TYPES } from 'consts';

import ImgWrapper from './ImgWrapper';
import PdfWrapper from './PdfWrapper';

const StyledImgWrapper = styled(ImgWrapper)`
  margin-right: 0;
  transition: transform 0.1s ease, opacity 0.3s ease;
  max-width: calc(100vw - 600px);
`;

const LightboxItemContent = ({ src, type, size, ...rest }) => {
  switch (type) {
    case FILE_TYPES.PDF:
      return <PdfWrapper {...rest} filename={src} width={size} height={size} />;
    case FILE_TYPES.IMG:
      // ImgWrapper renders incorrectly when size is undefined
      return <StyledImgWrapper {...rest} src={src} size={size || null} />;
    default:
      return null;
  }
};

LightboxItemContent.propTypes = {
  src: string.isRequired,
  type: oneOf(Object.values(FILE_TYPES)).isRequired,
  size: number,
};

export default LightboxItemContent;
