import { defineMessages } from 'react-intl';

export const scope = 'components.CorrectionApplications';

export default defineMessages({
  employee: {
    id: `${scope}.employee`,
    defaultMessage: 'Employee',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name',
  },
  back: {
    id: `${scope}.back`,
    defaultMessage: 'Back',
  },
  correctionApplicationsDetailTitle: {
    id: `${scope}.correctionApplicationsDetailTitle`,
    defaultMessage: `{name}'s Attendance Correction Details`,
  },
  attendanceDate: {
    id: `${scope}.attendanceDate`,
    defaultMessage: 'Attendance Date',
  },
  attendanceTime: {
    id: `${scope}.attendanceTime`,
    defaultMessage: 'Attendance Time',
  },
  correctionTime: {
    id: `${scope}.correctionTime`,
    defaultMessage: 'Correction Time',
  },
  numOfClockIn: {
    id: `${scope}.numOfClockIn`,
    defaultMessage: '{num} Clock In',
  },
  numOfClockOut: {
    id: `${scope}.numOfClockOut`,
    defaultMessage: '{num} Clock Out',
  },
  firstAbbr: {
    id: `${scope}.firstAbbr`,
    defaultMessage: '1st',
  },
  secondAbbr: {
    id: `${scope}.secondAbbr`,
    defaultMessage: '2nd',
  },
  thirdAbbr: {
    id: `${scope}.thirdAbbr`,
    defaultMessage: '3rd',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
  submissionDate: {
    id: `${scope}.submissionDate`,
    defaultMessage: 'Submission Date',
  },
  approver: {
    id: `${scope}.approver`,
    defaultMessage: 'Approver',
  },
  lastApprover: {
    id: `${scope}.lastApprover`,
    defaultMessage: 'Last Approver',
  },
  approvalDate: {
    id: `${scope}.approvalDate`,
    defaultMessage: 'Approval Date',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Description',
  },
  approval: {
    id: `${scope}.approval`,
    defaultMessage: 'Approval',
  },
  applicant: {
    id: `${scope}.applicant`,
    defaultMessage: 'Applicant',
  },
  departmentHead: {
    id: `${scope}.departmentHead`,
    defaultMessage: 'Department Head',
  },
  reportingLine: {
    id: `${scope}.reportingLine`,
    defaultMessage: 'Reporting Line',
  },
  reportingManager: {
    id: `${scope}.reportingManager`,
    defaultMessage: 'Reporting Manager',
  },
  whitelist: {
    id: `${scope}.whitelist`,
    defaultMessage: 'Whitelist',
  },
  submitted: {
    id: `${scope}.submitted`,
    defaultMessage: 'Submitted',
  },
  withdraw: {
    id: `${scope}.withdraw`,
    defaultMessage: 'Withdraw',
  },
  withdrawn: {
    id: `${scope}.withdrawn`,
    defaultMessage: 'Withdrawn',
  },
  pending: {
    id: `${scope}.pending`,
    defaultMessage: 'Pending',
  },
  approved: {
    id: `${scope}.approved`,
    defaultMessage: 'Approved',
  },
  approvedBy: {
    id: `${scope}.approvedBy`,
    defaultMessage: 'Approved by {name}',
  },
  rejected: {
    id: `${scope}.rejected`,
    defaultMessage: 'Rejected',
  },
  rejectedBy: {
    id: `${scope}.rejectedBy`,
    defaultMessage: 'Rejected by {name}',
  },
  terminated: {
    id: `${scope}.terminated`,
    defaultMessage: 'Terminated',
  },
  skipped: {
    id: `${scope}.skipped`,
    defaultMessage: 'Skipped',
  },
  autoApproved: {
    id: `${scope}.autoApproved`,
    defaultMessage: 'Automatically Approved',
  },
  system: {
    id: `${scope}.system`,
    defaultMessage: 'System',
  },
  noRecord: {
    id: `${scope}.noRecord`,
    defaultMessage: 'No Record',
  },
  lateIn: {
    id: `${scope}.lateIn`,
    defaultMessage: 'Late In',
  },
  earlyOut: {
    id: `${scope}.earlyOut`,
    defaultMessage: 'Early Out',
  },
  invalid: {
    id: `${scope}.invalid`,
    defaultMessage: 'Invalid',
  },
  countersign: {
    id: `${scope}.countersign`,
    defaultMessage: 'Countersign',
  },
  preempt: {
    id: `${scope}.preempt`,
    defaultMessage: 'Preempt',
  },
  remarks: {
    id: `${scope}.remarks`,
    defaultMessage: 'Remarks: {reason}',
  },
  // APPROVAL_CHAIN_STATUS_REASON
  repeatedApprover: {
    id: `${scope}.repeatedApprover`,
    defaultMessage: 'Approver repeated from the previous step',
  },
  approverSameAsApplicant: {
    id: `${scope}.approverSameAsApplicant`,
    defaultMessage: 'Approver is the same as applicant',
  },
  reportingManagerNotAssigned: {
    id: `${scope}.reportingManagerNotAssigned`,
    defaultMessage: 'Not assigned',
  },
  departmentHeadIsNotAssigned: {
    id: `${scope}.departmentHeadIsNotAssigned`,
    defaultMessage: 'Not assigned',
  },
  approverOffBoarding: {
    id: `${scope}.approverOffBoarding`,
    defaultMessage: 'Approver is off boarding',
  },
  // approval level
  firstLevel: {
    id: `${scope}.firstLevel`,
    defaultMessage: 'Direct Reporting Manager',
  },
  secondLevel: {
    id: `${scope}.secondLevel`,
    defaultMessage: '2nd Level Reporting Manager',
  },
  thirdLevel: {
    id: `${scope}.thirdLevel`,
    defaultMessage: '3rd Level Reporting Manager',
  },
  topLevel: {
    id: `${scope}.topLevel`,
    defaultMessage: 'Top Reporting Manager',
  },
});
