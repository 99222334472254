import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@seaweb/coral/components/Table';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { STYLE } from 'components/Common/constants';

import { OutlinedLinkButton } from './Button';
import messages from './messages';

const Card = styled(TableContainer)`
  border-radius: 4px;
  border: ${STYLE.styleBorder};
  overflow: hidden;
`;

const PolicyTitle = styled.div`
  padding-right: 0;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const TableTitleCell = styled(TableCell)`
  display: flex;
  align-items: center;
  padding-right: 0;

  ::before {
    content: '';
    opacity: 0.6;
    background-color: ${STYLE.colorClick};
    position: absolute;
    height: 24px;
    width: 4px;
    top: 10px;
    left: 0;
  }
`;

const TableDescriptionCell = styled(TableCell)`
  color: ${STYLE.colorTextTertiary};
  border-bottom: none;
  padding: 24px;
`;

const TableHorizontalSplitCell = styled(TableCell).attrs({ children: <hr /> })`
  border-bottom: none;
  height: 1px;
  padding: 0 24px;

  hr {
    border-style: none;
    border-bottom: ${STYLE.styleBorder};
  }
`;

const TableActionCell = styled(TableCell)`
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: none;
`;

const ActionCell = styled.div`
  display: flex;
  margin: 8px 0;
`;

const StyledButton = styled(OutlinedLinkButton)`
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding: 4px 8px;
  border: none;
  height: 60px;
  width: 50%;
`;

const ActionTitle = styled.div`
  color: ${STYLE.colorTextSecondary};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
`;

const VerticalHr = styled.div`
  border-left: ${STYLE.styleBorder};
  margin: 8px 12px 0;
  height: 43px;
  width: 1px;
`;

const NewPolicyTag = styled.div`
  background: ${STYLE.colorClick};
  transform: rotate(-45deg);
  position: absolute;
  right: -31px;
  bottom: 7px;
  height: 22px;
  width: 95px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  text-align: center;
`;

function PolicyCard({
  name,
  description,
  tag,
  isNew,
  actionChild,
  leftChild,
  rightChild,
}) {
  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableTitleCell ellipsis>
              <PolicyTitle>{name}</PolicyTitle>
              {tag}
            </TableTitleCell>
            <TableCell width={108}>{actionChild}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <tr>
            <TableDescriptionCell colSpan={2} ellipsis>
              {description}
            </TableDescriptionCell>
          </tr>
          <tr>
            <TableHorizontalSplitCell colSpan={2} />
          </tr>
          <tr>
            <TableActionCell colSpan={2}>
              <ActionCell>
                <StyledButton to={leftChild.url} {...leftChild.props}>
                  <ActionTitle>{leftChild.name}</ActionTitle>
                  <b>{leftChild.value}</b>
                </StyledButton>
                <VerticalHr />
                <StyledButton to={rightChild.url} {...rightChild.props}>
                  <ActionTitle>{rightChild.name}</ActionTitle>
                  <b>{rightChild.value}</b>
                </StyledButton>
              </ActionCell>
            </TableActionCell>
          </tr>
        </TableBody>
      </Table>
      {isNew && (
        <NewPolicyTag>
          <FormattedMessage {...messages.new} />
        </NewPolicyTag>
      )}
    </Card>
  );
}

PolicyCard.propTypes = {
  name: PropTypes.node,
  description: PropTypes.node,
  tag: PropTypes.node,
  isNew: PropTypes.bool,
  actionChild: PropTypes.node,
  leftChild: PropTypes.shape({
    name: PropTypes.node,
    value: PropTypes.number,
    url: PropTypes.string,
    props: PropTypes.object,
  }),
  rightChild: PropTypes.shape({
    name: PropTypes.node,
    value: PropTypes.number,
    url: PropTypes.string,
    props: PropTypes.object,
  }),
};

export default PolicyCard;
