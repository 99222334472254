import { CheckboxIcon } from '@seaweb/coral/components/Checkbox';
import { InputChipList, InputChip } from '@seaweb/coral/components/Chip';
import Field from '@seaweb/coral/components/Field';
import MultiSelect, {
  MultiSelectInputText as CoralMultiSelectInputText,
  MultiSelectList,
  MultiSelectOption,
} from '@seaweb/coral/components/MultiSelect';
import Tooltip from '@seaweb/coral/components/Tooltip';
import TreeSelect, {
  TreeSelectList,
  TreeSelectNode,
  TreeSelectButton,
} from '@seaweb/coral/components/TreeSelect';
import useControlProp from '@seaweb/coral/hooks/useControlProp';
import SitemapIcon from '@seaweb/coral/icons/Sitemap';
import PropTypes from 'prop-types';
import React, { useMemo, useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { THEME } from 'colorTheme';
import {
  DEPARTMENT_SCOPE_PURPOSE,
  APPLICATION_STATUS,
  CLAIM_APPLICATION_STATUS,
  WORKGROUP_SCOPE_PURPOSE,
  EMPLOYEE_STATUS,
} from 'consts';
import claimApplicationMessages from 'containers/Claim/ClaimReports/messages';
import correctionApplicationMessages from 'containers/LeaveAttendance/AttendanceManagement/AttendanceSummary/CorrectionApplications/messages';
import { LEAVE_APPLICATION_STATUS } from 'containers/LeaveAttendance/LeaveManagement/enums';
import leaveMessages from 'containers/LeaveAttendance/LeaveManagement/LeaveSummary/messages';
import useGetCache from 'hooks/useGetCache';

import messages from './messages';

import { EmployeeStatusDisplays } from '../../containers/Organization/Employee/EmployeeForm/constants';

export const MultiSelectField = styled(Field)`
  [data-coral-field-input] {
    height: 36px;
    align-items: flex-start;
  }
`;

export const MultiSelectInputText = styled(CoralMultiSelectInputText).attrs({
  placeholder: <FormattedMessage {...messages.filterPanelSelectAll} />,
})`
  width: 100%;
`;

const StyledTreeSelectList = styled(TreeSelectList)`
  && {
    [data-coral-tree-select-node-content] {
      width: 100%;
    }
    [data-coral-select-option-selected-icon] {
      display: none;
    }
  }
`;

const StyledTreeSelectButton = styled(TreeSelectButton)`
  min-height: 36px;
  height: fit-content;
  max-height: 160px;
  z-index: 4 !important;
  overflow-y: auto;

  align-items: ${(props) => (props.isOpen ? 'inherit' : 'center')};

  span {
    color: ${THEME.colorTextTertiary};
  }
`;

export function TreeSelectDepartment({
  value,
  initialValue = [],
  onChange,
  style,
  skip,
  purpose = DEPARTMENT_SCOPE_PURPOSE.VIEW_DEPARTMENT,
}) {
  const inputRef = useRef(null);
  const [selectedDept, setSelectedDept] = useControlProp(
    value,
    initialValue,
    onChange
  );
  const [departmentRes] = useGetCache(
    `/org/companies/v3/departments/tree/dropdown`,
    {
      purpose,
      version: 'v1',
    },
    { skip }
  );

  const { treeNodes, deptDict } = useMemo(() => {
    const deptDictResult = {};
    let treeNodesResult = <></>;
    if (departmentRes && departmentRes.departments) {
      const recursion = (data) => {
        if (data.length) {
          return data.map((dep) => {
            deptDictResult[dep.id] = dep.name;
            if (dep.children && dep.children.length) {
              return (
                <TreeSelectNode
                  value={dep.id}
                  label={
                    <>
                      <CheckboxIcon checked={selectedDept.includes(dep.id)} />
                      {dep.name}
                    </>
                  }
                  key={dep.id}
                >
                  {recursion(dep.children)}
                </TreeSelectNode>
              );
            }
            return (
              <TreeSelectNode
                value={dep.id}
                label={
                  <>
                    <CheckboxIcon checked={selectedDept.includes(dep.id)} />
                    {dep.name}
                  </>
                }
                key={dep.id}
              />
            );
          });
        }
        return <></>;
      };
      treeNodesResult = recursion(departmentRes.departments);
    }
    return { treeNodes: treeNodesResult, deptDict: deptDictResult };
  }, [departmentRes, selectedDept]);

  const selectedMsg = () => {
    if (selectedDept.length > 0) {
      const msg = selectedDept.map((ele) => deptDict[ele]).join(', ') || '';
      return (
        <Tooltip title={msg}>
          <>{msg}</>
        </Tooltip>
      );
    }
    return (
      <span>
        <FormattedMessage {...messages.filterPanelSelectAll} />
      </span>
    );
  };

  const stateReducer = (state, { action, nextState }) => {
    switch (action.type) {
      case 'keyDownEnter':
      case 'optionClick':
        return {
          ...nextState,
          isOpen: true,
        };
      default:
        return nextState;
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  return (
    <MultiSelectField
      label={<FormattedMessage {...messages.filterPanelSelectDepartment} />}
    >
      <TreeSelect
        stateReducer={stateReducer}
        value={null}
        onSelect={(val) => {
          const dept = selectedDept.includes(val)
            ? [...selectedDept].filter((x) => x !== val)
            : [...selectedDept, val];
          setSelectedDept(dept);
        }}
        onIsOpenChange={setIsOpen}
        isOpen={isOpen}
      >
        <StyledTreeSelectButton
          ref={inputRef}
          isOpen={isOpen}
          style={{ width: '100%', ...style }}
        >
          {isOpen ? (
            <InputChipList>
              {selectedDept.length > 0 ? (
                selectedDept.map((id, idx) => (
                  <InputChip
                    key={id}
                    tabIndex={-1}
                    index={idx}
                    style={{ maxWidth: '100%' }}
                    onDelete={() => {
                      setSelectedDept(
                        [...selectedDept].filter((x) => x !== id)
                      );
                      inputRef.current.focus();
                    }}
                    leftElement={<SitemapIcon size={16} />}
                  >
                    {deptDict[id]}
                  </InputChip>
                ))
              ) : (
                <span style={{ marginTop: 7 }}>
                  <FormattedMessage {...messages.filterPanelSelectAll} />
                </span>
              )}
            </InputChipList>
          ) : (
            selectedMsg()
          )}
        </StyledTreeSelectButton>

        <StyledTreeSelectList
          lined
          popperUpdateKey={`${isOpen} ${selectedDept.join('-')}`}
        >
          {treeNodes}
        </StyledTreeSelectList>
      </TreeSelect>
    </MultiSelectField>
  );
}

TreeSelectDepartment.propTypes = {
  initialValue: PropTypes.arrayOf(PropTypes.number),
  value: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  style: PropTypes.object,
  skip: PropTypes.bool,
  purpose: PropTypes.number,
};

const StyledMultiSelectList = styled(MultiSelectList)`
  li {
    overflow-wrap: anywhere;
  }
`;

export function MultiSelectPayrollCompany({
  initialValue = [],
  value,
  onChange,
  skip,
}) {
  const [selected, setSelected] = useControlProp(value, initialValue, onChange);
  const [payrollRes] = useGetCache(
    `/org/payroll_companies/v3/dropdown`,
    {},
    { skip }
  );
  const { payroll_companies: payrollCompanies = [] } = payrollRes || {};

  return (
    <MultiSelectField
      label={<FormattedMessage {...messages.filterPanelSelectPayrollCompany} />}
    >
      <MultiSelect value={selected} onChange={setSelected}>
        <MultiSelectInputText />
        <StyledMultiSelectList>
          {payrollCompanies.map((ele) => (
            <MultiSelectOption key={ele.id} value={ele.id}>
              {ele.name}
            </MultiSelectOption>
          ))}
        </StyledMultiSelectList>
      </MultiSelect>
    </MultiSelectField>
  );
}

MultiSelectPayrollCompany.propTypes = {
  initialValue: PropTypes.arrayOf(PropTypes.number),
  value: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  skip: PropTypes.bool,
};

export function MultiSelectOfficeLocation({
  initialValue = [],
  value,
  onChange,
  skip,
}) {
  const [selected, setSelected] = useControlProp(value, initialValue, onChange);
  const [officeRes] = useGetCache(`/org/offices/v3/dropdown`, {}, { skip });
  const { offices = [] } = officeRes || {};

  return (
    <MultiSelectField
      label={<FormattedMessage {...messages.filterPanelSelectOfficeLocation} />}
    >
      <MultiSelect value={selected} onChange={setSelected}>
        <MultiSelectInputText />
        <StyledMultiSelectList>
          {offices.map((ele) => (
            <MultiSelectOption key={ele.id} value={ele.id}>
              {ele.name}
            </MultiSelectOption>
          ))}
        </StyledMultiSelectList>
      </MultiSelect>
    </MultiSelectField>
  );
}

MultiSelectOfficeLocation.propTypes = {
  initialValue: PropTypes.arrayOf(PropTypes.number),
  value: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  skip: PropTypes.bool,
};

export function MultiSelectLeavePolicy({
  initialValue = [],
  value,
  onChange,
  skip,
}) {
  const [selected, setSelected] = useControlProp(value, initialValue, onChange);
  const [policyRes] = useGetCache(`/leave/policies`, {}, { skip });
  const { leave_policies: policies = [] } = policyRes || {};
  return (
    <MultiSelectField
      label={<FormattedMessage {...messages.filterPanelSelectLeavePolicy} />}
    >
      <MultiSelect value={selected} onChange={setSelected}>
        <MultiSelectInputText />
        <StyledMultiSelectList>
          {policies.map((ele) => (
            <MultiSelectOption key={ele.id} value={ele.id}>
              {ele.name}
            </MultiSelectOption>
          ))}
        </StyledMultiSelectList>
      </MultiSelect>
    </MultiSelectField>
  );
}

MultiSelectLeavePolicy.propTypes = {
  initialValue: PropTypes.arrayOf(PropTypes.number),
  value: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  skip: PropTypes.bool,
};

export function MultiSelectLeaveType({
  initialValue = [],
  value,
  onChange,
  skip,
}) {
  const [selected, setSelected] = useControlProp(value, initialValue, onChange);
  const [response] = useGetCache(`/leave/types/list`, {}, { skip });
  const { leave_types: leaveTypes = [] } = response || {};
  return (
    <MultiSelectField
      label={<FormattedMessage {...messages.filterPanelSelectLeaveType} />}
    >
      <MultiSelect value={selected} onChange={setSelected}>
        <MultiSelectInputText />
        <StyledMultiSelectList>
          {leaveTypes.map((ele) => (
            <MultiSelectOption key={ele.id} value={ele.id}>
              {ele.name}
            </MultiSelectOption>
          ))}
        </StyledMultiSelectList>
      </MultiSelect>
    </MultiSelectField>
  );
}

MultiSelectLeaveType.propTypes = {
  initialValue: PropTypes.arrayOf(PropTypes.number),
  value: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  skip: PropTypes.bool,
};

export function MultiSelectLeaveStatus({ initialValue = [], value, onChange }) {
  const [selectedStatus, setSelectedStatus] = useControlProp(
    value,
    initialValue,
    onChange
  );
  const status = {
    [LEAVE_APPLICATION_STATUS.PENDING]: (
      <FormattedMessage
        {...leaveMessages.leaveApplicationStatusDisplayPending}
      />
    ),
    [LEAVE_APPLICATION_STATUS.APPROVED]: (
      <FormattedMessage
        {...leaveMessages.leaveApplicationStatusDisplayApproved}
      />
    ),
    [LEAVE_APPLICATION_STATUS.REJECTED]: (
      <FormattedMessage
        {...leaveMessages.leaveApplicationStatusDisplayRejected}
      />
    ),
    [LEAVE_APPLICATION_STATUS.TERMINATED]: (
      <FormattedMessage
        {...leaveMessages.leaveApplicationStatusDisplayTerminated}
      />
    ),
    [LEAVE_APPLICATION_STATUS.WITHDRAWN]: (
      <FormattedMessage
        {...leaveMessages.leaveApplicationStatusDisplayWithdrawn}
      />
    ),
  };
  return (
    <MultiSelectField
      label={<FormattedMessage {...messages.filterPanelSelectStatus} />}
    >
      <MultiSelect value={selectedStatus} onChange={setSelectedStatus}>
        <MultiSelectInputText />
        <StyledMultiSelectList>
          {Object.keys(status).map((key) => (
            <MultiSelectOption key={key} value={Number(key)}>
              {status[key]}
            </MultiSelectOption>
          ))}
        </StyledMultiSelectList>
      </MultiSelect>
    </MultiSelectField>
  );
}

MultiSelectLeaveStatus.propTypes = {
  initialValue: PropTypes.arrayOf(PropTypes.number),
  value: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  inputStyle: PropTypes.object,
};

export function MultiSelectAttendanceApplicationStatus({
  initialValue = [],
  value,
  onChange,
}) {
  const [selectedStatus, setSelectedStatus] = useControlProp(
    value,
    initialValue,
    onChange
  );
  const status = {
    [APPLICATION_STATUS.PENDING]: (
      <FormattedMessage {...correctionApplicationMessages.pending} />
    ),
    [APPLICATION_STATUS.APPROVED]: (
      <FormattedMessage {...correctionApplicationMessages.approved} />
    ),
    [APPLICATION_STATUS.REJECTED]: (
      <FormattedMessage {...correctionApplicationMessages.rejected} />
    ),
    [APPLICATION_STATUS.TERMINATED]: (
      <FormattedMessage {...correctionApplicationMessages.terminated} />
    ),
    [APPLICATION_STATUS.WITHDRAWN]: (
      <FormattedMessage {...correctionApplicationMessages.withdrawn} />
    ),
  };
  return (
    <MultiSelectField
      label={<FormattedMessage {...messages.filterPanelSelectStatus} />}
    >
      <MultiSelect value={selectedStatus} onChange={setSelectedStatus}>
        <MultiSelectInputText />
        <StyledMultiSelectList>
          {Object.keys(status).map((key) => (
            <MultiSelectOption key={key} value={Number(key)}>
              {status[key]}
            </MultiSelectOption>
          ))}
        </StyledMultiSelectList>
      </MultiSelect>
    </MultiSelectField>
  );
}

MultiSelectAttendanceApplicationStatus.propTypes = {
  initialValue: PropTypes.arrayOf(PropTypes.number),
  value: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
};

export function MultiSelectClaimApplicationStatus({
  initialValue = [],
  value,
  onChange,
}) {
  const [selectedStatus, setSelectedStatus] = useControlProp(
    value,
    initialValue,
    onChange
  );
  const status = {
    [CLAIM_APPLICATION_STATUS.PENDING_MANAGER]: (
      <FormattedMessage {...claimApplicationMessages.pendingManager} />
    ),
    [CLAIM_APPLICATION_STATUS.PENDING_FINANCE]: (
      <FormattedMessage {...claimApplicationMessages.pendingFinance} />
    ),
    [CLAIM_APPLICATION_STATUS.PENDING_PAYMENT]: (
      <FormattedMessage {...claimApplicationMessages.pendingPayment} />
    ),
    [CLAIM_APPLICATION_STATUS.PAID_IN_FULL]: (
      <FormattedMessage {...claimApplicationMessages.paidInFull} />
    ),
    [CLAIM_APPLICATION_STATUS.REJECTED_BY_FINANCE]: (
      <FormattedMessage {...claimApplicationMessages.rejectedByFinance} />
    ),
    [CLAIM_APPLICATION_STATUS.REJECTED]: (
      <FormattedMessage {...claimApplicationMessages.rejectedByManager} />
    ),
    [CLAIM_APPLICATION_STATUS.TERMINATED]: (
      <FormattedMessage {...claimApplicationMessages.terminated} />
    ),
  };
  return (
    <MultiSelectField
      label={<FormattedMessage {...messages.filterPanelSelectStatus} />}
    >
      <MultiSelect value={selectedStatus} onChange={setSelectedStatus}>
        <MultiSelectInputText />
        <StyledMultiSelectList>
          {Object.keys(status).map((key) => (
            <MultiSelectOption key={key} value={Number(key)}>
              {status[key]}
            </MultiSelectOption>
          ))}
        </StyledMultiSelectList>
      </MultiSelect>
    </MultiSelectField>
  );
}

MultiSelectClaimApplicationStatus.propTypes = {
  initialValue: PropTypes.arrayOf(PropTypes.number),
  value: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
};

export function MultiSelectWorkGroup({
  initialValue = [],
  value,
  onChange,
  skip,
}) {
  const [selected, setSelected] = useControlProp(value, initialValue, onChange);
  const [response] = useGetCache(
    `/work_groups/dropdown`,
    {
      purpose: WORKGROUP_SCOPE_PURPOSE.FILTER_SUMMARY,
    },
    { skip }
  );
  const { work_groups: workGroups = [] } = response || {};
  return (
    <MultiSelectField
      label={<FormattedMessage {...messages.filterPanelSelectWorkGroup} />}
    >
      <MultiSelect value={selected} onChange={setSelected}>
        <MultiSelectInputText />
        <StyledMultiSelectList>
          {workGroups.map((ele) => (
            <MultiSelectOption key={ele.id} value={ele.id}>
              {ele.name}
            </MultiSelectOption>
          ))}
        </StyledMultiSelectList>
      </MultiSelect>
    </MultiSelectField>
  );
}

MultiSelectWorkGroup.propTypes = {
  initialValue: PropTypes.arrayOf(PropTypes.number),
  value: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  skip: PropTypes.bool,
};

export function MultiSelectEmployeeStatus({
  value,
  onChange,
  listStatus,
  ...props
}) {
  return (
    <MultiSelectField
      label={<FormattedMessage {...messages.employeeStatus} />}
      {...props}
    >
      <MultiSelect value={value} onChange={onChange}>
        <MultiSelectInputText />
        <StyledMultiSelectList>
          {listStatus.map((status) => (
            <MultiSelectOption key={status} value={status}>
              {EmployeeStatusDisplays[status]}
            </MultiSelectOption>
          ))}
        </StyledMultiSelectList>
      </MultiSelect>
    </MultiSelectField>
  );
}

MultiSelectEmployeeStatus.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  listStatus: PropTypes.array,
};

MultiSelectEmployeeStatus.defaultProps = {
  listStatus: [
    EMPLOYEE_STATUS.PENDING,
    EMPLOYEE_STATUS.ACTIVE,
    EMPLOYEE_STATUS.LEAVING,
    EMPLOYEE_STATUS.INACTIVE,
  ],
};
