import { CheckboxIcon } from '@seaweb/coral/components/Checkbox';
import { InputChipList, InputChip } from '@seaweb/coral/components/Chip';
import Tooltip from '@seaweb/coral/components/Tooltip';
import TreeSelect, {
  TreeSelectList,
  TreeSelectSearchableNodes,
  TreeSelectSearch,
  TreeSelectHighlighter,
} from '@seaweb/coral/components/TreeSelect';
import useControlProp from '@seaweb/coral/hooks/useControlProp';
import SitemapIcon from '@seaweb/coral/icons/Sitemap';
import {
  arrayOf,
  number,
  func,
  oneOf,
  objectOf,
  object,
  bool,
} from 'prop-types';
import React, { forwardRef, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

import { THEME } from 'colorTheme';
import { DEPARTMENT_SCOPE_PURPOSE } from 'consts';
import { useGetRequest } from 'hooks/useGetRequest';

import EmptyPlaceholder from './EmptyPlaceholder';
import messages from './messages';

import { useTutorialContext } from '../Tutorial';

const StyledTreeSelectSearchWrapper = styled.div`
  width: ${(props) => (props.hasItem ? 'fit-content' : '200px')};
  box-shadow: none;
  background-color: transparent;

  &:focus-within,
  &:hover,
  &[data-coral-invalid] {
    box-shadow: none;
  }
  input {
    box-shadow: none;
  }
`;

const StyledInputChipList = styled(InputChipList)`
  align-items: flex-start;
  width: 304px;
  min-height: 80px;
  max-height: 160px;
  overflow-y: auto;
  padding: 0 12px;
  border-radius: 4px;
  border: 1px solid ${THEME.colorBorder};
  cursor: pointer;
  background-color: ${THEME.colorBg};
  z-index: 4;

  [data-coral-select-selected-content-wrapper],
  input {
    padding-left: 4px;
  }

  &:hover {
    border-color: ${THEME.colorBorderSecondary};
  }

  &:focus-within {
    border-color: ${THEME.colorClick};
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      border-color: ${THEME.colorClick};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      background-color: ${THEME.colorBgSecondary};
    `}
`;

const StyledTreeSelectList = styled(TreeSelectList)`
  min-width: 170px;
  max-width: 428px;
  width: min-content;
  overflow-x: auto;

  [role='treeitem'] {
    min-width: 304px;
    padding-right: 12px;

    [data-coral-tree-select-node-content] {
      svg {
        min-width: 20px;
      }

      > span {
        margin-right: 24px;
      }

      [aria-label='selected'] {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }
    }
  }
`;

const TreeSelectInput = forwardRef(
  ({ value, departmentMap, onDelete, isOpen, style, disabled }, ref) => {
    const searchRef = useRef(null);
    const lastChipRef = useRef(null);

    const handleClick = (e) => {
      if (e.target === e.currentTarget) {
        const el = searchRef.current;
        el.click();
        el.focus();
      }
    };

    const handleSearchKeyDown = (e) => {
      if (e.key === 'Backspace' && !searchRef.current.value && value.length) {
        onDelete(value[value.length - 1]);
      }
      if (
        e.key === 'ArrowLeft' &&
        !isOpen &&
        !searchRef.current.value &&
        value.length &&
        lastChipRef.current
      ) {
        lastChipRef.current.focus();
      }
    };

    const handleLastChipKeyDown = (e) => {
      if (e.key === 'ArrowRight') {
        searchRef.current.focus();
      }
    };

    return (
      <StyledInputChipList
        onClick={handleClick}
        isOpen={isOpen}
        data-multi-select-department-input=""
        style={style}
        disabled={disabled}
        ref={ref}
      >
        {value.map((id, idx) => {
          const dept = departmentMap[id];
          return dept ? (
            <Tooltip
              key={dept.id}
              title={[...dept.parents.map((node) => node.name), dept.name].join(
                ' > '
              )}
            >
              <InputChip
                key={id}
                tabIndex={-1}
                index={idx}
                ref={idx === value.length - 1 ? lastChipRef : null}
                onKeyDown={
                  idx === value.length - 1 ? handleLastChipKeyDown : null
                }
                onDelete={() => {
                  onDelete(id);
                  // Don't lose focus after deleting last chip
                  if (value.length <= 1) searchRef.current.focus();
                }}
                leftElement={<SitemapIcon size={16} />}
              >
                {dept.name}
              </InputChip>
            </Tooltip>
          ) : (
            <InputChip
              key={id}
              tabIndex={-1}
              index={idx}
              ref={idx === value.length - 1 ? lastChipRef : null}
              onKeyDown={
                idx === value.length - 1 ? handleLastChipKeyDown : null
              }
              onDelete={() => {
                onDelete(id);
                // Don't lose focus after deleting last chip
                if (value.length <= 1) searchRef.current.focus();
              }}
            />
          );
        })}

        <TreeSelectSearch
          ref={searchRef}
          rightElement={null}
          placeholder={
            !value.length && <FormattedMessage {...messages.searchDepartment} />
          }
          onKeyDown={handleSearchKeyDown}
          hasItem={!!value.length}
          wrapperProps={{ as: StyledTreeSelectSearchWrapper }}
        />
      </StyledInputChipList>
    );
  }
);

TreeSelectInput.propTypes = {
  value: arrayOf(number).isRequired,
  departmentMap: objectOf(object).isRequired,
  onDelete: func.isRequired,
  isOpen: bool.isRequired,
  renderChipContent: func,
  style: object,
  disabled: bool,
};

const tlc = (str) => str.toLocaleLowerCase();

function MultiSelectDepartment({
  departmentInfo,
  value: valueProp,
  initialValue = [],
  onChange,
  purpose = DEPARTMENT_SCOPE_PURPOSE.DEFAULT,
  renderChipContent,
  inputStyle,
  closeOnSelect,
  invalid,
  disabled,
}) {
  const tutorialContext = useTutorialContext();

  const [value, setValue] = useControlProp(valueProp, initialValue);

  const stateReducer = (state, { action, nextState }) => {
    if (closeOnSelect) return nextState;

    switch (action.type) {
      case 'keyDownEnter':
      case 'optionClick':
        return {
          ...nextState,
          isOpen: true,
        };
      default:
        return nextState;
    }
  };

  const [departmentsRes] = useGetRequest(
    `/org/companies/v3/departments/tree/dropdown`,
    { purpose, version: 'v1' },
    { skip: !!tutorialContext }
  );

  const { departments = [] } = tutorialContext || departmentsRes || {};

  const departmentMap = {};
  const register = (dept, parents) => {
    departmentMap[dept.id] = { ...dept, parents };
    dept.children.forEach((d) => register(d, [...parents, dept]));
  };
  departments.forEach((dept) => register(dept, []));

  const handleSelect = (id, type) => {
    const next = value.includes(id)
      ? value.filter((deptId) => deptId !== id)
      : [...value, id];
    setValue(next);
    if (onChange)
      onChange(
        next,
        { id, type },
        next.map(
          (deptId) =>
            departmentMap[deptId] ||
            departmentInfo?.find((ele) => ele.id === deptId)
        )
      );
  };

  const transform = (dept) => ({
    value: dept.id,
    label: (
      <>
        <CheckboxIcon checked={value.includes(dept.id)} />
        <TreeSelectHighlighter text={dept.name} />
      </>
    ),
    'data-tree-name': dept.name,
    children: dept.children.map((d) => transform(d)),
  });
  const treeData = departments.map(transform);

  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);

  return (
    <TreeSelect
      value={null}
      onSelect={(id) => handleSelect(id, 'option')}
      stateReducer={stateReducer}
      isOpen={isOpen}
      onIsOpenChange={setIsOpen}
    >
      <TreeSelectInput
        value={value.filter((id) => !!departmentMap[id])}
        onDelete={(id) => handleSelect(id, 'chip')}
        departmentMap={departmentMap}
        isOpen={isOpen}
        renderChipContent={renderChipContent}
        style={
          invalid
            ? { ...inputStyle, borderColor: THEME.colorError }
            : inputStyle
        }
        disabled={disabled}
        ref={inputRef}
      />
      {treeData && (
        <StyledTreeSelectList
          lined
          popperReference={inputRef.current}
          popperUpdateKey={value.join('-')}
          emptyPlaceholder={<EmptyPlaceholder type="noData" />}
          placement="bottom-start"
        >
          <TreeSelectSearchableNodes
            data={treeData}
            filter={(node, query) =>
              tlc(node['data-tree-name']).includes(tlc(query))
            }
            skip={(query) => query.length < 2}
            resetExpandedTo={null}
          />
        </StyledTreeSelectList>
      )}
    </TreeSelect>
  );
}

MultiSelectDepartment.propTypes = {
  departmentInfo: arrayOf(object),
  value: arrayOf(number),
  initialValue: arrayOf(number),
  onChange: func,
  purpose: oneOf(Object.values(DEPARTMENT_SCOPE_PURPOSE)),
  renderChipContent: func,
  inputStyle: object,
  closeOnSelect: bool,
  invalid: bool,
  disabled: bool,
};

export default MultiSelectDepartment;
