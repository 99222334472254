import useWarnContext from '@seaweb/coral/hooks/useWarnContext';
import hash from 'object-hash';
import { node } from 'prop-types';
import React, { createContext, useCallback, useRef } from 'react';

const CacheContext = createContext(null);
CacheContext.displayName = 'CacheContext';
export const useCacheContext = () => useWarnContext(CacheContext);

const join = (url, params) => `${url}?${hash(params)}`;

/**
 * Use url + params as key.
 *
 * This component can be even more powerful if each `CacheProvider` also
 * checks the parent if cache exists.
 *
 * CacheProvider is much better than using global because now you can compose
 * where the cache should live:
 * You can put the Provider inside Leave Summary to optimize, but once you
 * go somewhere else, it's automatically cleared. Say you want to share some caches
 * with Leave Policy, you can wrap another higher one.
 */
function CacheProvider({ children }) {
  const cacheRef = useRef({});
  const ongoingRef = useRef({});

  const setCache = useCallback((url, params, value) => {
    cacheRef.current[join(url, params)] = { value };
  }, []);

  const getCache = useCallback(
    async (url, params, fallbackGet) => {
      const key = join(url, params);
      const cachedValue = cacheRef.current[key];
      if (cachedValue) {
        return cachedValue.value;
      }

      const ongoing = ongoingRef.current[key];
      if (ongoing) {
        return ongoing.promise;
      }

      const promise = fallbackGet();
      ongoingRef.current[url] = { promise };

      const result = await promise;
      setCache(url, params, result);

      return result;
    },
    [setCache]
  );

  const clearCache = useCallback((url) => {
    const result = { ...cacheRef.current };
    Object.keys(cacheRef.current).forEach((key) => {
      if (key.startsWith(url)) {
        delete result[key];
      }
    });
    cacheRef.current = result;
  }, []);

  return (
    <CacheContext.Provider value={{ getCache, clearCache }}>
      {children}
    </CacheContext.Provider>
  );
}

CacheProvider.propTypes = {
  children: node.isRequired,
};

export default CacheProvider;
