// theme variables
export const THEME = {
  colorApprovedBtnBG: '#f3fcf4',
  colorApprovedBtnBorder: '#13c429',
  colorApprovedBtnText: '#16c42a',
  colorApprovedBtnHover: 'rgba(19, 196, 41, 0.2)',
  colorRejectedBtnBG: '#fef4f3',
  colorRejectedBtnBorder: '#f5212d',
  colorRejectedBtnText: '#f5222d',
  colorRejectedBtnHover: 'rgba(245, 235, 235, 1)',
  colorGreyText: '#333333',
  colorTabBG: '#e8f4fe',
  colorClick: '#1b92f5',
  colorClickDisabled: 'rgba(27, 146, 245, 0.4)', // Same as colorClick but with 40% opacity (alpha)
  colorClickHover: '#047de2',
  colorText: '#293449',
  colorTextSecondary: '#5E6776',
  colorTextTertiary: '#9499A4',
  colorTextDisabled: '#C9CCD1',
  colorIcon: '#5E6776',
  colorTableIcon: '#c2d6f1',
  colorBg: '#FFF',
  colorBgSecondary: '#F2F3F4',
  colorTableBg: '#F8FCFF',
  colorTableHeaderBg: '#F9FAFD',
  colorTablePartialBorder: '#E8E9EB',
  colorTreeHoverBg: 'rgba(220, 231, 245, 0.3)',
  colorSubNavBg: 'rgba(220, 231, 245, 0.4)',
  colorBorder: '#E0E2E5',
  colorBorderSecondary: '#ADB2BA',
  colorTableBorder: 'rgba(234,240,254,0.5)',
  colorCheckboxBorder: '#e3e8f6',
  colorBorderBlue: '#e7eaf3',
  colorHover: '#eef3fa',
  colorTableHover: 'rgba(238, 243, 250, 0.5)',
  colorNavBg: '#293449',
  colorNavText: '#fff',
  colorMenuText: 'rgba(255, 255, 255, 0.5)',
  colorMenuActiveBg: '#393953',
  colorToastBg: 'rgba(83, 83, 105, 0.8)',
  colorError: '#f5222d',
  colorAbnormal: '#722ed1',
  colorSuccess: '#16c42a',
  colorWarning: '#f5ac1b',
  colorWarningBg: 'rgba(255, 215, 76, 0.2)',
  colorPending: '#ff861c',
  borderRadius: '4px',
  boxShadowModal: '0 5px 30px 0 rgba(97, 97, 123, 0.1)',
  spacing: '24px',
};

/* 
  Overwrite antd variables to follow SeaTalk theme,
  full variables list at:
  https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
*/
export const modifiedAntdVariables = {
  // color
  'primary-color': THEME.colorClick,
  'link-color': THEME.colorClick,
  'link-hover-color': THEME.colorClickHover,
  'link-active-color': THEME.colorClickHover,
  'success-color': THEME.colorSuccess,
  'warning-color': THEME.colorError,
  'error-color': THEME.colorError,
  // basic
  'heading-color': THEME.colorText,
  'text-color': THEME.colorText,
  'text-color-secondary': THEME.colorTextSecondary,
  'border-radius-base': THEME.borderRadius,
  'border-color-base': THEME.colorBorder,
  'item-hover-bg': THEME.colorHover,
  // layout
  'layout-body-background': '#fff',
  'layout-header-background': THEME.colorNavBg,
  'layout-header-height': '60px',
  'layout-header-padding': `0 ${THEME.spacing}`,
  'layout-sider-background': '#fff',
  // table
  'table-header-bg': '#F8FCFF',
  'table-row-hover-bg': THEME.colorTableHover,
  'table-selected-row-bg': 'none',
  // 'table-padding-vertical': 0,
  'table-padding-horizontal': '12px',
  // input
  'input-height-base': '36px',
  'input-padding-vertical-base': '8px',
  'input-padding-horizontal-base': '16px',
  'input-placeholder-color': THEME.colorTextTertiary,
  'input-bg': THEME.colorBg,
  'input-hover-border-color': THEME.colorBorder,
  'input-disabled-bg': THEME.colorBg,
  // pagination
  'pagination-item-bg-active': THEME.colorHover,
  // button
  'btn-height-base': '36px',
  'btn-shadow': 'none',
  'btn-primary-shadow': 'none',
  'btn-default-color': THEME.colorText,
  'btn-danger-bg': THEME.colorError,
  'btn-danger-border': THEME.colorError,
  'btn-danger-color': '#fff',
  'btn-padding-base': '0 12px',
  'btn-disable-color': 'invalid', // set invalid value to remove this property
  'btn-disable-bg': 'invalid', // set invalid value to remove this property
  'btn-disable-border': 'invalid', // set invalid value to remove this property
  // menu
  'menu-inline-toplevel-item-height': '50px',
  'menu-item-height': '50px',
  'menu-bg': 'inherit',
  'menu-item-color': 'inherit',
  'menu-item-active-bg': THEME.colorHover,
  // tabs
  'tabs-card-head-background': 'transparent',
  'tabs-title-font-size': '14px',
  'tabs-horizontal-padding': '6px 0',
  'tabs-bar-margin': '0 0 14px 0',
  'tabs-hover-color': THEME.colorClickHover,
  'tabs-card-gutter': '0',
  // modal
  'modal-body-padding': '0 30px',
  'modal-header-bg': THEME.colorBg,
  'modal-mask-bg': 'fade(#000, 30%)',
  // form
  'form-item-margin-bottom': THEME.spacing,
  // tooltip
  'tooltip-bg': THEME.colorToastBg,
  'tooltip-arrow-width': '7px',
  'tooltip-distance': '7px',
  // Switch
  'switch-height': '24px',
  // Animation
  'animation-duration-slow': '0.01s',
  'animation-duration-base': '0.01s',
};
