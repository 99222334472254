import React from 'react';
import { FormattedMessage } from 'react-intl';

import { EMPLOYEE_TYPE, EMPLOYEE_STATUS, GENDER, MARITAL_STATUS } from 'consts';

import messages from '../messages';

export const EmployeeTypeDisplays = {
  [EMPLOYEE_TYPE.FULL_TIME]: <FormattedMessage {...messages.fullTime} />,
  [EMPLOYEE_TYPE.PART_TIME]: <FormattedMessage {...messages.partTime} />,
  [EMPLOYEE_TYPE.SHIFT_WORKER]: <FormattedMessage {...messages.shiftWorker} />,
  [EMPLOYEE_TYPE.CONTRACTOR]: <FormattedMessage {...messages.contractor} />,
  [EMPLOYEE_TYPE.INTERNSHIP]: <FormattedMessage {...messages.internship} />,
  [EMPLOYEE_TYPE.OTHER]: <FormattedMessage {...messages.other} />,
};

export const EmployeeStatusDisplays = {
  [EMPLOYEE_STATUS.PENDING]: <FormattedMessage {...messages.pending} />,
  [EMPLOYEE_STATUS.ACTIVE]: <FormattedMessage {...messages.inPosition} />,
  [EMPLOYEE_STATUS.LEAVING]: <FormattedMessage {...messages.leaving} />,
  [EMPLOYEE_STATUS.INACTIVE]: <FormattedMessage {...messages.terminated} />,
};

export const GenderDisplays = {
  [GENDER.MALE]: <FormattedMessage {...messages.male} />,
  [GENDER.FEMALE]: <FormattedMessage {...messages.female} />,
  [GENDER.UNKNOWN]: <FormattedMessage {...messages.others} />,
};

export const MaritalStatusDisplays = {
  [MARITAL_STATUS.SINGLE]: <FormattedMessage {...messages.single} />,
  [MARITAL_STATUS.MARRIED]: <FormattedMessage {...messages.married} />,
  [MARITAL_STATUS.DIVORCED]: <FormattedMessage {...messages.divorced} />,
  [MARITAL_STATUS.WIDOWED]: <FormattedMessage {...messages.widowed} />,
  [MARITAL_STATUS.UNKNOWN]: <FormattedMessage {...messages.others} />,
};

export const TempLeapYear = 2000;
