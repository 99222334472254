import { InputChip } from '@seaweb/coral/components/Chip';
import MultiSelect, {
  MultiSelectInput,
  MultiSelectList,
  MultiSelectOption,
  MultiSelectSearch,
  Placements,
} from '@seaweb/coral/components/MultiSelect';
import { SelectHighlighter } from '@seaweb/coral/components/Select';
import { arrayOf, bool, func, number, object, oneOf } from 'prop-types';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';
import Tooltip from '@seaweb/coral/components/Tooltip';

import { THEME } from 'colorTheme';
import { useTutorialContext } from 'components/Tutorial';
import { DEPARTMENT_SCOPE_PURPOSE, EMPLOYEE_STATUS } from 'consts';
import { useGetRequestCamelized } from 'hooks/useGetRequest';

import Avatar from './Avatar';
import EmptyPlaceholder from './EmptyPlaceholder';
import messages from './messages';
import { Tag } from './Tag';

const Profile = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
`;

const PhoneEmail = styled.div`
  color: ${THEME.colorTextTertiary};
  line-height: 20px;
`;

const StyledAvatar = styled(Avatar)`
  margin-right: 0;
  border-radius: 50%;
`;

const StyledMultiSelectInput = styled(MultiSelectInput)`
  width: 100%;
`;

const StyledMultiSelectList = styled(MultiSelectList)`
  width: auto;

  div[data-coral-select-list-empty] {
    width: 250px;
  }
`;

const StyledMultiSelectOption = styled(MultiSelectOption)`
  padding: 8px 16px;

  ${({ disabled }) => {
    if (disabled) {
      return {
        opacity: 0.3,
      };
    }
  }}
`;

const StyledSelectHighlighter = styled(SelectHighlighter)`
  display: inline-block;
  max-width: 160px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
`;

const ItemEmployee = ({ employee }) => (
  <Profile>
    <Avatar src={employee.avatarSrc} size={32} style={{ borderRadius: 4 }} />
    <div style={{ height: 40 }}>
      <div style={{ height: 20 }}>
        <StyledSelectHighlighter text={employee.name} />
        {employee.employeeStatus === EMPLOYEE_STATUS.LEAVING && (
          <Tag style={{ verticalAlign: 'top' }}>
            <FormattedMessage {...messages.leaving} />
          </Tag>
        )}
      </div>
      <PhoneEmail>
        <StyledSelectHighlighter
          text={employee.companyEmail || employee.handPhone}
        />
      </PhoneEmail>
    </div>
  </Profile>
);

ItemEmployee.propTypes = {
  employee: object.isRequired,
};

/**
 * An upgrade version for SelectEmployee in multi mode
 */
const MultiSelectEmployee = forwardRef(
  (
    {
      value,
      onChange,
      purpose,
      params,
      blacklistId,
      noAccessListId,
      superAdminListId,
      style,
      disabled,
    },
    ref
  ) => {
    const tutorialContext = useTutorialContext();
    const [query, setQuery] = useState('');
    const [keyword] = useDebounce(query, 200);

    const [employeesRes] = useGetRequestCamelized(
      '/org/employees/dropdown',
      { purpose, keyword, ...params },
      { skip: !!tutorialContext }
    );
    const { employees = [] } = employeesRes || {};
    const employeesMap = useRef({});
    value.forEach((emp) => {
      employeesMap.current[emp.id] = emp;
    });
    employees.forEach((emp) => {
      employeesMap.current[emp.id] = emp;
    });

    // For multi mode
    const searchRef = useRef();
    const [searchEl, setSearchEl] = useState();
    useEffect(() => setSearchEl(searchRef.current), []);

    // Remove 1 employee from list
    const omitEmployee = (employeeId) => {
      onChange(value.filter((emp) => emp.id !== employeeId));
    };

    const renderSelectOption = ({ employee, isDisabledSuperAdmin }) => {
      if (isDisabledSuperAdmin) {
        return (
          <Tooltip
            key={employee.id}
            title={<FormattedMessage {...messages.userIsExistingSuperAdmin} />}
            placement={Placements.Top}
          >
            <StyledMultiSelectOption
              value={employee.id}
              index={employee.id}
              checkboxIconProp={{ checked: true }}
              disabled
            >
              <ItemEmployee employee={employee} />
            </StyledMultiSelectOption>
          </Tooltip>
        );
      }

      return (
        <StyledMultiSelectOption
          key={employee.id}
          value={employee.id}
          disabled={noAccessListId.includes(employee.id)}
        >
          <ItemEmployee employee={employee} />
        </StyledMultiSelectOption>
      );
    };

    return (
      <MultiSelect
        value={value.map((emp) => emp.id)}
        onChange={(empIds) =>
          onChange(empIds.map((id) => employeesMap.current[id]))
        }
        searchValue={query}
        onSearch={setQuery}
      >
        <StyledMultiSelectInput
          ref={ref}
          placeholder={<FormattedMessage {...messages.searchEmployee} />}
          searchElement={<MultiSelectSearch ref={searchRef} />}
          style={style}
          disabled={disabled}
        >
          {value &&
            value.map((emp) => (
              <InputChip
                key={emp.id}
                onDelete={() => {
                  omitEmployee(emp.id);
                  searchRef.current.focus();
                }}
                leftElement={
                  <StyledAvatar
                    src={emp.avatarSrc}
                    size={20}
                    noParams={!!tutorialContext}
                  />
                }
              >
                {emp.name}
              </InputChip>
            ))}
        </StyledMultiSelectInput>
        <StyledMultiSelectList
          emptyPlaceholder={<EmptyPlaceholder type="noData" />}
          popperReference={searchEl}
          placement={Placements.BottomStart}
        >
          {employees
            .filter((emp) => !blacklistId.includes(emp.id))
            .map((emp) =>
              renderSelectOption({
                employee: emp,
                isDisabledSuperAdmin: superAdminListId.includes(emp.id),
              })
            )}
        </StyledMultiSelectList>
      </MultiSelect>
    );
  }
);

MultiSelectEmployee.propTypes = {
  value: arrayOf(object).isRequired,
  onChange: func.isRequired,
  purpose: oneOf(Object.values(DEPARTMENT_SCOPE_PURPOSE)),
  params: object,
  clearable: bool,
  blacklistId: arrayOf(number), // Used to filter Main Super Admin
  noAccessListId: arrayOf(number), // Used to disable higher rank user
  superAdminListId: arrayOf(number), // Used to disable existing super admin
  style: object,
  disabled: bool,
};

MultiSelectEmployee.defaultProps = {
  purpose: DEPARTMENT_SCOPE_PURPOSE.VIEW_DEPARTMENT,
  params: {},
  clearable: true,
  blacklistId: [],
  noAccessListId: [],
  superAdminListId: [],
  style: {},
};

export default MultiSelectEmployee;
