import { defineMessages } from 'react-intl';

export const scope = 'components.Common.ToastMessageHandler';

export default defineMessages({
  companyAdd: {
    id: `${scope}.companyAdd`,
    defaultMessage: 'Company added successfully',
  },
  companyEdit: {
    id: `${scope}.companyEdit`,
    defaultMessage: 'Company edited successfully',
  },
  employeeAdd: {
    id: `${scope}.employeeAdd`,
    defaultMessage: 'Employee added successfully',
  },
  employeeEdit: {
    id: `${scope}.employeeEdit`,
    defaultMessage: 'Employee edited successfully',
  },
  employeeDelete: {
    id: `${scope}.employeeDelete`,
    defaultMessage: 'Employee deleted successfully',
  },
  employeeInvite: {
    id: `${scope}.employeeInvite`,
    defaultMessage: 'Email invitation sent successfully',
  },
  employeeNoExportData: {
    id: `${scope}.employeeNoExportData`,
    defaultMessage: 'No employee data to export',
  },
  departmentAdd: {
    id: `${scope}.departmentAdd`,
    defaultMessage: 'Department added successfully',
  },
  departmentEdit: {
    id: `${scope}.departmentEdit`,
    defaultMessage: 'Department edited successfully',
  },
  departmentDelete: {
    id: `${scope}.departmentDelete`,
    defaultMessage: 'Department deleted successfully',
  },
  jobTitleAdd: {
    id: `${scope}.jobTitleAdd`,
    defaultMessage: 'Job title added successfully',
  },
  jobTitleEdit: {
    id: `${scope}.jobTitleEdit`,
    defaultMessage: 'Job title edited successfully',
  },
  jobTitleDelete: {
    id: `${scope}.jobTitleDelete`,
    defaultMessage: 'Job title deleted successfully',
  },
  manualCorrectionAdd: {
    id: `${scope}.manualCorrectionAdd`,
    defaultMessage: 'Clock-in/out record successfully updated!',
  },
  officeAdd: {
    id: `${scope}.officeAdd`,
    defaultMessage: 'Office added successfully',
  },
  officeEdit: {
    id: `${scope}.officeEdit`,
    defaultMessage: 'Office edited successfully',
  },
  officeDelete: {
    id: `${scope}.officeDelete`,
    defaultMessage: 'Office deleted successfully',
  },
  payrollAdd: {
    id: `${scope}.payrollAdd`,
    defaultMessage: 'Payroll added successfully',
  },
  payrollEdit: {
    id: `${scope}.payrollEdit`,
    defaultMessage: 'Payroll edited successfully',
  },
  payrollDelete: {
    id: `${scope}.payrollDelete`,
    defaultMessage: 'Payroll deleted successfully',
  },
  rankAdd: {
    id: `${scope}.rankAdd`,
    defaultMessage: 'Rank added successfully',
  },
  rankEdit: {
    id: `${scope}.rankEdit`,
    defaultMessage: 'Rank edited successfully',
  },
  rankDelete: {
    id: `${scope}.rankDelete`,
    defaultMessage: 'Rank deleted successfully',
  },
  workShiftAdd: {
    id: `${scope}.workShiftAdd`,
    defaultMessage: 'Work Shift added successfully',
  },
  workShiftEdit: {
    id: `${scope}.workShiftEdit`,
    defaultMessage: 'Work Shift edited successfully',
  },
  domainAdd: {
    id: `${scope}.domainAdd`,
    defaultMessage: 'Domain added successfully',
  },
  domainEdit: {
    id: `${scope}.domainEdit`,
    defaultMessage: 'Domain edited successfully',
  },
  domainDelete: {
    id: `${scope}.domainDelete`,
    defaultMessage: 'Domain deleted successfully',
  },
  adminAdd: {
    id: `${scope}.adminAdd`,
    defaultMessage: 'Admin added successfully',
  },
  adminEdit: {
    id: `${scope}.adminEdit`,
    defaultMessage: 'Admin edited successfully',
  },
  adminDelete: {
    id: `${scope}.adminDelete`,
    defaultMessage: 'Admin deleted successfully',
  },
  adminReinvite: {
    id: `${scope}.adminReinvite`,
    defaultMessage: 'Email reminder sent successfully',
  },
  roleAdd: {
    id: `${scope}.roleAdd`,
    defaultMessage: 'Role added successfully',
  },
  roleEdit: {
    id: `${scope}.roleEdit`,
    defaultMessage: 'Role edited successfully',
  },
  roleDeactive: {
    id: `${scope}.roleDeactive`,
    defaultMessage: 'Role deactived successfully',
  },
  roleActive: {
    id: `${scope}.roleActive`,
    defaultMessage: 'Role actived successfully',
  },
  roleUnassign: {
    id: `${scope}.roleUnassign`,
    defaultMessage: 'Role unassigned successfully',
  },
  roleDelete: {
    id: `${scope}.roleDelete`,
    defaultMessage: 'Role deleted successfully',
  },
  accountBroadcastAdd: {
    id: `${scope}.accountBroadcastAdd`,
    defaultMessage: 'Broadcast added successfully',
  },
  accountBroadcastEdit: {
    id: `${scope}.accountBroadcastEdit`,
    defaultMessage: 'Broadcast edited successfully',
  },
  accountBroadcastDelete: {
    id: `${scope}.accountBroadcastDelete`,
    defaultMessage: 'Broadcast deleted successfully',
  },
  accountBroadcastRecall: {
    id: `${scope}.accountBroadcastRecall`,
    defaultMessage: 'Broadcast recalled successfully',
  },
  accountBroadcastReschedule: {
    id: `${scope}.accountBroadcastReschedule`,
    defaultMessage: 'Broadcast rescheduled successfully',
  },
  accountBroadcastFileTooBig: {
    id: `${scope}.accountBroadcastFileTooBig`,
    defaultMessage: 'File size is too big',
  },
  accountBroadcastUnknownError: {
    id: `${scope}.accountBroadcastUnknownError`,
    defaultMessage: 'Unknown error during file upload. Please try again.',
  },
  onboardCodeRefresh: {
    id: `${scope}.onboardCodeRefresh`,
    defaultMessage: 'All codes refreshed',
  },
  onboardRequestProcessed: {
    id: `${scope}.onboardRequestProcessed`,
    defaultMessage:
      'The request has been processed by another admin, please refresh the page',
  },
  defaultCalendarSet: {
    id: `${scope}.defaultCalendarSet`,
    defaultMessage: 'A new default calendar has been set',
  },
  copied: {
    id: `${scope}.copied`,
    defaultMessage: 'Copied',
  },
  downloadSuccess: {
    id: `${scope}.downloadSuccess`,
    defaultMessage: 'Download Successfully',
  },
  changesSaved: {
    id: `${scope}.changesSaved`,
    defaultMessage: 'Change has been saved',
  },
  claimResetSuccessfully: {
    id: `${scope}.claimResetSuccessfully`,
    defaultMessage: 'All claim related records reset successfully.',
  },
  errorFetch: {
    id: `${scope}.errorFetch`,
    defaultMessage: 'Unable to fetch',
  },
  e1: {
    id: `${scope}.e1`,
    defaultMessage:
      'Unexpected errors occurred. Please report to administrators for further investigation.',
  },
  e100: {
    id: `${scope}.e100`,
    defaultMessage: 'Request is unauthenticated. Please login.',
  },
  e101: {
    id: `${scope}.e101`,
    defaultMessage: 'Request is rejected due to invalid format.',
  },
  e102: {
    id: `${scope}.e102`,
    defaultMessage: 'Request is rejected due to insufficient permission.',
  },
  e103: {
    id: `${scope}.e103`,
    defaultMessage: 'Request rejected. Your account has been suspended.',
  },
  e104: {
    id: `${scope}.e104`,
    defaultMessage: 'Request rejected. Your account does not exist.',
  },
  e105: {
    id: `${scope}.e105`,
    defaultMessage: 'Request rejected. Requested company is not found.',
  },
  e106: {
    id: `${scope}.e106`,
    defaultMessage: 'Request rejected. Requested company is not authorized.',
  },
  e107: {
    id: `${scope}.e107`,
    defaultMessage: 'You have to select a company before this operation.',
  },
  e108: {
    id: `${scope}.e108`,
    defaultMessage:
      'Request rejected. Department management scope is violated.',
  },
  e109: {
    id: `${scope}.e109`,
    defaultMessage:
      'Request rejected. One or more requested employee(s) is/are not found.',
  },
  e110: {
    id: `${scope}.e110`,
    defaultMessage:
      'Request rejected. One or more requested employee(s) is/are beyond company scope.',
  },
  e111: {
    id: `${scope}.e111`,
    defaultMessage:
      'Request rejected. One or more requested employee(s) is/are beyond department scope.',
  },
  e112: {
    id: `${scope}.e112`,
    defaultMessage:
      'Request rejected. The requested public account is beyond your visibility.',
  },
  e113: {
    id: `${scope}.e113`,
    defaultMessage: 'Request rejected. The requested calendar is not found.',
  },
  e114: {
    id: `${scope}.e114`,
    defaultMessage:
      'Request rejected. The requested calendar is beyond company scope.',
  },
  e115: {
    id: `${scope}.e115`,
    defaultMessage:
      'Request rejected. The requested leave type id is not found.',
  },
  e116: {
    id: `${scope}.e116`,
    defaultMessage:
      'Request rejected. The requested leave rule id is not found.',
  },
  e117: {
    id: `${scope}.e117`,
    defaultMessage:
      'Request rejected. The requested leave rule is beyond company scope.',
  },
  e118: {
    id: `${scope}.e118`,
    defaultMessage: 'Request rejected. The requested location id is not found.',
  },
  e119: {
    id: `${scope}.e119`,
    defaultMessage: 'Request rejected. The request balance id is not found.',
  },
  e120: {
    id: `${scope}.e120`,
    defaultMessage:
      'Request rejected. One or more requested leave type(s) is/are beyond company scope.',
  },
  e200: {
    id: `${scope}.e200`,
    defaultMessage:
      'Database error occurs. If the issue persists, please report to administrators.',
  },
  e201: {
    id: `${scope}.e201`,
    defaultMessage:
      'Internal service error occurs. If the issue persists, please report to administrators.',
  },
  e202: {
    id: `${scope}.e202`,
    defaultMessage:
      'Failed to parse data from database. If the issue persists, please report to administrators.',
  },
  e1000: {
    id: `${scope}.e1000`,
    defaultMessage: 'A role with this name already exists.',
  },
  e1001: {
    id: `${scope}.e1001`,
    defaultMessage: 'A user with this email already exists.',
  },
  e1002: {
    id: `${scope}.e1002`,
    defaultMessage: 'A permission entry with this name already exists.',
  },
  e1004: {
    id: `${scope}.e1004`,
    defaultMessage: 'The requested user is not found.',
  },
  e1005: {
    id: `${scope}.e1005`,
    defaultMessage: 'The requested role is not found.',
  },
  e1006: {
    id: `${scope}.e1006`,
    defaultMessage: 'The requested permission is not found.',
  },
  e1007: {
    id: `${scope}.e1007`,
    defaultMessage: 'You can only manage users in your own company.',
  },
  e1008: {
    id: `${scope}.e1008`,
    defaultMessage: 'Company ID is required when adding a new role.',
  },
  e1009: {
    id: `${scope}.e1009`,
    defaultMessage: 'Company ID must be an integer.',
  },
  e1010: {
    id: `${scope}.e1010`,
    defaultMessage: '	Only roles under your company is allowed to edit.',
  },
  e1011: {
    id: `${scope}.e1011`,
    defaultMessage: 'One or more roles are not allowed to assign to users.	',
  },
  e1012: {
    id: `${scope}.e1012`,
    defaultMessage: 'Protected users are not allowed to be edited.',
  },
  e1013: {
    id: `${scope}.e1013`,
    defaultMessage: 'Role associated with users are not allowed to be deleted.',
  },
  e1014: {
    id: `${scope}.e1014`,
    defaultMessage: 'The requested user is not authroized for view.',
  },
  e1015: {
    id: `${scope}.e1015`,
    defaultMessage: 'One or more users are not under the current company.',
  },
  e1016: {
    id: `${scope}.e1016`,
    defaultMessage:
      'You do not have permission to grant or revoke higher rank roles to user.',
  },
  e1017: {
    id: `${scope}.e1017`,
    defaultMessage: 'The requested permission cannot be recognized.',
  },
  e1018: {
    id: `${scope}.e1018`,
    defaultMessage: 'Predefined role are not allowed to be edited or deleted.',
  },
  e1019: {
    id: `${scope}.e1019`,
    defaultMessage:
      'You do not have permission to view the detail of this role.',
  },
  e1020: {
    id: `${scope}.e1020`,
    defaultMessage: 'Departments in this role are not under this company.',
  },
  e1021: {
    id: `${scope}.e1021`,
    defaultMessage: 'The department management scope is not eligible.',
  },
  e1022: {
    id: `${scope}.e1022`,
    defaultMessage:
      'Customized roles and Super Admin roles cannot be granted together.',
  },
  e1023: {
    id: `${scope}.e1023`,
    defaultMessage:
      'Only main super admin of that company who can transfer role to others.',
  },
  e1024: {
    id: `${scope}.e1024`,
    defaultMessage:
      'The number of Super Admins have exceeded the limit. Ensure that there are at most 10 Super Admins.',
  },
  e1025: {
    id: `${scope}.e1025`,
    defaultMessage: 'Verification code is invalid.',
  },
  e1026: {
    id: `${scope}.e1026`,
    defaultMessage: 'Verification code is expired.',
  },
  e1027: {
    id: `${scope}.e1027`,
    defaultMessage: 'Cannot transfer role to self.',
  },
  e1028: {
    id: `${scope}.e1028`,
    defaultMessage: 'Role name must not be empty.',
  },
  e1029: {
    id: `${scope}.e1029`,
    defaultMessage: 'Role description must not be empty.',
  },
  e1033: {
    id: `${scope}.e1033`,
    defaultMessage: 'Permission of main super admin cannot be modified.',
  },
  e1034: {
    id: `${scope}.e1034`,
    defaultMessage: 'Whitelisted department scope is empty.',
  },
  e1035: {
    id: `${scope}.e1035`,
    defaultMessage: 'No feature scope is selected.',
  },
  e1036: {
    id: `${scope}.e1036`,
    defaultMessage: 'Feature scope is not within the role rank.',
  },
  e2000: {
    id: `${scope}.e2000`,
    defaultMessage:
      'Login attempt failed. If the issue persists, please report to administrators.',
  },
  e2001: {
    id: `${scope}.e2001`,
    defaultMessage: 'Login attempt failed. Your account does not exist.',
  },
  e2002: {
    id: `${scope}.e2002`,
    defaultMessage: 'Login attempt failed. Your account has been suspended.',
  },
  e2003: {
    id: `${scope}.e2003`,
    defaultMessage:
      'Login attempt failed. Please register your SeaTalk account first.',
  },
  e2004: {
    id: `${scope}.e2004`,
    defaultMessage: 'Switch company failed. Please ensure login first.',
  },
  e2005: {
    id: `${scope}.e2005`,
    defaultMessage: 'Switch company failed. Please ensure cookie is enabled.',
  },
  e2006: {
    id: `${scope}.e2006`,
    defaultMessage:
      'Switch company failed. You are not allowed to view the request company.',
  },
  e3000: {
    id: `${scope}.e3000`,
    defaultMessage: 'Name is a required field.',
  },
  e3001: {
    id: `${scope}.e3001`,
    defaultMessage: 'Company logo is a required field.',
  },
  e3002: {
    id: `${scope}.e3002`,
    defaultMessage: 'Foundation date is a required field.',
  },
  e3003: {
    id: `${scope}.e3003`,
    defaultMessage: 'Date is not valid.',
  },
  e3004: {
    id: `${scope}.e3004`,
    defaultMessage: 'A company with this name already exists.',
  },
  e3005: {
    id: `${scope}.e3005`,
    defaultMessage: 'The requested company is not found.',
  },
  e3006: {
    id: `${scope}.e3006`,
    defaultMessage: 'The parent department is not within the same company.',
  },
  e3007: {
    id: `${scope}.e3007`,
    defaultMessage: 'Company must be the top organization unit.',
  },
  e3008: {
    id: `${scope}.e3008`,
    defaultMessage:
      'Department can not be added under a subordinated department.',
  },
  e3009: {
    id: `${scope}.e3009`,
    defaultMessage: 'Company name is a required field.',
  },
  e3010: {
    id: `${scope}.e3010`,
    defaultMessage: 'Department name is a required field.',
  },
  e3011: {
    id: `${scope}.e3011`,
    defaultMessage: 'The requested department is not found.',
  },
  e3012: {
    id: `${scope}.e3012`,
    defaultMessage: 'The domain name is already taken.',
  },
  e3013: {
    id: `${scope}.e3013`,
    defaultMessage: 'The requested domain is not found.',
  },
  e3015: {
    id: `${scope}.e3015`,
    defaultMessage: 'Domain name is a required field.',
  },
  e3016: {
    id: `${scope}.e3016`,
    defaultMessage: 'The requested department is beyond your visibility.',
  },
  e3017: {
    id: `${scope}.e3017`,
    defaultMessage:
      'The department cannot be deleted due to associated departments under it.',
  },
  e3018: {
    id: `${scope}.e3018`,
    defaultMessage:
      'The department cannot be deleted due to associated employees under it.',
  },
  e3019: {
    id: `${scope}.e3019`,
    defaultMessage: 'The requested leader is not found.',
  },
  e3020: {
    id: `${scope}.e3020`,
    defaultMessage:
      'The leader and department does not belong to the same company.',
  },
  e3021: {
    id: `${scope}.e3021`,
    defaultMessage:
      'The department to be whitelisted does not belong to the same company.',
  },
  e3022: {
    id: `${scope}.e3022`,
    defaultMessage:
      'The uploaded image is too big. Ensure the size is less than 50MB.',
  },
  e3023: {
    id: `${scope}.e3023`,
    defaultMessage: 'Upload failure. Please report to administrator.',
  },
  e3024: {
    id: `${scope}.e3024`,
    defaultMessage: 'Root department is not allowed to be deleted.',
  },
  e3025: {
    id: `${scope}.e3025`,
    defaultMessage: 'Department name cannot contain > character.',
  },
  e3026: {
    id: `${scope}.e3026`,
    defaultMessage:
      'Department name has been used by another department with same parent.',
  },
  e3027: {
    id: `${scope}.e3027`,
    defaultMessage: 'Department scope purpose not found.',
  },
  e3028: {
    id: `${scope}.e3028`,
    defaultMessage: 'Department code is already taken.',
  },
  e3029: {
    id: `${scope}.e3029`,
    defaultMessage: 'Department name length is more than 40 characters.',
  },
  e3030: {
    id: `${scope}.e3030`,
    defaultMessage: 'Department code length is more than 40 characters.',
  },
  e3031: {
    id: `${scope}.e3031`,
    defaultMessage:
      'The department is referenced in one or more visibility settings. You need to remove the settings first.',
  },
  e3032: {
    id: `${scope}.e3032`,
    defaultMessage:
      'The department is referenced in one or more public account audiences. You need to remove it first.',
  },
  e3033: {
    id: `${scope}.e3033`,
    defaultMessage:
      'Locations does not include one or more office locations under this company.',
  },
  e3034: {
    id: `${scope}.e3034`,
    defaultMessage: 'Headquarter location is not in company location list.',
  },
  e3038: {
    id: `${scope}.e3038`,
    defaultMessage: 'Company name must be between 0 and 255 characters.',
  },
  e4000: {
    id: `${scope}.e4000`,
    defaultMessage: 'Birthday is not a valid date.',
  },
  e4001: {
    id: `${scope}.e4001`,
    defaultMessage: 'Board day is not a valid date.',
  },
  e4002: {
    id: `${scope}.e4002`,
    defaultMessage: 'Resign day is not a valid date.',
  },
  e4003: {
    id: `${scope}.e4003`,
    defaultMessage: 'Employee N.O. is a required field.',
  },
  e4004: {
    id: `${scope}.e4004`,
    defaultMessage: 'First name is required.',
  },
  e4005: {
    id: `${scope}.e4005`,
    defaultMessage: 'Last name is required.',
  },
  e4006: {
    id: `${scope}.e4006`,
    defaultMessage: 'Company email is required.',
  },
  e4007: {
    id: `${scope}.e4007`,
    defaultMessage: 'Company is required.',
  },
  e4008: {
    id: `${scope}.e4008`,
    defaultMessage: 'The Employee Code already exists, please modify it again.',
  },
  e4009: {
    id: `${scope}.e4009`,
    defaultMessage: 'The requested employee is not found.',
  },
  e4010: {
    id: `${scope}.e4010`,
    defaultMessage: 'The requested company is beyond your visibility.',
  },
  e4011: {
    id: `${scope}.e4011`,
    defaultMessage: 'Another employee with this Employee Code already exists.',
  },
  e4012: {
    id: `${scope}.e4012`,
    defaultMessage: 'An employee with this company email already exists.',
  },
  e4013: {
    id: `${scope}.e4013`,
    defaultMessage: 'Invitation failed. Please contact with administrators.',
  },
  e4014: {
    id: `${scope}.e4014`,
    defaultMessage:
      'Employee and assigned offices are not under the same company.',
  },
  e4015: {
    id: `${scope}.e4015`,
    defaultMessage:
      'Employee and assigned departments are not under the same company.',
  },
  e4016: {
    id: `${scope}.e4016`,
    defaultMessage:
      'Employee and assigned job titles are not under the same company.',
  },
  e4017: {
    id: `${scope}.e4017`,
    defaultMessage:
      'Employee and assigned reporters are not under the same company.',
  },
  e4018: {
    id: `${scope}.e4018`,
    defaultMessage:
      'The token for file server cannot be generated without a valid SeaTalk ID.',
  },
  e4019: {
    id: `${scope}.e4019`,
    defaultMessage: 'The uploaded xls/xlsx file cannot be parsed properly.',
  },
  e4020: {
    id: `${scope}.e4020`,
    defaultMessage: 'Job ID is required to query the importing progress.',
  },
  e4021: {
    id: `${scope}.e4021`,
    defaultMessage: 'Job is not found.',
  },
  e4022: {
    id: `${scope}.e4022`,
    defaultMessage: 'The uploaded xls/xlsx file is empty.',
  },
  e4023: {
    id: `${scope}.e4023`,
    defaultMessage: 'The uploaded xls/xlsx file is too large.',
  },
  e4024: {
    id: `${scope}.e4024`,
    defaultMessage: 'The uploaded file is not xls/xlsx.',
  },
  e4025: {
    id: `${scope}.e4025`,
    defaultMessage: 'Employee Code does not exist.',
  },
  e4026: {
    id: `${scope}.e4026`,
    defaultMessage: 'Avatar cannot be saved into database.',
  },
  e4027: {
    id: `${scope}.e4027`,
    defaultMessage: 'Employees are not under the same company.',
  },
  e4028: {
    id: `${scope}.e4028`,
    defaultMessage: 'Employees cannot be blank for bulk edit operations.	',
  },
  e4029: {
    id: `${scope}.e4029`,
    defaultMessage: 'Employees to be removed are not under the same company.',
  },
  e4030: {
    id: `${scope}.e4030`,
    defaultMessage: 'One or more employees to be removed can not be found.',
  },
  e4031: {
    id: `${scope}.e4031`,
    defaultMessage: 'The requested department is not found.',
  },
  e4032: {
    id: `${scope}.e4032`,
    defaultMessage: 'Employee avatar upload failure.',
  },
  e4033: {
    id: `${scope}.e4033`,
    defaultMessage:
      'Employee avatar upload failure. Please ensure size is less than 5MB.',
  },
  e4034: {
    id: `${scope}.e4034`,
    defaultMessage:
      'One or more reporters have cyclic reporting relation with current employee.',
  },
  e4035: {
    id: `${scope}.e4035`,
    defaultMessage: 'One or more fields are not allowed to be edited.',
  },
  e4036: {
    id: `${scope}.e4036`,
    defaultMessage:
      'The current request is rejected. Ensure you have proper department management scope.',
  },
  e4037: {
    id: `${scope}.e4037`,
    defaultMessage:
      'The employee to be deleted is under a department that is out of your management scope.',
  },
  e4038: {
    id: `${scope}.e4038`,
    defaultMessage: 'Error occured to some employees on bulk operation.',
  },
  e4039: {
    id: `${scope}.e4039`,
    defaultMessage: 'Photo upload failed. No photo is specified.',
  },
  e4040: {
    id: `${scope}.e4040`,
    defaultMessage: 'Employees cannot be blank for bulk delete operations.',
  },
  e4041: {
    id: `${scope}.e4041`,
    defaultMessage: 'Insufficient rank to delete an employee.',
  },
  e4042: {
    id: `${scope}.e4042`,
    defaultMessage: 'Probation end day is not a valid date.',
  },
  e4043: {
    id: `${scope}.e4043`,
    defaultMessage: 'IC issue day is not a valid date.',
  },
  e4044: {
    id: `${scope}.e4044`,
    defaultMessage: 'Passport expiration day is not a valid date.',
  },
  e4045: {
    id: `${scope}.e4045`,
    defaultMessage:
      'Employee and assigned payroll companies are not under the same company.',
  },
  e4046: {
    id: `${scope}.e4046`,
    defaultMessage:
      'Employee and assigned rank are not under the same company.',
  },
  e4047: {
    id: `${scope}.e4047`,
    defaultMessage: 'Employee cannot have more than one calendar assigned.',
  },
  e4061: {
    id: `${scope}.e4061`,
    defaultMessage:
      'Either company email or phone number needs to be filled in.',
  },
  e5000: {
    id: `${scope}.e5000`,
    defaultMessage: 'Name is a required field.',
  },
  e5001: {
    id: `${scope}.e5001`,
    defaultMessage: 'Language is a required field.',
  },
  e5002: {
    id: `${scope}.e5002`,
    defaultMessage: 'Location is a required field.',
  },
  e5003: {
    id: `${scope}.e5003`,
    defaultMessage: 'Company is a required field.',
  },
  e5004: {
    id: `${scope}.e5004`,
    defaultMessage: 'The requested office is not found.',
  },
  e5005: {
    id: `${scope}.e5005`,
    defaultMessage: 'The office name is already taken.',
  },
  e5006: {
    id: `${scope}.e5006`,
    defaultMessage: 'Location is not in the company locations configuration.',
  },
  e5007: {
    id: `${scope}.e5007`,
    defaultMessage: 'The office code is already taken.',
  },
  e5008: {
    id: `${scope}.e5008`,
    defaultMessage: 'The office code length is more than 40 characters.',
  },
  e6000: {
    id: `${scope}.e6000`,
    defaultMessage: 'Name is a required field.',
  },
  e6001: {
    id: `${scope}.e6001`,
    defaultMessage: 'Company is a required field.',
  },
  e6002: {
    id: `${scope}.e6002`,
    defaultMessage: 'The requested job title is not found.',
  },
  e6003: {
    id: `${scope}.e6003`,
    defaultMessage: 'The job title name is already taken.',
  },
  e6004: {
    id: `${scope}.e6004`,
    defaultMessage: 'The job title code is already taken.',
  },
  e6005: {
    id: `${scope}.e6005`,
    defaultMessage: 'The job title code length is more than 40 characters.',
  },
  e7000: {
    id: `${scope}.e7000`,
    defaultMessage:
      'One or more employees do not belong to the current company.',
  },
  e7001: {
    id: `${scope}.e7001`,
    defaultMessage: 'Invalid date',
  },
  e8000: {
    id: `${scope}.e8000`,
    defaultMessage: 'Please choose a name for your public account.',
  },
  e8001: {
    id: `${scope}.e8001`,
    defaultMessage: 'Please upload a photo for your public account.',
  },
  e8002: {
    id: `${scope}.e8002`,
    defaultMessage: 'Please choose audience for your public account.',
  },
  e8003: {
    id: `${scope}.e8003`,
    defaultMessage: 'Owners do not belong to current company.',
  },
  e8004: {
    id: `${scope}.e8004`,
    defaultMessage: 'Blacklisted staff do not belong to current company.',
  },
  e8005: {
    id: `${scope}.e8005`,
    defaultMessage: 'Whitelisted staff do not belong to current company.',
  },
  e8006: {
    id: `${scope}.e8006`,
    defaultMessage: 'Departments do not belong to current company.',
  },
  e8007: {
    id: `${scope}.e8007`,
    defaultMessage: 'Public account is not found.',
  },
  e8008: {
    id: `${scope}.e8008`,
    defaultMessage: 'Public account avatar upload failure.',
  },
  e8009: {
    id: `${scope}.e8009`,
    defaultMessage:
      'Public account avatar upload failure. Please ensure size is less than 5MB.',
  },
  e8010: {
    id: `${scope}.e8010`,
    defaultMessage: 'Broadcast is not found.',
  },
  e8011: {
    id: `${scope}.e8011`,
    defaultMessage: 'Broadcast media type is not supported.',
  },
  e8012: {
    id: `${scope}.e8012`,
    defaultMessage: 'Broadcast is not allowed to edit.',
  },
  e8013: {
    id: `${scope}.e8013`,
    defaultMessage: 'Broadcast time can not be past.',
  },
  e8014: {
    id: `${scope}.e8014`,
    defaultMessage:
      'Broadcast media size is too large. Please ensure size is less than 500MB.',
  },
  e8015: {
    id: `${scope}.e8015`,
    defaultMessage: 'Broadcast media upload failure.',
  },
  e8016: {
    id: `${scope}.e8016`,
    defaultMessage:
      'Broadcast are not allowed to recall because it is 7 days ago.',
  },
  e8017: {
    id: `${scope}.e8017`,
    defaultMessage: 'Only delivered broadcast is allowed to be recalled.',
  },
  e8018: {
    id: `${scope}.e8018`,
    defaultMessage: 'Only in-draft broadcast can be deleted.',
  },
  e8019: {
    id: `${scope}.e8019`,
    defaultMessage: 'Only pending broadcast can be withdrew.',
  },
  e8020: {
    id: `${scope}.e8020`,
    defaultMessage: 'Please specified audience/departments for the broadcast.',
  },
  e8021: {
    id: `${scope}.e8021`,
    defaultMessage: `Reschedule can't be accomplished because of the status of broadcast is no longer pending.`,
  },
  e8022: {
    id: `${scope}.e8022`,
    defaultMessage: 'Announcement send time can not be past.',
  },
  e8023: {
    id: `${scope}.e8023`,
    defaultMessage: 'Announcement expiry time can not be past.',
  },
  e8024: {
    id: `${scope}.e8024`,
    defaultMessage: 'Announcement is not found.',
  },
  e8025: {
    id: `${scope}.e8025`,
    defaultMessage: 'Only in-draft/pending announcement can be edited.',
  },
  e8026: {
    id: `${scope}.e8026`,
    defaultMessage: 'Only delivered announcement can be recalled.',
  },
  e8027: {
    id: `${scope}.e8027`,
    defaultMessage: 'Cannot recall expired announcement.',
  },
  e8028: {
    id: `${scope}.e8028`,
    defaultMessage:
      'Only in-draft/pending/recalled announcement can be deleted.',
  },
  e8029: {
    id: `${scope}.e8029`,
    defaultMessage: 'Please specify audience for the announcement.',
  },
  e8030: {
    id: `${scope}.e8030`,
    defaultMessage:
      'One or more audience(s) is/are blacklisted member of the public account.',
  },
  e8031: {
    id: `${scope}.e8031`,
    defaultMessage:
      'One or more audience(s) is/are not a member of the public account.',
  },
  e8032: {
    id: `${scope}.e8032`,
    defaultMessage:
      'One or more department(s) is/are not associated with the public account.',
  },
  e8033: {
    id: `${scope}.e8033`,
    defaultMessage: 'The broadcast message field is empty.',
  },
  e8034: {
    id: `${scope}.e8034`,
    defaultMessage: `Some of the employee's emails do not exist.`,
  },
  e8035: {
    id: `${scope}.e8035`,
    defaultMessage: 'Invalid custom message file format.',
  },
  e8036: {
    id: `${scope}.e8036`,
    defaultMessage: 'Message length has exceeded maximum limit (10000 words).',
  },
  e8037: {
    id: `${scope}.e8037`,
    defaultMessage: 'Article not found.',
  },
  e8038: {
    id: `${scope}.e8038`,
    defaultMessage: 'Article is unpublished.',
  },
  e8039: {
    id: `${scope}.e8039`,
    defaultMessage: 'Attachment not found.',
  },
  e9000: {
    id: `${scope}.e9000`,
    defaultMessage: 'Calendar name duplicates.',
  },
  e9001: {
    id: `${scope}.e9001`,
    defaultMessage: 'Holiday names duplicates.',
  },
  e9002: {
    id: `${scope}.e9002`,
    defaultMessage: 'Holiday dates are overlapped with others.',
  },
  e9003: {
    id: `${scope}.e9003`,
    defaultMessage: 'Default calendar are not allowed to be deleted.',
  },
  e9004: {
    id: `${scope}.e9004`,
    defaultMessage: 'Requested calendar holiday id is not found.',
  },
  e9005: {
    id: `${scope}.e9005`,
    defaultMessage: 'Some departments do not belong to current company.',
  },
  e9006: {
    id: `${scope}.e9006`,
    defaultMessage: 'Some Offices for some location is not found.',
  },
  e9007: {
    id: `${scope}.e9007`,
    defaultMessage: 'Some employees do not belong to current company.',
  },
  e9008: {
    id: `${scope}.e9008`,
    defaultMessage: 'The year value is not valid.',
  },
  e9009: {
    id: `${scope}.e9009`,
    defaultMessage: 'Some public holidays are not found.',
  },
  e9010: {
    id: `${scope}.e9010`,
    defaultMessage: 'Employee is not assigned to any calendar.',
  },
  e9011: {
    id: `${scope}.e9011`,
    defaultMessage: 'Some payroll companies do not belong to current company.',
  },
  e10000: {
    id: `${scope}.e10000`,
    defaultMessage: 'Name is a required field.',
  },
  e10001: {
    id: `${scope}.e10001`,
    defaultMessage: 'Code is a required field.',
  },
  e10002: {
    id: `${scope}.e10002`,
    defaultMessage: 'Company is a required field.',
  },
  e10003: {
    id: `${scope}.e10003`,
    defaultMessage: 'The payroll company name is already taken.',
  },
  e10004: {
    id: `${scope}.e10004`,
    defaultMessage: 'The requested payroll company is not found.',
  },
  e10005: {
    id: `${scope}.e10005`,
    defaultMessage:
      'The payroll company code length is more than 40 characters.',
  },
  e11000: {
    id: `${scope}.e11000`,
    defaultMessage: 'Name is a required field.',
  },
  e11001: {
    id: `${scope}.e11001`,
    defaultMessage: 'Company is a required field.',
  },
  e11002: {
    id: `${scope}.e11002`,
    defaultMessage: 'The rank name is already taken.',
  },
  e11003: {
    id: `${scope}.e11003`,
    defaultMessage: 'The requested rank is not found.',
  },
  e11004: {
    id: `${scope}.e11004`,
    defaultMessage: 'Rank code is already taken.',
  },
  e11005: {
    id: `${scope}.e11005`,
    defaultMessage: 'The rank code length is more than 40 characters.',
  },
  e12000: {
    id: `${scope}.e12000`,
    defaultMessage: 'Leave policy not found.',
  },
  e12001: {
    id: `${scope}.e12001`,
    defaultMessage: 'Leave policy name for this company is already used.',
  },
  e12002: {
    id: `${scope}.e12002`,
    defaultMessage: 'Leave policy code for this company is already used.',
  },
  e12003: {
    id: `${scope}.e12003`,
    defaultMessage: 'Some employees do not belong to current company.',
  },
  e12004: {
    id: `${scope}.e12004`,
    defaultMessage: 'Some leave rule does not belong to current company.',
  },
  e12005: {
    id: `${scope}.e12005`,
    defaultMessage: 'Leave type is duplicated.',
  },
  e12006: {
    id: `${scope}.e12006`,
    defaultMessage: 'Employees are not unique.',
  },
  e12007: {
    id: `${scope}.e12007`,
    defaultMessage: 'Some employee(s) is/are not found.',
  },
  e12008: {
    id: `${scope}.e12008`,
    defaultMessage: 'Some leave policy(s) is/are not found.',
  },
  e12009: {
    id: `${scope}.e12009`,
    defaultMessage:
      'Some pair of leave policy and employee have different company.',
  },
  e12010: {
    id: `${scope}.e12010`,
    defaultMessage: 'Duplicate leave rule order.',
  },
  e12011: {
    id: `${scope}.e12011`,
    defaultMessage:
      'The leave policy and some departments have different company.',
  },
  e12012: {
    id: `${scope}.e12012`,
    defaultMessage: 'Some offices for some location is not found.',
  },
  e12013: {
    id: `${scope}.e12013`,
    defaultMessage:
      'The leave policy and some payroll companies have different company.',
  },
  e12014: {
    id: `${scope}.e12014`,
    defaultMessage: 'Effective date must be the first day of a month.',
  },
  e12015: {
    id: `${scope}.e12015`,
    defaultMessage: 'Effective date must be the first day of a year.',
  },
  e12016: {
    id: `${scope}.e12016`,
    defaultMessage: 'Effective date for a leave rule is already taken.',
  },
  e12017: {
    id: `${scope}.e12017`,
    defaultMessage: 'Leave type not under the leave policy.',
  },
  e12018: {
    id: `${scope}.e12018`,
    defaultMessage:
      'Not allowed to add leave rule with older effective date for leave type that had been effective.',
  },
  e12019: {
    id: `${scope}.e12019`,
    defaultMessage: 'Cannot have consecutive same leave rules in a policy.',
  },
  e12020: {
    id: `${scope}.e12020`,
    defaultMessage: 'Can not suspend default policy.',
  },
  e12021: {
    id: `${scope}.e12021`,
    defaultMessage: 'Can not set suspended policy as default.',
  },
  e12022: {
    id: `${scope}.e12022`,
    defaultMessage: 'Leave policy name is invalid: Length exceeds limit.',
  },
  e12023: {
    id: `${scope}.e12023`,
    defaultMessage: 'Leave policy code is invalid: Length exceeds limit.',
  },
  e12024: {
    id: `${scope}.e12024`,
    defaultMessage:
      'Leave policy description is invalid: Length exceeds limit.',
  },
  e12025: {
    id: `${scope}.e12025`,
    defaultMessage:
      'Leave rule credit document help info is invalid: Length exceeds limit.',
  },
  e12026: {
    id: `${scope}.e12026`,
    defaultMessage: 'Leave policy is active, cannot be deleted.',
  },
  e13000: {
    id: `${scope}.e13000`,
    defaultMessage: 'Name is duplicated when adding a new leave type.',
  },
  e13001: {
    id: `${scope}.e13001`,
    defaultMessage: 'Code is duplicated when adding a new leave type.',
  },
  e13002: {
    id: `${scope}.e13002`,
    defaultMessage: 'Name is duplicated when adding a new leave rule.',
  },
  e13003: {
    id: `${scope}.e13003`,
    defaultMessage: 'Leave rule not found.',
  },
  e13004: {
    id: `${scope}.e13004`,
    defaultMessage: 'Leave type not found.',
  },
  e13005: {
    id: `${scope}.e13005`,
    defaultMessage: `Can't delete the leave type. It is still used by other data.`,
  },
  e13006: {
    id: `${scope}.e13006`,
    defaultMessage: 'Leave type is already disabled.',
  },
  e13007: {
    id: `${scope}.e13007`,
    defaultMessage: 'Leave type is already enabled.',
  },
  e13008: {
    id: `${scope}.e13008`,
    defaultMessage: 'Office location not found.',
  },
  e13009: {
    id: `${scope}.e13009`,
    defaultMessage:
      'Entitlement type is not the same with other leave rule under the leave type.',
  },
  e13010: {
    id: `${scope}.e13010`,
    defaultMessage: 'Stepped must not have same year.',
  },
  e13011: {
    id: `${scope}.e13011`,
    defaultMessage:
      'Leave type is assigned to be default leave type of a leave policy, not allowed to delete it.',
  },
  e13012: {
    id: `${scope}.e13012`,
    defaultMessage:
      'Leave rule is currently in use under a leave policy, not allowed to delete it.',
  },
  e13013: {
    id: `${scope}.e13013`,
    defaultMessage: 'Leave type name must be between 0 and 255 characters.',
  },
  e13014: {
    id: `${scope}.e13014`,
    defaultMessage: 'Leave type code must be between 0 and 16 characters.',
  },
  e13015: {
    id: `${scope}.e13015`,
    defaultMessage:
      'Leave type description must be between 0 and 512 characters.',
  },
  e13016: {
    id: `${scope}.e13016`,
    defaultMessage: 'Document help info must be between 0 and 255 characters',
  },
  e13017: {
    id: `${scope}.e13017`,
    defaultMessage: 'Leave rule name must be between 0 and 255 characters',
  },
  e14000: {
    id: `${scope}.e14000`,
    defaultMessage: 'Leave approval not found.',
  },
  e14001: {
    id: `${scope}.e14001`,
    defaultMessage: 'Leave approval nodes validation error.',
  },
  e14002: {
    id: `${scope}.e14002`,
    defaultMessage: 'There is a node with blank node type.',
  },
  e14003: {
    id: `${scope}.e14003`,
    defaultMessage: 'The number of start nodes in the graph is not 1.',
  },
  e14004: {
    id: `${scope}.e14004`,
    defaultMessage: 'The number of end nodes in the graph is not 1.',
  },
  e14005: {
    id: `${scope}.e14005`,
    defaultMessage: 'The start node has parent nodes.',
  },
  e14006: {
    id: `${scope}.e14006`,
    defaultMessage: 'There is a non-start node with no parent nodes.',
  },
  e14007: {
    id: `${scope}.e14007`,
    defaultMessage: 'The leave approval graph is not a directed acyclic graph.',
  },
  e14008: {
    id: `${scope}.e14008`,
    defaultMessage: 'The end node must not have any child nodes.',
  },
  e14009: {
    id: `${scope}.e14009`,
    defaultMessage:
      'Node that is not the end node must have at least 1 child node.',
  },
  e14010: {
    id: `${scope}.e14010`,
    defaultMessage:
      'One or more conditional child nodes are not condition nodes.',
  },
  e14011: {
    id: `${scope}.e14011`,
    defaultMessage:
      'One or more conditional child nodes have empty condition field.',
  },
  e14012: {
    id: `${scope}.e14012`,
    defaultMessage:
      'The priority of the conditional child nodes is not sequential from 1 to the number of conditional child nodes.',
  },
  e14013: {
    id: `${scope}.e14013`,
    defaultMessage:
      'One or more conditional child nodes which do not have the lowest priority are set as the `other conditions` node.',
  },
  e14014: {
    id: `${scope}.e14014`,
    defaultMessage:
      'The lowest priority conditional child node is not set as the `other conditions` node.',
  },
  e14015: {
    id: `${scope}.e14015`,
    defaultMessage: 'Node condition field is empty.',
  },
  e14016: {
    id: `${scope}.e14016`,
    defaultMessage: 'The condition cannot be blank, please setup.',
  },
  e14017: {
    id: `${scope}.e14017`,
    defaultMessage:
      'Condition node is the `other conditions` node but it does have some filter enabled.',
  },
  e14018: {
    id: `${scope}.e14018`,
    defaultMessage:
      'Applicant scope filter is enabled but all filters (departments, locations) are empty.',
  },
  e14019: {
    id: `${scope}.e14019`,
    defaultMessage: 'One or more departments are duplicates.',
  },
  e14020: {
    id: `${scope}.e14020`,
    defaultMessage: 'One or more departments are not found.',
  },
  e14021: {
    id: `${scope}.e14021`,
    defaultMessage: 'One or more departments are beyond company scope.',
  },
  e14022: {
    id: `${scope}.e14022`,
    defaultMessage: 'One or more locations are duplicates.',
  },
  e14023: {
    id: `${scope}.e14023`,
    defaultMessage:
      'One or more locations are not in the company locations configuration.',
  },
  e14024: {
    id: `${scope}.e14024`,
    defaultMessage:
      'Leave type filter is enabled but no leave types are selected.',
  },
  e14025: {
    id: `${scope}.e14025`,
    defaultMessage: 'The list of leave type IDs has some duplicates.',
  },
  e14026: {
    id: `${scope}.e14026`,
    defaultMessage: 'One or more leave types are not found.',
  },
  e14027: {
    id: `${scope}.e14027`,
    defaultMessage: 'One or more leave types are beyond company scope.',
  },
  e14028: {
    id: `${scope}.e14028`,
    defaultMessage: 'Condition node duration range type cannot be blank.',
  },
  e14029: {
    id: `${scope}.e14029`,
    defaultMessage: 'Minimum number of days is not positive.',
  },
  e14030: {
    id: `${scope}.e14030`,
    defaultMessage:
      'Minimum number of days is not less than maximum number of days.',
  },
  e14031: {
    id: `${scope}.e14031`,
    defaultMessage: 'Number of days is not positive.',
  },
  e14032: {
    id: `${scope}.e14032`,
    defaultMessage: 'Node approver field is nil.',
  },
  e14033: {
    id: `${scope}.e14033`,
    defaultMessage: 'Approver node approver type cannot be blank.',
  },
  e14034: {
    id: `${scope}.e14034`,
    defaultMessage: 'Approver node approval method cannot be blank.',
  },
  e14035: {
    id: `${scope}.e14035`,
    defaultMessage: 'One or more employees are duplicates.',
  },
  e14036: {
    id: `${scope}.e14036`,
    defaultMessage: 'One or more employees are not found.',
  },
  e14037: {
    id: `${scope}.e14037`,
    defaultMessage: 'One or more employees are beyond company scope.',
  },
  e14038: {
    id: `${scope}.e14038`,
    defaultMessage: '	Node carbon copy field is nil.',
  },
  e14039: {
    id: `${scope}.e14039`,
    defaultMessage: 'At least one copy options must be selected.',
  },
  e14040: {
    id: `${scope}.e14040`,
    defaultMessage: 'Last level nodes must be an approver node.',
  },
  e14041: {
    id: `${scope}.e14041`,
    defaultMessage: 'Approver node must be preempt.',
  },
  e14042: {
    id: `${scope}.e14042`,
    defaultMessage: 'Unknown node type.',
  },
  e14043: {
    id: `${scope}.e14043`,
    defaultMessage: 'Leave type entitlement must be upon approval.',
  },
  e14044: {
    id: `${scope}.e14044`,
    defaultMessage: 'Unknown reporting level.',
  },
  e14045: {
    id: `${scope}.e14045`,
    defaultMessage: 'Leave credit approval nodes validation error.',
  },
  e15000: {
    id: `${scope}.e15000`,
    defaultMessage: 'Balance is not enough for adjustment.',
  },
  e15001: {
    id: `${scope}.e15001`,
    defaultMessage: 'The requested employee is not found.',
  },
  e15002: {
    id: `${scope}.e15002`,
    defaultMessage:
      'All employees under your management scope are not assigned to any leave policy.',
  },
  e15003: {
    id: `${scope}.e15003`,
    defaultMessage: 'All related leave policies are deactivated.',
  },
  e15004: {
    id: `${scope}.e15004`,
    defaultMessage:
      'All related leave policies are not associated to any leave rule.',
  },
  e15005: {
    id: `${scope}.e15005`,
    defaultMessage: 'Some departments are not found.',
  },
  e15006: {
    id: `${scope}.e15006`,
    defaultMessage: 'Some payroll companies are not found.',
  },
  e15007: {
    id: `${scope}.e15007`,
    defaultMessage: 'Employee not assigned to any leave policy.',
  },
  e15008: {
    id: `${scope}.e15008`,
    defaultMessage: 'Invalid valid period.',
  },
  e15009: {
    id: `${scope}.e15009`,
    defaultMessage: 'Duration must be in half-day format.',
  },
  e15010: {
    id: `${scope}.e15010`,
    defaultMessage: 'Duration must be in whole-day format.',
  },
  e15011: {
    id: `${scope}.e15011`,
    defaultMessage: 'Credit application must be approved to be edited.',
  },
  e15012: {
    id: `${scope}.e15012`,
    defaultMessage: 'Valid dates must covers all valid leave applications.',
  },
  e15013: {
    id: `${scope}.e15013`,
    defaultMessage: 'Duration must be positive.',
  },
  e15014: {
    id: `${scope}.e15014`,
    defaultMessage:
      'Not allowed to manually adjust existing balance for upon approval. The older credit is still active.',
  },
  e15015: {
    id: `${scope}.e15015`,
    defaultMessage: 'The remark entered is too long.',
  },
  e16000: {
    id: `${scope}.16000`,
    defaultMessage: 'Task ID length must be between 1 to 64.',
  },
  e16001: {
    id: `${scope}.16001`,
    defaultMessage: 'Employee have finished the this task before.',
  },
  e170101: {
    id: `${scope}.e170101`,
    defaultMessage: 'Panel Code does not exist.',
  },
  e170102: {
    id: `${scope}.e170102`,
    defaultMessage: 'Status code is not valid.',
  },
  e170201: {
    id: `${scope}.e170201`,
    defaultMessage: 'Quick access item code does not exist.',
  },
  e170202: {
    id: `${scope}.e170202`,
    defaultMessage: 'Quick access item code is not allowed.',
  },
  e170203: {
    id: `${scope}.e170203`,
    defaultMessage: 'Maximum number of quick access items is 10.',
  },
  e170204: {
    id: `${scope}.e170204`,
    defaultMessage: 'Enabled quick access items has duplicates.',
  },
  e180101: {
    id: `${scope}.e180101`,
    defaultMessage: '	Location does not exist',
  },
  e180102: {
    id: `${scope}.e180102`,
    defaultMessage: 'Invalid date format.',
  },
  e180103: {
    id: `${scope}.e180103`,
    defaultMessage: 'Company does not exist.',
  },
  e180201: {
    id: `${scope}.e180201`,
    defaultMessage: 'Calendar does not exist.',
  },
  e180202: {
    id: `${scope}.e180202`,
    defaultMessage: 'Length of calendar name must be between 0 and 255.',
  },
  e180203: {
    id: `${scope}.e180203`,
    defaultMessage: 'There is another calendar with the same name.',
  },
  e180204: {
    id: `${scope}.e180204`,
    defaultMessage: 'Calendar is used in some workgroups.',
  },
  e180301: {
    id: `${scope}.e180301`,
    defaultMessage: 'Calendar holiday does not exist.',
  },
  e180302: {
    id: `${scope}.e180302`,
    defaultMessage: 'Calendar holiday intra day span is invalid.',
  },
  e180303: {
    id: `${scope}.e180303`,
    defaultMessage: 'Length of holiday name must between 0 and 255.',
  },
  e180304: {
    id: `${scope}.e180304`,
    defaultMessage: 'There is another holiday with the same name.',
  },
  e180305: {
    id: `${scope}.e180305`,
    defaultMessage: 'Holiday overlapped with an existing holiday.',
  },
  e180401: {
    id: `${scope}.e180401`,
    defaultMessage: 'Work shift does not exist.',
  },
  e180402: {
    id: `${scope}.e180402`,
    defaultMessage: `Work shift's number of clock in is invalid.`,
  },
  e180403: {
    id: `${scope}.e180403`,
    defaultMessage: 'Length of work shift name must between 0 and 255.',
  },
  e180404: {
    id: `${scope}.e180404`,
    defaultMessage: 'There is another work shift with the same name.',
  },
  e180405: {
    id: `${scope}.e180405`,
    defaultMessage: 'Clock time setting is invalid.',
  },
  e180406: {
    id: `${scope}.e180406`,
    defaultMessage:
      'The free clock in/out work shift is used on rest days. The work shift type cannot be changed',
  },
  e180407: {
    id: `${scope}.e180407`,
    defaultMessage: 'The work shift setting is invalid',
  },
  e180501: {
    id: `${scope}.e180501`,
    defaultMessage: 'Work group does not exist.',
  },
  e180502: {
    id: `${scope}.e180502`,
    defaultMessage: `There is another work group with the same name.`,
  },
  e180503: {
    id: `${scope}.e180503`,
    defaultMessage: 'Length of work group name must between 0 and 255.',
  },
  e180504: {
    id: `${scope}.e180504`,
    defaultMessage: 'Attendance method is invalid',
  },
  e180505: {
    id: `${scope}.e180505`,
    defaultMessage: 'Clock in method is invalid.',
  },
  e180506: {
    id: `${scope}.e180506`,
    defaultMessage: 'Wifi is duplicated.',
  },
  e180507: {
    id: `${scope}.e180507`,
    defaultMessage: 'Wifi is invalid.',
  },
  e180508: {
    id: `${scope}.e180508`,
    defaultMessage: 'GPS is duplicated.',
  },
  e180509: {
    id: `${scope}.e180509`,
    defaultMessage: 'GPS is invalid.',
  },
  e180510: {
    id: `${scope}.e180510`,
    defaultMessage: 'Working cycle is invalid.',
  },
  e180511: {
    id: `${scope}.e180511`,
    defaultMessage: 'Working calendar is invalid.',
  },
  e180512: {
    id: `${scope}.e180512`,
    defaultMessage: 'Working timezone is invalid.',
  },
  e180513: {
    id: `${scope}.e180513`,
    defaultMessage: 'Work group shift day is existed.',
  },
  e180514: {
    id: `${scope}.e180514`,
    defaultMessage: 'Work group work shift is invalid.',
  },
  e180515: {
    id: `${scope}.e180515`,
    defaultMessage: 'Work group employee is invalid.',
  },
  e180516: {
    id: `${scope}.e180516`,
    defaultMessage: "Work group's work shifts are conflicted",
  },
  e180517: {
    id: `${scope}.e180517`,
    defaultMessage: "Work group's attendance terminal setting is invalid",
  },
  e180518: {
    id: `${scope}.e180518`,
    defaultMessage:
      "Work group's wifi setting is invalid - Wifi whitelist is empty ",
  },
  e180519: {
    id: `${scope}.e180519`,
    defaultMessage:
      "Work group's GPS setting is invalid - GPS whitelist is empty ",
  },
  e180520: {
    id: `${scope}.e180520`,
    defaultMessage: "Work group's working cycle - Shift setting is invalid",
  },
  e180521: {
    id: `${scope}.e180521`,
    defaultMessage: "Work group's cycle shifts conflicted",
  },
  e180522: {
    id: `${scope}.e180522`,
    defaultMessage:
      'Invalid template format, please check the column structure.',
  },
  e180523: {
    id: `${scope}.e180523`,
    defaultMessage: 'Schedule shifts conflicted.',
  },
  e180524: {
    id: `${scope}.e180524`,
    defaultMessage: 'Cannot set the work shedule for past date',
  },
  e180525: {
    id: `${scope}.e180525`,
    defaultMessage: 'Manual schedule is disabled.',
  },
  e180526: {
    id: `${scope}.e180526`,
    defaultMessage: 'JobID not found.',
  },
  e180527: {
    id: `${scope}.e180527`,
    defaultMessage: 'Attachment not found',
  },
  e180528: {
    id: `${scope}.e180528`,
    defaultMessage: 'Cycle not found',
  },
  e180529: {
    id: `${scope}.e180529`,
    defaultMessage: 'Cycle name is duplicated',
  },
  e180530: {
    id: `${scope}.e180530`,
    defaultMessage: 'Auto clocking config is invalid',
  },
  e180531: {
    id: `${scope}.e180531`,
    defaultMessage: 'Workgroup is deleted',
  },
  e180532: {
    id: `${scope}.e180532`,
    defaultMessage: 'Workgroup is inactive',
  },
  e180601: {
    id: `${scope}.e180601`,
    defaultMessage: 'Correction approval nodes validation error',
  },
  e180602: {
    id: `${scope}.e180602`,
    defaultMessage: 'There is a node with blank node type.',
  },
  e180603: {
    id: `${scope}.e180603`,
    defaultMessage: 'The number of start nodes in the graph is not 1.',
  },
  e180604: {
    id: `${scope}.e180604`,
    defaultMessage: 'The number of end nodes in the graph is not 1.',
  },
  e180605: {
    id: `${scope}.e180605`,
    defaultMessage: 'The start node has parent nodes.',
  },
  e180606: {
    id: `${scope}.e180606`,
    defaultMessage: 'There is a non-start node with no parent nodes.',
  },
  e180607: {
    id: `${scope}.e180607`,
    defaultMessage:
      'The correction approval graph is not a directed acyclic graph.',
  },
  e180608: {
    id: `${scope}.e180608`,
    defaultMessage: 'The end node must not have any child nodes.',
  },
  e180609: {
    id: `${scope}.e180609`,
    defaultMessage:
      'Node that is not the end node must have at least 1 child node.',
  },
  e180610: {
    id: `${scope}.e180610`,
    defaultMessage:
      'One or more conditional child nodes are not condition nodes.',
  },
  e180611: {
    id: `${scope}.e180611`,
    defaultMessage:
      'One or more conditional child nodes have empty condition field.',
  },
  e180612: {
    id: `${scope}.e180612`,
    defaultMessage:
      'The priority of the conditional child nodes is not sequential from 1 to the number of conditional child nodes.',
  },
  e180613: {
    id: `${scope}.e180613`,
    defaultMessage:
      'One or more conditional child nodes which do not have the lowest priority are set as the `other conditions` node.',
  },
  e180614: {
    id: `${scope}.e180614`,
    defaultMessage:
      'The lowest priority conditional child node is not set as the `other conditions` node.',
  },
  e180615: {
    id: `${scope}.e180615`,
    defaultMessage: 'Node condition field is empty.',
  },
  e180616: {
    id: `${scope}.e180616`,
    defaultMessage:
      'Condition node is not the `other conditions` node but it does not have any filter enabled.',
  },
  e180617: {
    id: `${scope}.e180617`,
    defaultMessage:
      'Condition node is the `other conditions` node but it does have some filter enabled.',
  },
  e180618: {
    id: `${scope}.e180618`,
    defaultMessage:
      'Applicant scope filter is enabled but all filters (departments, locations) are empty.',
  },
  e180619: {
    id: `${scope}.e180619`,
    defaultMessage: 'One or more departments are duplicates.',
  },
  e180620: {
    id: `${scope}.e180620`,
    defaultMessage: 'One or more departments are not found.',
  },
  e180621: {
    id: `${scope}.e180621`,
    defaultMessage: 'One or more departments are beyond company scope.',
  },
  e180622: {
    id: `${scope}.e180622`,
    defaultMessage: 'One or more locations are duplicated.',
  },
  e180623: {
    id: `${scope}.e180623`,
    defaultMessage:
      'One or more locations are not in the company locations configuration.',
  },
  e180624: {
    id: `${scope}.e180624`,
    defaultMessage: 'One or more payroll companies are duplicated.',
  },
  e180625: {
    id: `${scope}.e180625`,
    defaultMessage:
      'One or more payroll companies are not in company configuration.',
  },
  e180626: {
    id: `${scope}.e180626`,
    defaultMessage: 'One or more work groups are duplicated.',
  },
  e180627: {
    id: `${scope}.e180627`,
    defaultMessage: 'One or more work groups are not in company configuration.',
  },
  e180628: {
    id: `${scope}.e180628`,
    defaultMessage: 'Node approver field is nil.',
  },
  e180629: {
    id: `${scope}.e180629`,
    defaultMessage: 'Approver node approver type cannot be blank.',
  },
  e180630: {
    id: `${scope}.e180630`,
    defaultMessage: 'Approver node approval method cannot be blank.',
  },
  e180631: {
    id: `${scope}.e180631`,
    defaultMessage: 'One or more employees are duplicates.',
  },
  e180632: {
    id: `${scope}.e180632`,
    defaultMessage: 'One or more employees are not found.',
  },
  e180633: {
    id: `${scope}.e180633`,
    defaultMessage: 'One or more employees are beyond company scope.',
  },
  e180634: {
    id: `${scope}.e180634`,
    defaultMessage: 'Unknown reporting level.',
  },
  e180701: {
    id: `${scope}.e180701`,
    defaultMessage: 'Correction application does not exist.',
  },
  e180702: {
    id: `${scope}.e180702`,
    defaultMessage: 'No work shift allocated on attendance_date.',
  },
  e180703: {
    id: `${scope}.e180703`,
    defaultMessage: 'There are existing clock in/out records.',
  },
  e180704: {
    id: `${scope}.e180704`,
    defaultMessage: 'Invalid clock type.',
  },
  e180705: {
    id: `${scope}.e180705`,
    defaultMessage: 'Invalid number of clock.',
  },
  e180706: {
    id: `${scope}.e180706`,
    defaultMessage:
      'Clock in/out time does not match with the designated effective clock in/out period.',
  },
  e180707: {
    id: `${scope}.e180707`,
    defaultMessage: 'The attendance date cannot be a future date.',
  },
  e180708: {
    id: `${scope}.e180708`,
    defaultMessage: 'No clock in record.',
  },
  e180709: {
    id: `${scope}.e180709`,
    defaultMessage: 'No import template.',
  },
  e180710: {
    id: `${scope}.e180710`,
    defaultMessage: 'Imported file format error.',
  },
  e180711: {
    id: `${scope}.e180711`,
    defaultMessage: 'Imported file larger than limit (10MB).',
  },
  e180712: {
    id: `${scope}.e180712`,
    defaultMessage: 'Job ID not found',
  },
  e180713: {
    id: `${scope}.e180713`,
    defaultMessage: 'Attachment (SHA256) Not Found',
  },
  e190101: {
    id: `${scope}.e190101`,
    defaultMessage: 'Self onboard disabled.',
  },
  e190102: {
    id: `${scope}.e190102`,
    defaultMessage: 'Avatar not found.',
  },
  e190103: {
    id: `${scope}.e190103`,
    defaultMessage:
      'Onboard failed. Request has been processed by another admin.',
  },
  e190104: {
    id: `${scope}.e190104`,
    defaultMessage: 'Onboard failed. This SeaTalk account was deleted.',
  },
  e190105: {
    id: `${scope}.e190105`,
    defaultMessage: 'Email format is invalid',
  },
  // Claim Basic Settings
  e200101: {
    id: `${scope}.e200101`,
    defaultMessage: 'No basic settings.',
  },
  e200102: {
    id: `${scope}.e200102`,
    defaultMessage:
      'Cannot change the report no to the number less than the current series number.',
  },
  e200103: {
    id: `${scope}.e200103`,
    defaultMessage: 'Claim currency existed.',
  },
  e200104: {
    id: `${scope}.e200104`,
    defaultMessage: 'Claim currency not found.',
  },
  e200105: {
    id: `${scope}.e200105`,
    defaultMessage:
      'Cannot delete the base currency or add exchange rates to it.',
  },
  e200106: {
    id: `${scope}.e200106`,
    defaultMessage: 'Claim currency used in transactions.',
  },
  e200107: {
    id: `${scope}.e200107`,
    defaultMessage: 'Exchange rate not found.',
  },
  e200108: {
    id: `${scope}.e200108`,
    defaultMessage: 'Base currency need to be reset.',
  },
  e200109: {
    id: `${scope}.e200109`,
    defaultMessage:
      'Exchange rate with the same effective date already exists.',
  },
  // Claim Categories
  e200201: {
    id: `${scope}.e200201`,
    defaultMessage: 'Claim category name is invalid.',
  },
  e200202: {
    id: `${scope}.e200202`,
    defaultMessage: 'This claim category name has already been taken.',
  },
  e200203: {
    id: `${scope}.e200203`,
    defaultMessage: "Claim form id is invalid: Form doesn't exist.",
  },
  e200204: {
    id: `${scope}.e200204`,
    defaultMessage: "Claim category doesn't exist.",
  },
  // Claim Policies
  e200301: {
    id: `${scope}.e200301`,
    defaultMessage: 'Claim policy not found.',
  },
  e200302: {
    id: `${scope}.e200302`,
    defaultMessage: 'Claim policy name has been taken.',
  },
  e200303: {
    id: `${scope}.e200303`,
    defaultMessage: 'Claim policy categories conflict.',
  },
  e200304: {
    id: `${scope}.e200304`,
    defaultMessage: 'Cannot delete an active policy.',
  },
  e200305: {
    id: `${scope}.e200305`,
    defaultMessage: 'Cannot delete a default policy.',
  },
  e200306: {
    id: `${scope}.e200306`,
    defaultMessage: 'Policy name cannot be empty.',
  },
  e200308: {
    id: `${scope}.e200308`,
    defaultMessage: 'Claim policy name must be between 0 and 100 characters',
  },
  e200309: {
    id: `${scope}.e200309`,
    defaultMessage:
      'Claim policy description must be between 0 and 200 characters',
  },
  // Claim Approval Flow
  e200400: {
    id: `${scope}.e200400`,
    defaultMessage: 'There is a node with blank node type.',
  },
  e200401: {
    id: `${scope}.e200401`,
    defaultMessage: 'The number of start nodes in the graph is not 1.',
  },
  e200402: {
    id: `${scope}.e200402`,
    defaultMessage: 'The number of end nodes in the graph is not 1.',
  },
  e200403: {
    id: `${scope}.e200403`,
    defaultMessage: 'The start node has parent nodes.',
  },
  e200404: {
    id: `${scope}.e200404`,
    defaultMessage: 'There is a non-start node with no parent nodes.',
  },
  e200405: {
    id: `${scope}.e200405`,
    defaultMessage:
      'The correction approval graph is not a directed acyclic graph.',
  },
  e200406: {
    id: `${scope}.e200406`,
    defaultMessage: 'Correction approval nodes validation error.',
  },
  e200407: {
    id: `${scope}.e200407`,
    defaultMessage: 'The end node must not have any child nodes.',
  },
  e200408: {
    id: `${scope}.e200408`,
    defaultMessage:
      'Node that is not the end node must have at least 1 child node.',
  },
  e200409: {
    id: `${scope}.e200409`,
    defaultMessage:
      'One or more conditional child nodes are not condition nodes.',
  },
  e200410: {
    id: `${scope}.e200410`,
    defaultMessage:
      'One or more conditional child nodes have empty condition field.',
  },
  e200411: {
    id: `${scope}.e200411`,
    defaultMessage:
      'The priority of the conditional child nodes is not sequential from 1 to the number of conditional child nodes.',
  },
  e200412: {
    id: `${scope}.e200412`,
    defaultMessage:
      'One or more conditional child nodes which do not have the lowest priority are set as the `other conditions` node.',
  },
  e200413: {
    id: `${scope}.e200413`,
    defaultMessage:
      'The lowest priority conditional child node is not set as the `other conditions` node.',
  },
  e200414: {
    id: `${scope}.e200414`,
    defaultMessage: 'Node condition field is empty.',
  },
  e200415: {
    id: `${scope}.e200415`,
    defaultMessage:
      'Condition node is not the `other conditions` node but it does not have any filter enabled.',
  },
  e200416: {
    id: `${scope}.e200416`,
    defaultMessage:
      'Condition node is the `other conditions` node but it does have some filter enabled.',
  },
  e200417: {
    id: `${scope}.e200417`,
    defaultMessage:
      'Applicant scope filter is enabled but all filters (departments, locations) are empty.',
  },
  e200418: {
    id: `${scope}.e200418`,
    defaultMessage: 'One or more departments are duplicates.',
  },
  e200419: {
    id: `${scope}.e200419`,
    defaultMessage: 'One or more departments are not found.',
  },
  e200420: {
    id: `${scope}.e200420`,
    defaultMessage: 'One or more departments are beyond company scope.',
  },
  e200421: {
    id: `${scope}.e200421`,
    defaultMessage: 'One or more locations are duplicated.',
  },
  e200422: {
    id: `${scope}.e200422`,
    defaultMessage:
      'One or more locations are not in the company locations configuration.',
  },
  e200423: {
    id: `${scope}.e200423`,
    defaultMessage: 'One or more ranks are duplicated.',
  },
  e200424: {
    id: `${scope}.e200424`,
    defaultMessage: 'One or more ranks are not in company configuration.',
  },
  e200425: {
    id: `${scope}.e200425`,
    defaultMessage: 'One or more claim category are duplicated.',
  },
  e200426: {
    id: `${scope}.e200426`,
    defaultMessage: 'One or more claim category are not found.',
  },
  e200427: {
    id: `${scope}.e200427`,
    defaultMessage: 'Condition node claim amount range type is unknown.',
  },
  e200428: {
    id: `${scope}.e200428`,
    defaultMessage: 'Claim amount is not positive.',
  },
  e200429: {
    id: `${scope}.e200429`,
    defaultMessage: 'Minimum claim amount is not positive.',
  },
  e200430: {
    id: `${scope}.e200430`,
    defaultMessage:
      'Minimum claim amount is not less than maximum claim amount.',
  },
  e200431: {
    id: `${scope}.e200431`,
    defaultMessage: 'Node approver field is nil.',
  },
  e200432: {
    id: `${scope}.e200432`,
    defaultMessage: 'Approver node approval method cannot be blank.',
  },
  e200433: {
    id: `${scope}.e200433`,
    defaultMessage: 'One or more employees are duplicates.',
  },
  e200434: {
    id: `${scope}.e200434`,
    defaultMessage: 'One or more employees are not found.',
  },
  e200435: {
    id: `${scope}.e200435`,
    defaultMessage: 'One or more employees are beyond company scope.',
  },
  e200436: {
    id: `${scope}.e200436`,
    defaultMessage: 'Unknown reporting level.',
  },
  e200437: {
    id: `${scope}.e200437`,
    defaultMessage: 'Approver node approver type cannot be blank',
  },
  e200603: {
    id: `${scope}.e200603`,
    defaultMessage: 'Submitted more than 9 attachments in an entry.',
  },
  e200608: {
    id: `${scope}.e200608`,
    defaultMessage: 'Entry amount is not positive.',
  },
  e200609: {
    id: `${scope}.e200609`,
    defaultMessage: 'Exchange rate is not positive.',
  },
  e200610: {
    id: `${scope}.e200610`,
    defaultMessage: 'Entry description is invalid.',
  },
  e200611: {
    id: `${scope}.e200611`,
    defaultMessage: 'Entry details are invalid.',
  },
  e200613: {
    id: `${scope}.e200613`,
    defaultMessage: 'Entries are the same with the previous entry.',
  },
  e200615: {
    id: `${scope}.e200615`,
    defaultMessage: 'Claim application not found.',
  },
  e200619: {
    id: `${scope}.e200619`,
    defaultMessage:
      'Entries update request is conflicted: Newer version(s) are available.',
  },
  e200702: {
    id: `${scope}.e200702`,
    defaultMessage: 'Claim application status is not pending payment.',
  },
  e200704: {
    id: `${scope}.e200704`,
    defaultMessage: 'Claim application ids list must not be empty.',
  },
  e201101: {
    id: `${scope}.e201101`,
    defaultMessage: 'The department does not have a department group yet.',
  },
  e201102: {
    id: `${scope}.e201102`,
    defaultMessage: 'The department already has a department group.',
  },
  e201103: {
    id: `${scope}.e201103`,
    defaultMessage: 'The owner is not active.',
  },
  e201104: {
    id: `${scope}.e201104`,
    defaultMessage: 'The department size is too large.',
  },
  e201105: {
    id: `${scope}.e201105`,
    defaultMessage: 'One or more department(s) is/are still owned by employee.',
  },
  e210101: {
    id: `${scope}.e210101`,
    defaultMessage:
      "App name's length out of range, need to be 1 - 50 characters",
  },
  e210102: {
    id: `${scope}.e210102`,
    defaultMessage: 'App description exceeds 100 characters limit',
  },
  e210103: {
    id: `${scope}.e210103`,
    defaultMessage: "App's logo length is out of scope",
  },
  e210104: {
    id: `${scope}.e210104`,
    defaultMessage: "App's name is duplicate",
  },
  e210105: {
    id: `${scope}.e210105`,
    defaultMessage: 'App does not exist, redirecting to the Custom App List',
  },
  e210201: {
    id: `${scope}.e210201`,
    defaultMessage: "App's field config is invalid",
  },
  e210202: {
    id: `${scope}.e210202`,
    defaultMessage: 'App contains invalid fields',
  },
  e210300: {
    id: `${scope}.e210300`,
    defaultMessage: 'App state is not valid.',
  },
  e210301: {
    id: `${scope}.e210301`,
    defaultMessage: 'App info is invalid.',
  },
  e210401: {
    id: `${scope}.e210401`,
    defaultMessage: 'Applicant view type is invalid',
  },
  e210402: {
    id: `${scope}.e210402`,
    defaultMessage: 'Applicant view positions length out of scope',
  },
  e210403: {
    id: `${scope}.e210403`,
    defaultMessage: 'Applicant view positions contain out of scope records',
  },
  e210404: {
    id: `${scope}.e210404`,
    defaultMessage: 'Applicant view positions contain duplicate records',
  },
  e210405: {
    id: `${scope}.e210405`,
    defaultMessage: 'Approval view type is invalid',
  },
  e210406: {
    id: `${scope}.e210406`,
    defaultMessage: 'Approval view positions length out of scope',
  },
  e210407: {
    id: `${scope}.e210407`,
    defaultMessage: 'Approval view positions contain out of scope records',
  },
  e210408: {
    id: `${scope}.e210408`,
    defaultMessage: 'Approval view positions contain duplicate records',
  },
  e210501: {
    id: `${scope}.e210501`,
    defaultMessage: 'Approval flow validation error.',
  },
  e210502: {
    id: `${scope}.e210502`,
    defaultMessage: 'Approval flow node validation error.',
  },
  e210503: {
    id: `${scope}.e210503`,
    defaultMessage: 'Approval flow node condition validation error.',
  },
  e210504: {
    id: `${scope}.e210504`,
    defaultMessage: 'Approval flow node approval validation error.',
  },
  e210505: {
    id: `${scope}.e210505`,
    defaultMessage: 'Approval flow node approval cc validation error.',
  },
  e220101: {
    id: `${scope}.e220101`,
    defaultMessage: 'The employee is already in the off-boarding process.',
  },
  e220102: {
    id: `${scope}.e220102`,
    defaultMessage: 'The employee is terminated.',
  },
  e220103: {
    id: `${scope}.e220103`,
    defaultMessage: 'The employee is deleted.',
  },
  e220104: {
    id: `${scope}.e220104`,
    defaultMessage: 'The off-boarding process is finished.',
  },
  e220105: {
    id: `${scope}.e220105`,
    defaultMessage: 'The off-boarding request is not found.',
  },
  e220106: {
    id: `${scope}.e220106`,
    defaultMessage: 'The termination date is a future date.',
  },
  e220107: {
    id: `${scope}.e220107`,
    defaultMessage: 'The termination date is before the joining date.',
  },
  e220108: {
    id: `${scope}.e220108`,
    defaultMessage: 'Unknown resource type.',
  },
  e220109: {
    id: `${scope}.e220109`,
    defaultMessage: 'Resources conflict.',
  },
  e220110: {
    id: `${scope}.e220110`,
    defaultMessage: 'Some transferees are not in the same company.',
  },
  e220111: {
    id: `${scope}.e220111`,
    defaultMessage: 'Cannot offboard yourself.',
  },
  e220112: {
    id: `${scope}.e220112`,
    defaultMessage: 'Cannot offboard the primary super admin.',
  },
  e220113: {
    id: `${scope}.e220113`,
    defaultMessage: 'Employees cannot report to themselves.',
  },
  e220114: {
    id: `${scope}.e220114`,
    defaultMessage: 'Transferee cannot be the same as the leaving employee.',
  },
  e220115: {
    id: `${scope}.e220115`,
    defaultMessage: 'The group owner should be an active user.',
  },
  e2002001: {
    id: `${scope}.e2002001`,
    defaultMessage: 'This request is withdrawn by App Owner',
  },
  e2002002: {
    id: `${scope}.e2002002`,
    defaultMessage: 'This request has already being processed',
  },
  e2002003: {
    id: `${scope}.e2002003`,
    defaultMessage: 'This request has already being processed',
  },
  e202201: {
    id: `${scope}.e202201`,
    defaultMessage: 'Field value exceed 50 char',
  },
  e202202: {
    id: `${scope}.e202202`,
    defaultMessage: 'Field value must be a valid URL or SOP App Link',
  },
  e202203: {
    id: `${scope}.e202203`,
    defaultMessage: 'Link must be less than 500 characters',
  },
  e20020001: {
    id: `${scope}.e20020001`,
    defaultMessage: 'custom field name duplicated',
  },
  e20020002: {
    id: `${scope}.e20020002`,
    defaultMessage: 'invalid custom name',
  },
  e20020003: {
    id: `${scope}.e20020003`,
    defaultMessage: 'Invalid Display ID',
  },
  e20020004: {
    id: `${scope}.e20020004`,
    defaultMessage: 'Custom fields too many',
  },
  e20020005: {
    id: `${scope}.e20020005`,
    defaultMessage: 'Invalid Field ID',
  },
  e20020006: {
    id: `${scope}.e20020006`,
    defaultMessage: 'Existed Displayed Field',
  },
  e20020008: {
    id: `${scope}.e20020008`,
    defaultMessage: 'Department or Employee too many',
  },
  e20030015: {
    id: `${scope}.e20030015`,
    defaultMessage:
      'Cannot apply decorations to this user group filtered by the department.',
  },
  e20040002: {
    id: `${scope}.e20040002`,
    defaultMessage: 'Cannot add more than 20 avatar decorations',
  },
});
