import {
  PaginationList,
  PaginationVariants,
} from '@seaweb/coral/components/Pagination';
import {
  TablePagination,
  TablePaginationChildren,
  TablePaginationTotal,
  TablePaginationSizeSelect,
} from '@seaweb/coral/components/TableData';
import { string } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

function Pagination2({ variant = PaginationVariants.Outlined, ...otherProps }) {
  return (
    <TablePagination variant={variant} {...otherProps}>
      <TablePaginationChildren
        total={
          <TablePaginationTotal
            style={otherProps.hiddenTotal ? { visibility: 'hidden' } : null}
            prefix={
              <>
                <FormattedMessage {...messages.total} />:
              </>
            }
          />
        }
        list={<PaginationList variant={variant} />}
        sizeSelect={
          variant === PaginationVariants.Outlined ? (
            <TablePaginationSizeSelect
              suffix={<FormattedMessage {...messages.perPage} />}
            />
          ) : (
            <div />
          )
        }
      />
    </TablePagination>
  );
}

Pagination2.propTypes = {
  variant: string,
};

export default Pagination2;
