import { useRef, useState, forwardRef, type ReactNode } from 'react';
import TextField from '@seaweb/coral/components/TextField';
import styled from 'styled-components';
import { InputChipList, InputChip } from '@seaweb/coral/components/Chip';
import { useFieldContext } from '@seaweb/coral/components/Field';

import EllipsisToolTip from './EllipsisToolTip';

const Wrapper = styled.div<{ fullWidth: boolean; invalid: boolean }>`
  font-size: 14px;
  border-radius: 4px;
  background-color: #ffffff;
  color: #293449;
  border: 1px solid #e0e2e5;
  transition: all 0.1s ease;
  cursor: text;
  padding: 0 11px;

  &:hover {
    ${(props) => !props.invalid && 'border-color: #adb2ba;'}
  }

  ${(props) => props.fullWidth && 'width: 100%;'}
  ${(props) => props.invalid && 'border-color: #f5222d;'}

  &:focus-within {
    border-color: #1b92f5;
  }
`;

const StyledTextField = styled(TextField)`
  padding: 8px 5px;

  & + [data-coral-text-field-placeholder] {
    left: 5px;
  }
`;

const StyledInputChip = styled(InputChip)`
  font-weight: 400;
  color: #293449;
  font-size: 14px;
`;

interface Props {
  value: string[];
  handleEnter: (value: string) => boolean | void;
  handleDelete: (index: number) => void;
  placeholder?: ReactNode;
  fullWidth?: boolean;
}

const TagInput = forwardRef<HTMLInputElement, Props>(
  (
    { value, handleDelete, handleEnter, placeholder, fullWidth = false },
    ref
  ) => {
    const localRef = useRef<HTMLInputElement>(null);
    const [inputValue, setInputValue] = useState('');

    const { invalid } = useFieldContext();

    return (
      <Wrapper
        onClick={() => {
          localRef.current.focus();
        }}
        fullWidth={fullWidth}
        invalid={invalid}
      >
        <InputChipList>
          {value.map((item, index) => (
            <StyledInputChip
              key={index}
              index={index}
              onDelete={() => handleDelete(index)}
            >
              <EllipsisToolTip content={item} />
            </StyledInputChip>
          ))}
          <StyledTextField
            ref={(node) => {
              localRef.current = node;
              if (typeof ref === 'function') {
                ref(node);
              } else if (ref) {
                // eslint-disable-next-line no-param-reassign
                ref.current = node;
              }
            }}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            wrapperProps={{
              style: {
                flex: '1',
                minWidth: '100px',
                boxShadow: 'none',
              },
            }}
            placeholder={placeholder}
            onKeyUp={(e) => {
              if (e.key !== 'Enter') return;
              const shouldClear = handleEnter(inputValue);
              if (shouldClear || typeof shouldClear === 'undefined') {
                setInputValue('');
              }
            }}
          />
        </InputChipList>
      </Wrapper>
    );
  }
);

export default TagInput;
