import { FC, useRef, useEffect, useState, ReactNode } from 'react';
import styled from 'styled-components';
import Tooltip from '@seaweb/coral/components/Tooltip';
import { TooltipProps } from '@seaweb/coral/components/Tooltip/Tooltip';

interface Props extends Partial<TooltipProps> {
  /** 必须是文本、行内元素（如span, a等） */
  content: ReactNode;
  /** 默认取text */
  title?: ReactNode;
  /** 内容区的最大宽度；超出且hover显示ToolTip */
  maxWidth?: number | 'initial';
}

const Content = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

// 使用 position: fixed 脱离文档流，从而不影响Content的布局
const CloneContent = styled.div`
  position: fixed;
  visibility: hidden;
`;

/**
 * 判断当内容溢出时展示ToolTip
 *
 * 实现思路一般有两种：
 *
 * 思路1：比较 offsetWidth 和 scrollWidth, scrollWidth >= offsetWidth 则说明内容溢出；
 *  优点：无性能问题；
 *  缺点：存在offsetWidth、scrollWidth在部分浏览器中表现不一致导致判断不准确的问题：
 *    1. IE10 的 offsetWidth 和 scrollWidth 始终相等（即使内容已经溢出了）；
 *    2. Chrome 60 (latest)、IE的一些版本中，内容还未发生溢出，offsetWidth 和 scrollWidth 却不相等（存在1～2px的差值）；
 *
 *  > antd4的Text组件用的这种方式实现，issues中就有用户报了上述问题，目前仍未解决
 *      https://github.com/ant-design/ant-design/blob/956d332c014d5ca0759e574bd9f9b3e0ce0045fa/components/typography/Base/index.tsx#L305
 *      https://github.com/ant-design/ant-design/issues/35397#issuecomment-1119334671
 *
 * 思路2：将内容克隆一份但不显示（visibility:hidden）,和容器宽度比较，如果副本的宽度大于容器宽度，则表示溢出，否则未溢出
 *  优点：能准确判断，兼容所有浏览器；
 *  缺点：在DOM数量多时可能会遇到性能问题，因为页面上多了一份clonedDOM；
 *
 * 综上，为保证可靠性与兼容性，本组件采用思路2的方式实现，适用于对dom数量要求不那么严格的场景
 *
 * 参考：https://stackoverflow.com/a/10017343
 *
 * */
const EllipsisToolTip: FC<Props> = ({
  content,
  title,
  maxWidth = 'initial',
  ...restProps
}) => {
  const contentRef = useRef(null);
  const cloneContentRef = useRef(null);
  const [isShowToolTip, setIsShowToolTip] = useState(false);

  useEffect(() => {
    if (contentRef.current && cloneContentRef.current) {
      const ele = contentRef.current;
      const cloneEle = cloneContentRef.current;
      setIsShowToolTip(cloneEle.clientWidth > ele.clientWidth);
    }
  }, [contentRef, cloneContentRef]);

  const wrapperEle = (
    <Content ref={contentRef} style={{ maxWidth }}>
      {content}
    </Content>
  );
  return (
    <>
      {isShowToolTip ? (
        <Tooltip
          style={{ wordBreak: 'break-all' }}
          title={title === undefined ? content : title}
          {...restProps}
        >
          {wrapperEle}
        </Tooltip>
      ) : (
        wrapperEle
      )}
      <CloneContent ref={cloneContentRef}>{content}</CloneContent>
    </>
  );
};
export default EllipsisToolTip;
