import styled from 'styled-components';

import { THEME } from 'colorTheme';

const SectionTitle = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  padding: 6px 12px;
  line-height: 24px;
  font-weight: 500;
  font-size: 16px;

  ::before {
    content: '';
    position: absolute;
    height: 16px;
    width: 4px;
    left: 0;
    top: 10px;
    background-color: ${THEME.colorClick};
  }
`;

export default SectionTitle;
