import Select, {
  SelectSearch,
  SelectArrowIcon,
  SelectList,
  SelectOption,
  SelectHighlighter,
} from '@seaweb/coral/components/Select';
import { func, number, oneOf, bool, object, arrayOf } from 'prop-types';
import React, { useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';

import { THEME } from 'colorTheme';
import { useTutorialContext } from 'components/Tutorial';
import { DEPARTMENT_SCOPE_PURPOSE, EMPLOYEE_STATUS } from 'consts';
import { useGetRequestCamelized } from 'hooks/useGetRequest';

import Avatar from './Avatar';
import EmptyPlaceholder from './EmptyPlaceholder';
import messages from './messages';
import { Tag } from './Tag';

const Profile = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  word-break: break-word;
  line-height: 20px;
`;

const PhoneEmail = styled.div`
  color: ${THEME.colorTextTertiary};
  line-height: 18px;
`;

function SelectEmployee2({
  value,
  onChange,
  purpose = DEPARTMENT_SCOPE_PURPOSE.VIEW_DEPARTMENT,
  params,
  clearable,
  initialEmployee,
  excludeEmployeeIds = [],
  ...props
}) {
  const tutorialContext = useTutorialContext();
  const [query, setQuery] = useState('');
  const [keyword] = useDebounce(query, 200);
  const [employeesRes] = useGetRequestCamelized(
    '/org/employees/dropdown',
    { purpose, keyword, perPage: 20, ...params },
    { skip: !!tutorialContext }
  );

  const initialEmployeeMap = {};
  if (initialEmployee) {
    initialEmployeeMap[`${initialEmployee.id}`] = initialEmployee;
  }

  const { employees } = tutorialContext || employeesRes || {};
  const employeeMapRef = useRef(initialEmployeeMap);
  if (employees) {
    employees.forEach((emp) => {
      employeeMapRef.current[emp.id] = emp;
    });
  }

  // When there's initial value, it may not be included in the employees.
  // Fetch again specifically for this, but no need to refetch when value changes.
  const initValRef = useRef(value);
  const [valueRes] = useGetRequestCamelized(
    '/org/employees/dropdown',
    { ids: initValRef.current, purpose },
    { skip: !initValRef.current || !!tutorialContext }
  );
  if (valueRes) {
    valueRes.employees.forEach((emp) => {
      employeeMapRef.current[emp.id] = emp;
    });
  }

  const selectedEmployee = employeeMapRef.current[value];

  return (
    <Select
      value={value}
      onChange={(val) => onChange(val, employeeMapRef.current[val])}
      searchValue={query}
      onSearch={setQuery}
    >
      <SelectSearch
        rightElement={<SelectArrowIcon clearable={clearable} />}
        placeholder={<FormattedMessage {...messages.searchEmployee} />}
        {...props}
      >
        {selectedEmployee && (
          <Profile>
            <Avatar
              size={24}
              src={selectedEmployee.avatarSrc}
              style={{ borderRadius: 2 }}
              noParams={!!tutorialContext}
            />
            {selectedEmployee.name}
          </Profile>
        )}
      </SelectSearch>
      {employeesRes && (
        <SelectList emptyPlaceholder={<EmptyPlaceholder type="noData" />}>
          {employeesRes.employees
            .filter((emp) => !excludeEmployeeIds.includes(emp.id))
            .map((emp) => (
              <SelectOption
                key={emp.id}
                value={emp.id}
                style={{ padding: '9px 16px' }}
              >
                <Profile>
                  <Avatar
                    src={emp.avatarSrc}
                    size={32}
                    style={{ borderRadius: 4 }}
                  />
                  <div>
                    <SelectHighlighter text={emp.name} />
                    {emp.employeeStatus === EMPLOYEE_STATUS.LEAVING && (
                      <Tag radius={12}>
                        <FormattedMessage {...messages.leaving} />
                      </Tag>
                    )}
                    <PhoneEmail>
                      <SelectHighlighter
                        text={emp.companyEmail || emp.handPhone}
                      />
                    </PhoneEmail>
                  </div>
                </Profile>
              </SelectOption>
            ))}
        </SelectList>
      )}
    </Select>
  );
}

SelectEmployee2.propTypes = {
  value: number,
  onChange: func,
  purpose: oneOf([
    DEPARTMENT_SCOPE_PURPOSE.MANAGE_PUBLIC_ACCOUNT,
    DEPARTMENT_SCOPE_PURPOSE.VIEW_DEPARTMENT,
  ]),
  params: object,
  clearable: bool,
  initialEmployee: object,
  excludeEmployeeIds: arrayOf(number),
};

export default SelectEmployee2;
