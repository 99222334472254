/* eslint-disable react/no-array-index-key */
import { Popover } from 'antd';
import dayjs from 'dayjs';
import range from 'lodash/range';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
// import moment from 'moment';

import { STYLE } from 'components/Common/constants';
import { WEEK_DISPLAY_INDICATOR } from 'consts';
import { useDateTimeFormatContext } from 'containers/DateTimeFormat/DateTimeFormatContext';

import messages from './messages';

const WeekWrapper = styled.div`
  position: relative;
  display: flex;
  margin: auto 0;
`;

const DayWrapper = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  font-size: 12px;
  overflow: hidden;

  color: ${({ isActive, grayed }) =>
    !grayed && isActive ? STYLE.colorClick : STYLE.colorText};
  border: 1px solid
    ${({ isActive }) => (isActive ? STYLE.colorClick : STYLE.colorBorder)};
  border-color: ${({ grayed }) => grayed && 'rgba(32, 35, 37, 0.2)'};
  border-radius: ${({ simplified }) => (simplified ? '50%' : '16px')};
  height: ${({ simplified }) => (simplified ? 24 : 32)}px;
  width: ${({ simplified }) => (simplified ? 24 : 58)}px;
  cursor: ${({ editable }) => editable && 'pointer'};

  p {
    position: absolute;
  }

  & + span {
    margin-left: 8px;
  }
`;

const AmOverlay = styled.div`
  position: absolute;
  top: 0;
  margin: auto;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;

  width: ${({ simplified }) => (simplified ? 24 : 58)}px;
  height: ${({ simplified }) => (simplified ? 11 : 15)}px;
  background-color: ${({ grayed }) =>
    grayed ? 'rgba(32, 35, 37, 0.1)' : 'rgba(27, 146, 245, 0.2)'};
`;

const PmOverlay = styled.div`
  position: absolute;
  bottom: 0;
  margin: auto;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;

  width: ${({ simplified }) => (simplified ? 24 : 58)}px;
  height: ${({ simplified }) => (simplified ? 11 : 15)}px;
  background-color: ${({ grayed }) =>
    grayed ? 'rgba(32, 35, 37, 0.1)' : 'rgba(27, 146, 245, 0.2)'};
`;

const PopoverMenu = styled.div`
  padding: 4px 0;

  button {
    width: 100%;
    padding: 8px 16px;
    cursor: pointer;
    display: block;
    outline: none;

    &:hover {
      background-color: rgba(220, 231, 245, 0.3);
    }
  }
`;

function WeekDisplay(props) {
  const {
    data = [],
    editable,
    onChange,
    simplified = false,
    grayed = false,
  } = props;
  const { daySingleFormat, dayShortFormat } = useDateTimeFormatContext();

  const handleDayTranslation = (translation, locale) => {
    switch (locale) {
      case 'th':
        return translation.format(daySingleFormat).slice(0, -1);

      case 'vi':
        return translation.format(daySingleFormat);

      default:
        return translation.format(daySingleFormat).slice(0, 1);
    }
  };

  const dayObj = range(7).map((day) => {
    const locale = localStorage.getItem('locale');
    const dateTranslation = dayjs().day(day);
    return {
      short: handleDayTranslation(dateTranslation, locale),
      abbr: dateTranslation.format(dayShortFormat).toLocaleUpperCase(),
    };
  });

  const dataArray = [...data];
  while (dataArray.length < 7) dataArray.push(0);

  const popoverContent = (index) => (
    <PopoverMenu>
      <button
        type="button"
        onClick={() => onDayChange(index, WEEK_DISPLAY_INDICATOR.AM)}
      >
        <FormattedMessage {...messages.firstHalf} />
      </button>
      <button
        type="button"
        onClick={() => onDayChange(index, WEEK_DISPLAY_INDICATOR.PM)}
      >
        <FormattedMessage {...messages.secondHalf} />
      </button>
    </PopoverMenu>
  );

  const onDayChange = (index, selected) => {
    const newDataArray = [...dataArray];
    if (selected) {
      newDataArray[index] = selected;
    } else if (newDataArray[index] === WEEK_DISPLAY_INDICATOR.UNCHECKED) {
      newDataArray[index] = WEEK_DISPLAY_INDICATOR.FULL;
    } else {
      newDataArray[index] = WEEK_DISPLAY_INDICATOR.UNCHECKED;
    }
    onChange(newDataArray);
  };

  return (
    <WeekWrapper>
      {dataArray.map((day, index) => {
        const isActive = day !== WEEK_DISPLAY_INDICATOR.UNCHECKED;
        const dayComponent = (
          <DayWrapper
            key={index}
            grayed={grayed}
            simplified={simplified}
            isActive={isActive}
            editable={editable}
            onClick={() => editable && onDayChange(index)}
            id={`work-group-workdays-${index}`}
          >
            {(day === WEEK_DISPLAY_INDICATOR.AM ||
              day === WEEK_DISPLAY_INDICATOR.FULL) && (
              <AmOverlay simplified={simplified} grayed={grayed} />
            )}
            {(day === WEEK_DISPLAY_INDICATOR.PM ||
              day === WEEK_DISPLAY_INDICATOR.FULL) && (
              <PmOverlay simplified={simplified} grayed={grayed} />
            )}
            <p>{simplified ? dayObj[index].short : dayObj[index].abbr}</p>
          </DayWrapper>
        );
        return !editable ? (
          dayComponent
        ) : (
          <Popover
            key={index}
            placement="bottom"
            content={popoverContent(index)}
            mouseEnterDelay={0.5}
          >
            {dayComponent}
          </Popover>
        );
      })}
    </WeekWrapper>
  );
}

WeekDisplay.propTypes = {
  data: PropTypes.array,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
  simplified: PropTypes.bool,
  grayed: PropTypes.bool,
};

export default WeekDisplay;
