import Button from '@seaweb/coral/components/Button';
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@seaweb/coral/components/Dialog';
import useControlProp from '@seaweb/coral/hooks/useControlProp';
import useWrapEvent from '@seaweb/coral/hooks/useWrapEvent';
import { element, node, func, bool } from 'prop-types';
import React, { cloneElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import messages from './messages';

const StyledDialog = styled(Dialog)`
  width: 480px;
`;

function ButtonConfirmation({
  confirming: confirmingProp,
  initialConfirming = false,
  onConfirming,
  button,
  confirmationButton = (
    <Button colorType="error">
      <FormattedMessage {...messages.confirm} />
    </Button>
  ),
  onConfirm,
  title,
  children,
  ...props
}) {
  const [confirming, setConfirming] = useControlProp(
    confirmingProp,
    initialConfirming,
    onConfirming
  );

  const handleClick = useWrapEvent(button.props.onClick, () =>
    setConfirming(true)
  );

  return (
    <>
      {cloneElement(button, { onClick: handleClick })}

      <StyledDialog
        immediate
        isOpen={confirming}
        onClose={() => setConfirming(false)}
        {...props}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setConfirming(false)}>
            <FormattedMessage {...messages.cancel} />
          </Button>
          {cloneElement(confirmationButton, {
            onClick: () => {
              onConfirm();
              setConfirming(false);
            },
          })}
        </DialogActions>
      </StyledDialog>
    </>
  );
}

ButtonConfirmation.propTypes = {
  confirming: bool,
  initialConfirming: bool,
  onConfirming: func,
  button: element.isRequired,
  title: node.isRequired,
  confirmationButton: element,
  onConfirm: func.isRequired,
  children: node.isRequired,
};

export default ButtonConfirmation;
