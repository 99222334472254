import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

export const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 18px 24px 24px;
  overflow: hidden;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : 'none')};
`;

class MainWrapper extends PureComponent {
  render() {
    return <Content {...this.props}>{this.props.children}</Content>;
  }
}

MainWrapper.propTypes = {
  children: PropTypes.any,
};

export default MainWrapper;
