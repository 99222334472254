import {
  usePusherContext,
  PusherPositions,
} from '@seaweb/coral/components/Pusher';
import Toast, { ToastTypes } from '@seaweb/coral/components/Toast';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import toastMessages from 'components/Common/ToastMessageHandler/messages';

export default function useErrorToast() {
  const { push } = usePusherContext();

  return useCallback(
    (errorCode) => {
      const message = toastMessages[`e${errorCode}`];
      return push(
        <Toast type={ToastTypes.Error}>
          {message ? <FormattedMessage {...message} /> : 'Unknown Error'}
        </Toast>,
        {
          position: PusherPositions.MiddleCenter,
          duration: 3000,
        }
      );
    },
    [push]
  );
}

export function useCustomErrorToast(options?: { position?: PusherPositions }) {
  const { push } = usePusherContext();
  const { position } = options || {};
  return useCallback(
    (message = 'Unknown Error') =>
      push(<Toast type={ToastTypes.Error}>{message}</Toast>, {
        position: position || PusherPositions.MiddleCenter,
        duration: 3000,
      }),
    [push, position]
  );
}
