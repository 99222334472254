import { string, shape, node } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

/**
 * Stick the icon with the last word of message,
 * when the width is not enough for the content,
 * the icon and the last word will be wrapped together to another line
 */

const StyledSpan = styled.span`
  white-space: ${(props) => (props.isOneWord ? 'nowrap' : 'normal')};
  span {
    white-space: nowrap;
  }
  svg {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
  }
`;

const LabelWithIcon = ({ label, icon, ...props }) => (
  <FormattedMessage {...label}>
    {(msg) => {
      const empty = '';
      const whiteSpace = ' ';
      const words = msg.split(whiteSpace);
      const lastWord = words[words.length - 1];
      const headWords =
        words.slice(0, words.length - 1).join(whiteSpace) +
        (lastWord ? whiteSpace : empty);
      return (
        <StyledSpan isOneWord={words.length === 1} {...props}>
          {headWords}
          <span>
            {lastWord} {icon}
          </span>
        </StyledSpan>
      );
    }}
  </FormattedMessage>
);

LabelWithIcon.propTypes = {
  label: shape({
    id: string.isRequired,
    defaultMessage: string,
  }).isRequired,
  icon: node.isRequired,
};

export default LabelWithIcon;
