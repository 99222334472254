import CoralField from '@seaweb/coral/components/Field';
import styled from 'styled-components';

export const DialogField = styled(CoralField)`
  &:first-child {
    margin-top: 0;
  }
`;

export const FilterField = styled(CoralField)`
  margin: 0 16px 0 0;
  [data-coral-field-input] {
    width: 304px;
  }
`;
