import Tooltip from '@seaweb/coral/components/Tooltip';
import useDebounceCallback from '@seaweb/coral/hooks/useDebounceCallback';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

function DebouncedTooltip(props) {
  const [isActive, setIsActive] = useState(false);
  const debouncedSetActive = useDebounceCallback(
    (val) => setIsActive(val),
    150
  );

  return (
    <Tooltip
      active={isActive}
      onMouseEnter={() => debouncedSetActive(true)}
      onMouseLeave={() => debouncedSetActive(false)}
      {...props}
      style={{
        pointerEvents: 'auto',
        userSelect: 'auto',
        whiteSpace: 'pre-wrap',
        ...(props.style || {}),
      }}
    >
      <span
        onMouseEnter={() => {
          setIsActive(true);
          debouncedSetActive(true);
        }}
        onMouseLeave={() => debouncedSetActive(false)}
      >
        {props.children}
      </span>
    </Tooltip>
  );
}

DebouncedTooltip.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
};

export default DebouncedTooltip;
