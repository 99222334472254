import {
  usePusherContext,
  PusherPositions,
} from '@seaweb/coral/components/Pusher';
import Toast, { ToastTypes } from '@seaweb/coral/components/Toast';
import useEventCallback from '@seaweb/coral/hooks/useEventCallback';
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import messages from 'components/Common/ToastMessageHandler/messages';
import { RequestStatus } from 'consts';
import { forever } from 'utils/promise';
import request, { camelizeKeys } from 'utils/request';

export const useBaseSafeRequest = () => {
  const unmountedRef = useRef(false);

  useEffect(
    () => () => {
      unmountedRef.current = true;
    },
    []
  );

  /**
   * @param {string} url
   * @param {string} method
   * @param {any} data
   */
  return useCallback(async (url, method?, data?) => {
    const res = await request(url, method, data);
    if (unmountedRef.current) await forever;

    return res;
  }, []);
};

/**
 * https://kentcdodds.com/blog/stop-using-isloading-booleans
 */
export const useLoadingRequest = () => {
  const req = useBaseSafeRequest();
  const [status, setStatus] = useState<RequestStatus>(RequestStatus.Idle);

  const wrappedRequest = useEventCallback(
    async (...args: [string, string, unknown]) => {
      try {
        setStatus(RequestStatus.Pending);
        const result = await req(...args);

        setStatus(RequestStatus.Resolved);
        return result;
      } catch (err) {
        setStatus(RequestStatus.Error);
        throw err;
      }
    }
  );

  return {
    request: wrappedRequest,
    status,
  };
};

/**
 * Both `useGetRequestCamelized` and `usePostRequestCamelized` are called in every render. In case you only need to send
 * request in a specific circumstance, such as submitting form, use `useLoadingRequestCamelized` instead.
 * `useLoadingRequestCamelized` is still a hook which is called in every render. The difference is that it returns the tool to call
 * request instead of calling the request directly.
 * 
 * https://stackoverflow.com/questions/41112313/how-to-use-generics-with-arrow-functions-in-typescript-jsx-with-react
 *
 * @usage const { request, status } = useLoadingRequestCamelized()
 *
 * @returns {{{(any) => Promise} request, status: string}}
 * - request: Function which is used to sending request, return a promise, like Fetch API
 * - status: Status of ongoing request, can be: `idle`, `pending`, `resolved` or `error`
 *
 * @example
 * async function fn() {
    try {
      const response = await request(url, method, params | body);
    }
    catch (err) {
      // err.code
      // err.originalResponse
    }
  }
 * @param {string} url: The API url of the request
 * @param {string} method: Request method (POST, GET, PUT, DELETE)
 * @param {object} params or body: An object, will be `params` if method is GET, otherwise a `body`
 *
 * @return {object} response: If response status code is 200
 * @return {object} error (in catch block): If response status is different from 200
 */
export const useLoadingRequestCamelized = <
  T extends Record<string, unknown>
>() => {
  const req = useBaseSafeRequest();
  const [status, setStatus] = useState<RequestStatus>(RequestStatus.Idle);

  const wrappedRequest = useEventCallback(
    async (...args: [string, string, T]) => {
      try {
        setStatus(RequestStatus.Pending);
        const result = await req(...args);

        setStatus(RequestStatus.Resolved);
        return camelizeKeys(result);
      } catch (err) {
        setStatus(RequestStatus.Error);
        throw camelizeKeys(err);
      }
    }
  );

  return {
    request: wrappedRequest,
    status,
  };
};

/**
 * Prevent 'Warning: Can’t call setState (or forceUpdate) on an unmounted component.'
 * by ignoring response on unmounted component.
 */
export const useSafeRequest = () => {
  const req = useBaseSafeRequest();

  const pusher = usePusherContext();
  const pusherRef = useRef(pusher);
  pusherRef.current = pusher;

  /**
   * @param {string} url
   * @param {string} method
   * @param {any} data
   */
  return useCallback(
    async (url, method, data) => {
      try {
        const res = await req(url, method, data);
        return res;
      } catch (err) {
        if (pusherRef.current) {
          const message = messages[`e${err.code}`];
          pusherRef.current.push(
            <Toast type={ToastTypes.Error}>
              {message ? <FormattedMessage {...message} /> : 'Unknown Error'}
            </Toast>,
            { position: PusherPositions.BottomCenter }
          );
        }
        return forever;
      }
    },
    [req]
  );
};
