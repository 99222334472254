import React from 'react';
import styled from 'styled-components';

import { THEME } from 'colorTheme';

const ActionsInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: auto;

  > * {
    margin-left: 16px;
  }
`;

const Actions = styled.div.attrs(({ children }) => ({
  id: 'actions-bar',
  children: <ActionsInner>{children}</ActionsInner>,
}))`
  position: fixed;
  z-index: 1;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 14px;
  background-color: white;
  box-shadow: ${THEME.boxShadowModal};
`;

export default Actions;
