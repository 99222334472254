import { defineMessages } from 'react-intl';

export const scope = 'containers.Leave.LeaveSummary';

export default defineMessages({
  leaveSummary: {
    id: `${scope}.leaveSummary`,
    defaultMessage: 'Leave Summary',
  },
  haventAddLeaveType: {
    id: `${scope}.haventAddLeaveType`,
    defaultMessage: "You haven't add any leave type yet.",
  },
  haventAddLeavePolicy: {
    id: `${scope}.haventAddLeavePolicy`,
    defaultMessage: "You haven't add any leave policy yet.",
  },
  goToConfigurationAddLeaveTypes: {
    id: `${scope}.goToConfigurationAddLeaveTypes`,
    defaultMessage: 'Go to “Configuration” add leave types and policy.',
  },
  addLeaveType: {
    id: `${scope}.addLeaveType`,
    defaultMessage: 'Add Leave Type',
  },
  addLeavePolicy: {
    id: `${scope}.addLeavePolicy`,
    defaultMessage: 'Add Leave Policy',
  },
  nameLeaveSummary: {
    id: `${scope}.nameLeaveSummary`,
    defaultMessage: "{name}'s Leave Summary",
  },
  joinedOnDate: {
    id: `${scope}.joinedOnDate`,
    defaultMessage: 'Joined on {date}',
  },
  joiningDateNotSet: {
    id: `${scope}.joiningDateNotSet`,
    defaultMessage: 'Joining date not set',
  },
  leaveDetail: {
    id: `${scope}.leaveDetail`,
    defaultMessage: 'Leave Detail',
  },
  detailsOn: {
    id: `${scope}.detailsOn`,
    defaultMessage: 'Details On',
  },
  balanceDetail: {
    id: `${scope}.balanceDetail`,
    defaultMessage: 'Balance Details',
  },
  leaveApplication: {
    id: `${scope}.leaveApplication`,
    defaultMessage: 'Leave Application',
  },
  creditApplication: {
    id: `${scope}.creditApplication`,
    defaultMessage: 'Credit Application',
  },
  balanceChangeLog: {
    id: `${scope}.balanceChangeLog`,
    defaultMessage: 'Balance Change Log',
  },
  leaveTypeWontEffect: {
    id: `${scope}.leaveTypeWontEffect`,
    defaultMessage: 'Leave type won’t effect until joining date has been set.',
  },
  goToEmployeeProfile: {
    id: `${scope}.goToEmployeeProfile`,
    defaultMessage: 'Go to Employee Profile page to set joining date',
  },
  setJoiningDate: {
    id: `${scope}.setJoiningDate`,
    defaultMessage: 'Set Joining Date',
  },
  employeeHasntApplied: {
    id: `${scope}.employeeHasntApplied`,
    defaultMessage: "Employee hasn't applied any leave yet.",
  },
  employeeHasntAppliedCredit: {
    id: `${scope}.employeeHasntAppliedCredit`,
    defaultMessage: "Employee hasn't applied any credit yet.",
  },
  noBalanceChangeLog: {
    id: `${scope}.noBalanceChangeLog`,
    defaultMessage: 'No balance change log',
  },
  allEvents: {
    id: `${scope}.allEvents`,
    defaultMessage: 'All Events',
  },
  changeLogInfo: {
    id: `${scope}.changeLogInfo`,
    defaultMessage: '{action} the leave balance at {time}',
  },
  // approvER statuses
  leaveApplicationStatusDisplayPending: {
    id: `${scope}.leaveApplicationStatusDisplayPending`,
    defaultMessage: 'Pending',
  },
  leaveApplicationStatusDisplayApproved: {
    id: `${scope}.leaveApplicationStatusDisplayApproved`,
    defaultMessage: 'Approved',
  },
  leaveApplicationStatusDisplayRejected: {
    id: `${scope}.leaveApplicationStatusDisplayRejected`,
    defaultMessage: 'Rejected',
  },
  leaveApplicationStatusDisplayTerminated: {
    id: `${scope}.leaveApplicationStatusDisplayTerminated`,
    defaultMessage: 'Terminated',
  },
  leaveApplicationStatusDisplayWithdrawn: {
    id: `${scope}.leaveApplicationStatusDisplayWithdrawn`,
    defaultMessage: 'Withdrawn',
  },
  // approvAL statuses
  leaveApprovalStatusDisplayApproved: {
    id: `${scope}.leaveApprovalStatusDisplayApproved`,
    defaultMessage: 'Approved',
  },
  leaveApprovalStatusDisplayRejected: {
    id: `${scope}.leaveApprovalStatusDisplayRejected`,
    defaultMessage: 'Rejected',
  },
  // approval type
  approvalDisplaySubmit: {
    id: `${scope}.approvalDisplaySubmit`,
    defaultMessage: 'Apply Leave',
  },
  approvalDisplayWithdraw: {
    id: `${scope}.approvalDisplayWithdraw`,
    defaultMessage: 'Withdraw',
  },
  //
  leaveType: {
    id: `${scope}.leaveType`,
    defaultMessage: 'Leave Type',
  },
  leaveRule: {
    id: `${scope}.leaveRule`,
    defaultMessage: 'Leave Rule',
  },
  leaveTypeRule: {
    id: `${scope}.leaveTypeRule`,
    defaultMessage: 'Leave Type (Rule)',
  },
  leaveDate: {
    id: `${scope}.leaveDate`,
    defaultMessage: 'Leave Date',
  },
  noData: {
    id: `${scope}.noData`,
    defaultMessage: 'No Data',
  },
  allLeavePolicy: {
    id: `${scope}.allLeavePolicy`,
    defaultMessage: 'All Leave Policy',
  },
  allLeaveType: {
    id: `${scope}.allLeaveType`,
    defaultMessage: 'All Leave Type',
  },
  allStatus: {
    id: `${scope}.allStatus`,
    defaultMessage: 'All Status',
  },
  system: {
    id: `${scope}.system`,
    defaultMessage: 'System',
  },
  selectedStatus: {
    id: `${scope}.selectedStatus`,
    defaultMessage: '{count} Status',
  },
  selectedAction: {
    id: `${scope}.selectedAction`,
    defaultMessage: '{count} Action',
  },
  date: {
    id: `${scope}.date`,
    defaultMessage: 'Date',
  },
  duration: {
    id: `${scope}.duration`,
    defaultMessage: 'Duration',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Description',
  },
  joiningDate: {
    id: `${scope}.joiningDate`,
    defaultMessage: 'Joining Date',
  },
  notSet: {
    id: `${scope}.notSet`,
    defaultMessage: 'Not Set',
  },
  approval: {
    id: `${scope}.approval`,
    defaultMessage: 'Approval',
  },
  skipTheStep: {
    id: `${scope}.skipTheStep`,
    defaultMessage: 'Skip the step.',
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Delete',
  },
  notice: {
    id: `${scope}.notice`,
    defaultMessage: 'Notice',
  },
  importBalance: {
    id: `${scope}.importBalance`,
    defaultMessage: 'Import Balance',
  },
  importAdjustment: {
    id: `${scope}.importAdjustment`,
    defaultMessage: 'Import Adjustment',
  },
  youCanImportBalance: {
    id: `${scope}.youCanImportBalance`,
    defaultMessage:
      'You can import “Balance” , “Taken”, “Carryover” column of leave. If you want to edit “Adjust” column, please select the Import Adjustment function.',
  },
  youCanImportAdjustment: {
    id: `${scope}.youCanImportAdjustment`,
    defaultMessage:
      'You can import “Adjustment” column of leave. If you want to edit balance, please select the Import Balance function.',
  },
  leaveApplicationHistory: {
    id: `${scope}.leaveApplicationHistory`,
    defaultMessage: 'Leave Application History',
  },
  creditApplicationHistory: {
    id: `${scope}.creditApplicationHistory`,
    defaultMessage: 'Credit Application History',
  },
  modify: {
    id: `${scope}.modify`,
    defaultMessage: 'Modify',
  },
  durationDays: {
    id: `${scope}.durationDays`,
    defaultMessage: '{duration} days',
  },
  numberDaysTaken: {
    id: `${scope}.numberDaysTaken`,
    defaultMessage: '{number} days taken',
  },
  modifyCreditApplication: {
    id: `${scope}.modifyCreditApplication`,
    defaultMessage: 'Modify Credit Application',
  },
  employee: {
    id: `${scope}.employee`,
    defaultMessage: 'Employee',
  },
  creditBalance: {
    id: `${scope}.creditBalance`,
    defaultMessage: 'Credit Balance',
  },
  credit: {
    id: `${scope}.credit`,
    defaultMessage: 'Credit',
  },
  deduct: {
    id: `${scope}.deduct`,
    defaultMessage: 'Deduct',
  },
  validPeriod: {
    id: `${scope}.validPeriod`,
    defaultMessage: 'Valid Period',
  },
  expiryDate: {
    id: `${scope}.expiryDate`,
    defaultMessage: 'Expiry Date',
  },
  validOnFromTo: {
    id: `${scope}.validOnFromTo`,
    defaultMessage: 'Valid on {from} - {to}',
  },
  balanceChange: {
    id: `${scope}.balanceChange`,
    defaultMessage: 'Balance Change',
  },
  operation: {
    id: `${scope}.operation`,
    defaultMessage: 'Operation',
  },
  detail: {
    id: `${scope}.detail`,
    defaultMessage: 'Detail',
  },
  effectiveDate: {
    id: `${scope}.effectiveDate`,
    defaultMessage: 'Effective Date',
  },
  operator: {
    id: `${scope}.operator`,
    defaultMessage: 'Operator',
  },
  days: {
    id: `${scope}.days`,
    defaultMessage: 'day(s)',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  adjust: {
    id: `${scope}.adjust`,
    defaultMessage: 'Adjust',
  },
  reason: {
    id: `${scope}.reason`,
    defaultMessage: 'Reason',
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: 'Confirm',
  },
  import: {
    id: `${scope}.import`,
    defaultMessage: 'Import',
  },
  export: {
    id: `${scope}.export`,
    defaultMessage: 'Export',
  },
  importSuccess: {
    id: `${scope}.importSuccess`,
    defaultMessage: 'Imported successfully',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
  changeDetail: {
    id: `${scope}.changeDetail`,
    defaultMessage: 'Change Detail',
  },
  submittedDate: {
    id: `${scope}.submittedDate`,
    defaultMessage: 'Submitted Date',
  },
  approver: {
    id: `${scope}.approver`,
    defaultMessage: 'Approver',
  },
  changeType: {
    id: `${scope}.changeType`,
    defaultMessage: 'Change Type',
  },
  balanceBefore: {
    id: `${scope}.balanceBefore`,
    defaultMessage: 'Balance Before',
  },
  balanceAfter: {
    id: `${scope}.balanceAfter`,
    defaultMessage: 'Balance After',
  },
  accrualForRange: {
    id: `${scope}.accrualForRange`,
    defaultMessage: 'Accrual for {start} - {end}',
  },
  validOnRange: {
    id: `${scope}.validOnRange`,
    defaultMessage: 'Valid on {start} - {end}',
  },
  invalidOnDate: {
    id: `${scope}.invalidOnDate`,
    defaultMessage: 'Invalid on {date}',
  },
  balanceUpdated: {
    id: `${scope}.balanceUpdated`,
    defaultMessage: 'Balance Updated',
  },
  creditWillEffectImmediately: {
    id: `${scope}.creditWillEffectImmediately`,
    defaultMessage:
      'Credit will effect immediately and expired on the end of current accrued period.',
  },
  // action filters
  balanceChangeActionEntitle: {
    id: `${scope}.balanceChangeActionEntitle`,
    defaultMessage: 'Entitled',
  },
  balanceChangeActionCarryoverExpired: {
    id: `${scope}.balanceChangeActionCarryoverExpired`,
    defaultMessage: 'Expired',
  },
  balanceChangeActionCarryover: {
    id: `${scope}.balanceChangeActionCarryover`,
    defaultMessage: 'Carryover',
  },
  balanceChangeActionPolicyUpdate: {
    id: `${scope}.balanceChangeActionPolicyUpdate`,
    defaultMessage: 'Policy Update',
  },
  balanceChangeActionCredit: {
    id: `${scope}.balanceChangeActionCredit`,
    defaultMessage: 'Credit',
  },
  balanceChangeActionDeduct: {
    id: `${scope}.balanceChangeActionDeduct`,
    defaultMessage: 'Deduct',
  },
  balanceChangeActionRuleUpdate: {
    id: `${scope}.balanceChangeActionRuleUpdate`,
    defaultMessage: 'Rule Update',
  },
  balanceChangeActionImport: {
    id: `${scope}.balanceChangeActionImport`,
    defaultMessage: 'Import',
  },
  balanceChangeActionOffboard: {
    id: `${scope}.balanceChangeActionOffboard`,
    defaultMessage: 'Offboard',
  },
  balanceChangeActionEmployeeUpdate: {
    id: `${scope}.balanceChangeActionEmployeeUpdate`,
    defaultMessage: 'Employee Info Update',
  },
  balanceChangeActionApplied: {
    id: `${scope}.balanceChangeActionApplied`,
    defaultMessage: 'Applied',
  },
  balanceChangeActionWithdraw: {
    id: `${scope}.balanceChangeActionWithdraw`,
    defaultMessage: 'Withdraw',
  },
  balanceChangeActionPreferenceUpdate: {
    id: `${scope}.balanceChangeActionPreferenceUpdate`,
    defaultMessage: 'Preference Update',
  },
  // approver type --- duplicated from LeaveApproval/messages.js ---
  approverDepartmentHead: {
    id: `${scope}.approverDepartmentHead`,
    defaultMessage: 'Department Head',
  },
  approverReportingManager: {
    id: `${scope}.approverReportingManager`,
    defaultMessage: 'Reporting Manager',
  },
  approverWhitelist: {
    id: `${scope}.approverWhitelist`,
    defaultMessage: 'Whitelist',
  },
  // approver method --- duplicated from LeaveApproval/messages.js ---
  approvalMethodCountersign: {
    id: `${scope}.approvalMethodCountersign`,
    defaultMessage: 'Countersign',
  },
  approvalMethodPreempt: {
    id: `${scope}.approvalMethodPreempt`,
    defaultMessage: 'Preempt',
  },
  noCreditApplication: {
    id: `${scope}.noCreditApplication`,
    defaultMessage: 'No credit application.',
  },
  noLeaveApplication: {
    id: `${scope}.noLeaveApplication`,
    defaultMessage: 'No leave application.',
  },
  balanceDetailCalendarTooltip: {
    id: `${scope}.balanceDetailCalendarTooltip`,
    defaultMessage:
      'Balance Details is the snapshot for last day of each month.',
  },
  entitled: {
    id: `${scope}.entitled`,
    defaultMessage: 'Entitled',
  },
  carryover: {
    id: `${scope}.carryover`,
    defaultMessage: 'Carryover',
  },
  adjustment: {
    id: `${scope}.adjustment`,
    defaultMessage: 'Adjustment',
  },
  expired: {
    id: `${scope}.expired`,
    defaultMessage: 'Expired',
  },
  total: {
    id: `${scope}.total`,
    defaultMessage: 'Total',
  },
  taken: {
    id: `${scope}.taken`,
    defaultMessage: 'Taken',
  },
  balance: {
    id: `${scope}.balance`,
    defaultMessage: 'Balance',
  },
  enterTheRemark: {
    id: `${scope}.enterTheRemark`,
    defaultMessage: 'Enter the remark',
  },
  lastApprover: {
    id: `${scope}.lastApprover`,
    defaultMessage: 'Last Approver',
  },
  approvalDate: {
    id: `${scope}.approvalDate`,
    defaultMessage: 'Approval Date',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save',
  },
  terminated: {
    id: `${scope}.terminated`,
    defaultMessage: 'Terminated',
  },
  leaveSummaryNotAvailable: {
    id: `${scope}.leaveSummaryNotAvailable`,
    defaultMessage: 'Leave Summary is not available',
  },
  leaveSummaryNotAvailableReasons: {
    id: `${scope}.leaveSummaryNotAvailableReasons`,
    defaultMessage:
      'Check the followings to make sure Leave Summary run well:\n1. Setup leave types and policy\n2. Assign policy to your employees\n3. Set join date of employees',
  },
  department: {
    id: `${scope}.department`,
    defaultMessage: 'Department',
  },
  policy: {
    id: `${scope}.policy`,
    defaultMessage: 'Policy',
  },
  noleavePolicyInUse: {
    id: `${scope}.noleavePolicyInUse`,
    defaultMessage: 'No leave policy currently in use',
  },
  updateBalance: {
    id: `${scope}.updateBalance`,
    defaultMessage: 'Update Balance',
  },
  joiningDateTooltip: {
    id: `${scope}.joiningDateTooltip`,
    defaultMessage:
      'Join Date is the precondition of leave balance entitlement',
  },
  youAreSettingJoinDate: {
    id: `${scope}.youAreSettingJoinDate`,
    defaultMessage: 'You are setting join date for {name}',
  },
  selectJoiningDate: {
    id: `${scope}.selectJoiningDate`,
    defaultMessage: 'Select Join Date',
  },
  importBalanceDescription: {
    id: `${scope}.importBalanceDescription`,
    defaultMessage:
      'You can import “Balance”, and optional import “Taken” and “Carryover” of leave here. If you want to edit “Adjustment”, simply switch to <b>Import Adjustment</b> function. ',
  },
  exportBalanceOfEmployee: {
    id: `${scope}.exportBalanceOfEmployee`,
    defaultMessage: 'Step 1. Export current balance of employees',
  },
  selectAndExportBalanceDetail: {
    id: `${scope}.selectAndExportBalanceDetail`,
    defaultMessage: 'Select employee & export balance details',
  },
  selectAndExportTemplate: {
    id: `${scope}.selectAndExportTemplate`,
    defaultMessage: 'Select employee & export template',
  },
  andFollowInstruction: {
    id: `${scope}.andFollowInstruction`,
    defaultMessage: 'and follow instructions to fill in.',
  },
  uploadCompletedTable: {
    id: `${scope}.uploadCompletedTable`,
    defaultMessage: 'Step 2. Upload completed table',
  },
  uploadFailureDescription: {
    id: `${scope}.uploadFailureDescription`,
    defaultMessage:
      'Upon data import failure, the system will return an excel file, where you can download to check the reason of failure.',
  },
  importAdjustmentDescription: {
    id: `${scope}.importAdjustmentDescription`,
    defaultMessage:
      'You can import “Adjustment” of leave. If you want to edit “Balance” , simply switch to <b>Import Balance</b> function.',
  },
  exportAdjustmentTemplate: {
    id: `${scope}.exportAdjustmentTemplate`,
    defaultMessage: 'Step 1. Export adjustment template',
  },
  exportEmployeesNotice: {
    id: `${scope}.exportEmployeesNotice`,
    defaultMessage: `Employees who are not set join date or not assigned leave policy won't be exported.`,
  },
  selectEmployeeToExport: {
    id: `${scope}.selectEmployeeToExport`,
    defaultMessage: 'Select Employee to Export',
  },
  uploadFailed: {
    id: `${scope}.uploadFailed`,
    defaultMessage: 'Upload failed, please try uploading again',
  },
  uploadingAndMatching: {
    id: `${scope}.uploadingAndMatching`,
    defaultMessage: 'Uploading and matching..., please do not leave this page',
  },
  goToCheck: {
    id: `${scope}.goToCheck`,
    defaultMessage: 'Go to Check',
  },
  uploadAgain: {
    id: `${scope}.uploadAgain`,
    defaultMessage: 'Upload Again',
  },
  importingData: {
    id: `${scope}.importingData`,
    defaultMessage: 'Importing Data',
  },
  processing: {
    id: `${scope}.processing`,
    defaultMessage: 'Processing...',
  },
  importSuccessfully: {
    id: `${scope}.importSuccessfully`,
    defaultMessage: '{success} items import sucessfully.',
  },
  importPartiallyFailed: {
    id: `${scope}.importPartiallyFailed`,
    defaultMessage:
      '{success} items import sucessfully, {failed} items import failed.',
  },
  importError: {
    id: `${scope}.importError`,
    defaultMessage: 'Template not matched, import failed.',
  },
  browseOrDragToUpload: {
    id: `${scope}.browseOrDragToUpload`,
    defaultMessage: '<span>Browse</span> or drag a file to this area to upload',
  },
  gotIt: {
    id: `${scope}.gotIt`,
    defaultMessage: 'Got it',
  },
  howToUnderstandBalance: {
    id: `${scope}.howToUnderstandBalance`,
    defaultMessage: 'How to understand balance?',
  },
  howToUnderstandBalanceBalance: {
    id: `${scope}.howToUnderstandBalanceBalance`,
    defaultMessage:
      "<b>Balance</b> is current available quota that employees can take leave. It's the difference of Total days and Taken days.\n<b>Balance = Total - Taken</b>",
  },
  howToUnderstandBalanceTotal: {
    id: `${scope}.howToUnderstandBalanceTotal`,
    defaultMessage:
      "<b>Total</b>: it's total quota employee is eligible to take leave, and consists of 3 sources (which are entitled, carryover and adjust) according to different leave types.\n<b>Total = Entitled + Carryover + Adjustment</b>",
  },
  howToUnderstandBalanceUnlimited: {
    id: `${scope}.howToUnderstandBalanceUnlimited`,
    defaultMessage:
      'For leave type <b>No balance, just track taken</b> (e.g. No pay leave):\nThere is no Total exists in this type, it only record the days employee take leave after the last reset.',
  },
  howToUnderstandBalancePeriodical: {
    id: `${scope}.howToUnderstandBalancePeriodical`,
    defaultMessage:
      'For leave type <b>Periodical Balance</b> (e.g. Annual leave):\n4 sources in this leave type.\n<b>・Entitled</b>: given to the employee for the current period (year) based on the entitlement rule defined in the leave rule settings. It will be calculated automatically and can only be revised if there is any settings change (leave rule, employee information, etc)\n<b>・Carryover</b>: the unused leave balance brought forward from previous period. Maximum carryover and expiry can be set in the carryover settings in Leave Rule.\n<b>・Adjust</b>: includes credit and deduct, which is applicable to leave that type is periodical balance or credit balance upon approval deduct.\n<b>・Expired</b>: will increase if there is expired balance or carryover. The expired will be available for backdated application depends on the backdate application setup.',
  },
  howToUnderstandBalanceCredit: {
    id: `${scope}.howToUnderstandBalanceCredit`,
    defaultMessage:
      'For leave type <b>Credit balance upon approval</b> (e.g. Maternity leave):\nCredit is the only source of this leave type',
  },
  noLeaveTypeAvailable: {
    id: `${scope}.noLeaveTypeAvailable`,
    defaultMessage: 'No leave type is available for this employee',
  },
  goToLeavePolicy: {
    id: `${scope}.goToLeavePolicy`,
    defaultMessage: 'Go to Leave Policy',
  },
  configureAtLeavePolicy: {
    id: `${scope}.configureAtLeavePolicy`,
    defaultMessage: 'Go to leave policy to configure leave types',
  },
  askAdminSetJoinDate: {
    id: `${scope}.askAdminSetJoinDate`,
    defaultMessage: 'Reach relevant admin to set join date first',
  },
  askAdminConfigureAtLeavePolicy: {
    id: `${scope}.askAdminConfigureAtLeavePolicy`,
    defaultMessage: 'Reach relevant admin to configure leave type in policy',
  },
  youAreAdjustingBalanceOf: {
    id: `${scope}.youAreAdjustingBalanceOf`,
    defaultMessage: 'You are adjusting <b>{type}</b> balance of <b>{name}</b>',
  },
  adjustBalance: {
    id: `${scope}.adjustBalance`,
    defaultMessage: 'Adjust Balance',
  },
  errorInsufficientBalance: {
    id: `${scope}.errorInsufficientBalance`,
    defaultMessage:
      'Insufficient balance to be deducted. {num} days available now.',
  },
  nameBalanceDetail: {
    id: `${scope}.nameBalanceDetail`,
    defaultMessage: '{name} Balance Details',
  },
  currentEffectiveRule: {
    id: `${scope}.currentEffectiveRule`,
    defaultMessage: 'Current effective rule: {name}',
  },
  amount: {
    id: `${scope}.amount`,
    defaultMessage: 'Amount',
  },
  amountSource: {
    id: `${scope}.amountSource`,
    defaultMessage: 'Amount (Source)',
  },
  backdateTaken: {
    id: `${scope}.backdateTaken`,
    defaultMessage: 'Backdate Taken',
  },
  lastResetDate: {
    id: `${scope}.lastResetDate`,
    defaultMessage: 'Last Reset Date:',
  },
  nextResetDate: {
    id: `${scope}.nextResetDate`,
    defaultMessage: 'Next Reset Date:',
  },
  adjustmentTableEmptyPlaceholder: {
    id: `${scope}.adjustmentTableEmptyPlaceholder`,
    defaultMessage: 'No Adjustment',
  },
  activity: {
    id: `${scope}.activity`,
    defaultMessage: 'Activity',
  },
  activityDetails: {
    id: `${scope}.activityDetails`,
    defaultMessage: 'Activity Details',
  },
  time: {
    id: `${scope}.time`,
    defaultMessage: 'Time',
  },
  remark: {
    id: `${scope}.remark`,
    defaultMessage: 'Remark',
  },
  previous: {
    id: `${scope}.previous`,
    defaultMessage: 'Previous',
  },
  current: {
    id: `${scope}.current`,
    defaultMessage: 'Current',
  },
  balanceOfActivityDetails: {
    id: `${scope}.balanceOfActivityDetails`,
    defaultMessage: 'Balance of {name} <b>{change}</b>',
  },
  processedDate: {
    id: `${scope}.processedDate`,
    defaultMessage: 'Processed Date',
  },
  errorAdjustmentAmout: {
    id: `${scope}.errorAdjustmentAmout`,
    defaultMessage: 'Amount cannot less than {count} days taken',
  },
  deleteAdjustmentContent: {
    id: `${scope}.deleteAdjustmentContent`,
    defaultMessage:
      'Once deleted, will remove this credit for employee.\nDelete <b>{count} days</b> adjustment for {from} - {till}?',
  },
  never: {
    id: `${scope}.never`,
    defaultMessage: 'Never',
  },
  am: {
    id: `${scope}.am`,
    defaultMessage: 'AM',
  },
  pm: {
    id: `${scope}.pm`,
    defaultMessage: 'PM',
  },
  entitledChangeLogReason: {
    id: `${scope}.entitledChangeLogReason`,
    defaultMessage: 'New period started, leave balance is newly entitled.',
  },
  expiredCarryoverChangeLogReason: {
    id: `${scope}.expiredCarryoverChangeLogReason`,
    defaultMessage: '{days} days of carryover was expired.',
  },
  expiredEntitlementChangeLogReason: {
    id: `${scope}.expiredEntitlementChangeLogReason`,
    defaultMessage: '{days} days of entitlement was expired.',
  },
  carryoverChangeLogReason: {
    id: `${scope}.carryoverChangeLogReason`,
    defaultMessage:
      '{days} days of entitlement expired and carried over to the new period.',
  },
  leaveRuleUpdatedChangeLogReason: {
    id: `${scope}.leaveRuleUpdatedChangeLogReason`,
    defaultMessage: 'Leave rule {leaveRuleName} was updated.',
  },
  leavePolicyAssignedChangeLogReason: {
    id: `${scope}.leavePolicyAssignedChangeLogReason`,
    defaultMessage: 'Leave policy {leavePolicyName} was assigned.',
  },
  leavePolicyUnassignedChangeLogReason: {
    id: `${scope}.leavePolicyUnassignedChangeLogReason`,
    defaultMessage: 'Leave policy {leavePolicyName} was unassigned.',
  },
  leaveTypeAddedToPolicyChangeLogReason: {
    id: `${scope}.leaveTypeAddedToPolicyChangeLogReason`,
    defaultMessage:
      'Leave type {leaveTypeName} was added from assigned policy {leavePolicyName}.',
  },
  leaveTypeRemovedFromPolicyChangeLogReason: {
    id: `${scope}.leaveTypeRemovedFromPolicyChangeLogReason`,
    defaultMessage:
      'Leave type {leaveTypeName} was removed from assigned policy {leavePolicyName}.',
  },
  importLeaveBalanceChangeLogReason: {
    id: `${scope}.importLeaveBalanceChangeLogReason`,
    defaultMessage: 'Imported leave balance.',
  },
  importLeaveAdjustmentChangeLogReason: {
    id: `${scope}.importLeaveAdjustmentChangeLogReason`,
    defaultMessage: 'Imported leave adjustment.',
  },
  offboardNowChangeLogReason: {
    id: `${scope}.offboardNowChangeLogReason`,
    defaultMessage: 'The employee is offboarded on {terminationDate}.',
  },
  offboardLaterChangeLogReason: {
    id: `${scope}.offboardLaterChangeLogReason`,
    defaultMessage: 'The employee will be offboarded on {terminationDate}.',
  },
  employeeProfileUpdatedChangeLogReason: {
    id: `${scope}.employeeProfileUpdatedChangeLogReason`,
    defaultMessage: "Employee's profile information was updated.",
  },
  appliedLeaveApplicationChangeLogReason: {
    id: `${scope}.appliedLeaveApplicationChangeLogReason`,
    defaultMessage: 'Employee submitted leave application.',
  },
  creditApplicationApprovedChangeLogReason: {
    id: `${scope}.creditApplicationApprovedChangeLogReason`,
    defaultMessage: "Employee's credit application was approved.",
  },
  withdrawLeaveApplicationChangeLogReason: {
    id: `${scope}.withdrawLeaveApplicationChangeLogReason`,
    defaultMessage: 'Employee withdrew leave application.',
  },
  leavePreferenceUpdatedChangeLogReason: {
    id: `${scope}.leavePreferenceUpdatedChangeLogReason`,
    defaultMessage: 'Leave preference settings was updated.',
  },
  enter: {
    id: `${scope}.enter`,
    defaultMessage: 'Enter',
  },
});
