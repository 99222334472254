import { createContext, useContext } from 'react';

export interface DateTimeFormatContextValue {
  dateFormat: string;
  dateFormatWithourYear: string;
  timeFormat: string;
  dateTimeLongFormat: string;
  // `ret` means "retarded" since this use US retarded
  // month-day-year order.
  // also, yes, this format is not consistent with other date format
  // in each lang. not even for chinese.
  // if you still wonder why, read by yourself
  // https://confluence.shopee.io/x/jkyQig
  dateTimeLongFormatRet: string;
  dateTimeShortFormat: string;
  daySingleFormat: string;
  dayShortFormat: string;
  monthYearFormat: string;
}

const DateTimeFormatContext = createContext<DateTimeFormatContextValue | null>(
  null
);

export const useDateTimeFormatContext = () => useContext(DateTimeFormatContext);

export default DateTimeFormatContext;
