import styled from 'styled-components';

import { STYLE } from './constants';

const HeaderWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  width: 100%;
  min-height: 50px;
  padding-bottom: 14px;

  ${(props) => {
    if (props.withDivider) {
      return `
        margin-bottom: ${STYLE.spacing};
        border-bottom: ${STYLE.styleBorder};
        padding-bottom: 13px;
      `;
    }
    return null;
  }}
`;

export default HeaderWrapper;
