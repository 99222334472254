import Select, {
  SelectSearch,
  SelectVirtualizedList,
  SelectOption,
  SelectHighlighter,
} from '@seaweb/coral/components/Select';
import PropTypes from 'prop-types';
import React, { useMemo, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import { Utils } from './Utils';

function SelectTimezone({ isView, onChange, value }) {
  const [query, setQuery] = useState('');
  const timezones = useMemo(() => Utils.getAllTimeZones(), []);
  const filterTimezone = useCallback(
    (keyword) =>
      timezones.filter(({ name }) => {
        const nameLower = name.toLocaleLowerCase();
        const keywordLower = keyword ? keyword.toLocaleLowerCase() : '';
        return nameLower
          .replace(/[_\s]/g, '')
          .includes(keywordLower.replace(/[_\s]/g, ''));
      }),
    [timezones]
  );

  const selectedTimezone = filterTimezone(value);

  return !isView ? (
    <Select
      value={value || null}
      onChange={onChange}
      searchValue={query}
      onSearch={setQuery}
    >
      <SelectSearch
        id="timezone-select-search"
        fullWidth
        placeholder={<FormattedMessage {...messages.selectTimezone} />}
      >
        {selectedTimezone.length > 0
          ? `(GMT${
              selectedTimezone[0].utcOffsetStr
            }) ${selectedTimezone[0].value
              .replace(/_/g, ' ')
              .replace(/\//g, ' - ')}`
          : null}
      </SelectSearch>
      <SelectVirtualizedList popperUpdateKey={query}>
        {filterTimezone(query).map((tup) => (
          <SelectOption key={tup.value} value={tup.value}>
            <SelectHighlighter
              text={`(GMT${tup.utcOffsetStr}) ${tup.name
                .replace(/_/g, ' ')
                .replace(/\//g, ' - ')}`}
            />
          </SelectOption>
        ))}
      </SelectVirtualizedList>
    </Select>
  ) : (
    (selectedTimezone.length > 0
      ? `(GMT${selectedTimezone[0].utcOffsetStr}) ${selectedTimezone[0].value
          .replace(/_/g, ' ')
          .replace(/\//g, ' - ')}`
      : null) || '-'
  );
}

SelectTimezone.propTypes = {
  isView: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default React.memo(SelectTimezone);
