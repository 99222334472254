import axios from 'axios';
import React, { CSSProperties, FC, memo, useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import styled from 'styled-components';

// import './style.less';
import { IS_LIVE } from 'consts';

type BannerConfig = {
  show: {
    oa: boolean;
  };
  content: string;
};

type BannerState = {
  content?: string;
  show?: boolean;
};

const purifyCfg: DOMPurify.Config = {
  ALLOWED_TAGS: ['p', 'strong'],
  ALLOWED_ATTR: ['class'],
};

type AnnouncementBannerProps = {
  className?: string;
  style?: CSSProperties;
};

const configURL = {
  nonLive:
    'https://deo.shopeemobile.com/shopee/shopee-seatalkcdn-test-sg/seatalk-banner-config.json',
  live: 'https://deo.shopeemobile.com/shopee/shopee-seatalkcdn-live-sg/seatalk-banner-config.json',
};

const DivAnnouncementBanner = styled.div`
  background-color: lightyellow;
  padding: 1em;

  p {
    color: black;
    font-size: 14px;
    margin-bottom: 0.5em;
  }

  p.title {
    color: red;
    font-weight: 700;
    font-size: 18px;
  }

  strong {
    font-weight: 700;
  }
`;

const AnnouncementBanner: FC<AnnouncementBannerProps> = (props) => {
  const { className = '', style: styleProps = {} } = props;

  const [banner, setBanner] = useState<BannerState>({});

  useEffect(() => {
    axios
      .get<BannerConfig>(IS_LIVE ? configURL.live : configURL.nonLive)
      .then(({ data }) => {
        if (data) {
          setBanner({
            show: data.show.oa,
            content: DOMPurify.sanitize(
              (data.content || '').trim(),
              purifyCfg
            ) as string,
          });
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log('[notification] Error get notification');
      });
  }, []);

  if (!banner.show || !banner.content) {
    return null;
  }

  return (
    <DivAnnouncementBanner
      id="announcement-banner"
      className={className}
      dangerouslySetInnerHTML={{
        __html: banner.content,
      }}
      style={styleProps}
    />
  );
};

export default memo(AnnouncementBanner);
