import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Highlighter from 'react-highlight-words';

import { STYLE } from './constants';

/** Highlights portion of text based on keyword provided
 *
 * @param {String} keyword Keyword to be highlighted
 * @param {String} text Full text that is being displayed
 */
class HighlightSearch extends PureComponent {
  render() {
    const { keyword, text, ...otherProps } = this.props;
    return (
      <Highlighter
        highlightStyle={{
          padding: 0,
          color: STYLE.colorClick,
          backgroundColor: 'transparent',
        }}
        autoEscape
        searchWords={[keyword]}
        textToHighlight={text}
        {...otherProps}
      />
    );
  }
}

HighlightSearch.propTypes = {
  keyword: PropTypes.string,
  text: PropTypes.string,
};

export default HighlightSearch;
