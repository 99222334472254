import useForkRef from '@seaweb/coral/hooks/useForkRef';
import useWrapEvent from '@seaweb/coral/hooks/useWrapEvent';
import React, { useRef, useState } from 'react';
import { useDrag } from 'react-use-gesture';
import styled, { css } from 'styled-components';

const DraggableContainer = styled.div`
  cursor: grab;

  ${({ dragging }) =>
    dragging &&
    css`
      cursor: grabbing;
      user-select: none;
    `}
`;

function DraggableScroll(props) {
  const containerRef = useRef(null);

  const bind = useDrag(({ delta: [x, y] }) => {
    containerRef.current.scrollTop -= y;
    containerRef.current.scrollLeft -= x;
  });

  const dragProps = bind();
  const ref = useForkRef(dragProps.ref, containerRef);

  const [dragging, setDragging] = useState(false);

  const onMouseDown = useWrapEvent((e) => {
    if (e.target !== containerRef.current) {
      // e.preventDefault();
    }
    setDragging(true);
  }, dragProps.onMouseDown);

  const onMouseUp = useWrapEvent(() => setDragging(false), dragProps.onMouseUp);

  return (
    <DraggableContainer
      {...dragProps}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      dragging={dragging}
      ref={ref}
      {...props}
    />
  );
}

export default DraggableScroll;
