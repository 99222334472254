import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export default function LocalizedLocation({ locationName }) {
  return messages[`location${locationName}`] ? (
    <FormattedMessage {...messages[`location${locationName}`]} />
  ) : (
    locationName
  );
}

LocalizedLocation.propTypes = {
  locationName: PropTypes.string.isRequired,
};

export function ListLocalizedLocation({ locations }) {
  return (
    locations?.map((loc, idx) => (
      <span key={loc.name}>
        <LocalizedLocation locationName={loc.name} />
        {idx < locations.length - 1 && ', '}
      </span>
    )) || '-'
  );
}

ListLocalizedLocation.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
};
