import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import ImgWrapper from './ImgWrapper';

const StyledImgWrapper = styled(ImgWrapper)`
  min-width: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;
  border: ${(props) => props.type !== 'round' && 'none'};
  border-radius: ${(props) => {
    if (props.type === 'round') return '50%';
    if (props.type === 'timeline') return '2px';
    return '4px';
  }};
`;

function Avatar(props) {
  const { src, size, type, ...otherProps } = props;

  return (
    <StyledImgWrapper
      src={src}
      size={size}
      type={type}
      placeholder="avatar"
      {...otherProps}
    />
  );
}

Avatar.propTypes = {
  type: PropTypes.string,
  src: PropTypes.string,
  size: PropTypes.number,
};

Avatar.defaultProps = {
  size: 32,
};

export default Avatar;
