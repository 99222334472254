import Badge from '@seaweb/coral/components/Badge';
import Button from '@seaweb/coral/components/Button';
import {
  MonthPicker,
  DatePickerInput,
} from '@seaweb/coral/components/DatePicker';
import Drawer, {
  DrawerTitle,
  DrawerContent,
  DrawerActions,
  DrawerClose,
} from '@seaweb/coral/components/Drawer';
import InputGroup from '@seaweb/coral/components/InputGroup';
import RangePicker, {
  RangePickerInput,
} from '@seaweb/coral/components/RangePicker';
import Tooltip from '@seaweb/coral/components/Tooltip';
import FilterIcon from '@seaweb/coral/icons/Filter';
import InfoOIcon from '@seaweb/coral/icons/InfoO';
import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { THEME } from 'colorTheme';
import { useDateTimeFormatContext } from 'containers/DateTimeFormat/DateTimeFormatContext';

import { DialogField, FilterField } from './Field';
import {
  TreeSelectDepartment,
  MultiSelectLeavePolicy,
  MultiSelectLeaveType,
  MultiSelectLeaveStatus,
  MultiSelectWorkGroup,
  MultiSelectAttendanceApplicationStatus,
  MultiSelectClaimApplicationStatus,
  MultiSelectEmployeeStatus,
} from './FilterPanelSelect';
import messages from './messages';
import SearchBox from './SearchBox';

export const FilterWrapper = styled.div`
  background-color: ${THEME.colorTableBg};
  padding: 16px;
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
`;

const FilterRow = styled.div`
  display: flex;
  align-items: flex-end;
  > div {
    margin: 0 16px 0 0;
    [data-coral-field-input] {
      width: 304px;
    }
  }
`;

const ActionButtons = styled(InputGroup).attrs({ gap: 16 })`
  margin-left: 24px;
  align-items: flex-end;
`;

function AdvanceFilterDrawerContent({
  skipRequest,
  initialValue,
  calendarProps,
  haveElement,
  reset,
  onConfirm,
  departmentPurpose,
}) {
  const { dateFormat, monthYearFormat } = useDateTimeFormatContext();
  const [value, setValue] = useState(initialValue);

  const handleChange = (key, val) => {
    setValue((prev) => ({ ...prev, [key]: val }));
  };

  return (
    <>
      <DrawerContent>
        {haveElement.searchKey && (
          <DialogField label={<FormattedMessage {...messages.search} />}>
            <SearchBox
              defaultValue={value.searchKey}
              onSearch={(val) => handleChange('searchKey', val)}
              fullWidth
            />
          </DialogField>
        )}

        {haveElement.calendar && (
          <DialogField
            label={
              <>
                {calendarProps.title || (
                  <FormattedMessage {...messages.filterPanelDetailsOn} />
                )}
                {calendarProps.tooltip && (
                  <Tooltip placement="right" title={calendarProps.tooltip}>
                    <InfoOIcon
                      size={16}
                      style={{ marginLeft: 8, verticalAlign: 'sub' }}
                    />
                  </Tooltip>
                )}
              </>
            }
          >
            {calendarProps.type === 'month' ? (
              <MonthPicker
                value={dayjs(value.calendar[0], 'YYYY-MM-DD')}
                allowClear={false}
                dateFormat={monthYearFormat}
                onChange={(date) =>
                  handleChange('calendar', [date.format('YYYY-MM-DD')])
                }
                isMonthDisabled={calendarProps.disabledDate}
                inputElement={<DatePickerInput fullWidth />}
              />
            ) : (
              <RangePicker
                value={value.calendar.map((x) => dayjs(x, 'YYYY-MM-DD'))}
                allowClear={false}
                onChange={(dates) => {
                  const dateRange = dates[1] ? dates : [dates[0], dates[0]];
                  handleChange(
                    'calendar',
                    dateRange.map((date) => date.format('YYYY-MM-DD'))
                  );
                }}
                dateFormat={dateFormat}
                isDateDisabled={calendarProps.disabledDate}
                inputElement={<RangePickerInput style={{ width: '100%' }} />}
              />
            )}
          </DialogField>
        )}

        {haveElement.leaveApplicationStatus && (
          <MultiSelectLeaveStatus
            value={value.leaveApplicationStatus}
            onChange={(val) => handleChange('leaveApplicationStatus', val)}
          />
        )}
        {haveElement.department && (
          <TreeSelectDepartment
            value={value.department}
            onChange={(val) => handleChange('department', val)}
            skip={skipRequest}
            purpose={departmentPurpose}
          />
        )}
        {haveElement.workGroup && (
          <MultiSelectWorkGroup
            value={value.workGroup}
            onChange={(val) => handleChange('workGroup', val)}
            skip={skipRequest}
          />
        )}
        {haveElement.leavePolicy && (
          <MultiSelectLeavePolicy
            value={value.leavePolicy}
            onChange={(val) => handleChange('leavePolicy', val)}
            skip={skipRequest}
          />
        )}
        {haveElement.leaveType && (
          <MultiSelectLeaveType
            value={value.leaveType}
            onChange={(val) => handleChange('leaveType', val)}
            skip={skipRequest}
          />
        )}
        {haveElement.attendanceApplicationStatus && (
          <MultiSelectAttendanceApplicationStatus
            value={value.attendanceApplicationStatus}
            onChange={(val) => handleChange('attendanceApplicationStatus', val)}
          />
        )}
        {haveElement.claimApplicationStatus && (
          <MultiSelectClaimApplicationStatus
            value={value.claimApplicationStatus}
            onChange={(val) => handleChange('claimApplicationStatus', val)}
          />
        )}
        {haveElement.employeeStatuses && (
          <MultiSelectEmployeeStatus
            value={value.employeeStatuses}
            onChange={(val) => handleChange('employeeStatuses', val)}
          />
        )}
      </DrawerContent>
      <DrawerActions>
        <Button variant="outlined" onClick={reset}>
          <FormattedMessage {...messages.reset} />
        </Button>
        <Button onClick={() => onConfirm(value)}>
          <FormattedMessage {...messages.confirm} />
        </Button>
      </DrawerActions>
    </>
  );
}

AdvanceFilterDrawerContent.propTypes = {
  skipRequest: PropTypes.bool,
  initialValue: PropTypes.object,
  calendarProps: PropTypes.object,
  haveElement: PropTypes.object,
  reset: PropTypes.func,
  onConfirm: PropTypes.func,
  departmentPurpose: PropTypes.number,
};

function FilterPanel({
  skipRequest,
  value,
  defaultValue = {},
  calendarProps = {},
  onSearch,
  onCalendarChange,
  onDepartmentChange,
  departmentPurpose, // onDepartmentChange and departmentPurpose go together
  onLeavePolicyChange,
  onLeaveTypeChange,
  onLeaveApplicationStatusChange,
  onClaimApplicationStatusChange,
  onWorkGroupChange,
  onAttendanceApplicationStatusChange,
  onEmployeeStatusesChange,
  onExport,
  actionButtons,
  children,
}) {
  const { dateFormat, monthYearFormat } = useDateTimeFormatContext();
  const [isOpen, setIsOpen] = useState(false);

  const filterCount =
    !!onSearch +
    !!onCalendarChange +
    !!onDepartmentChange +
    !!onLeavePolicyChange +
    !!onLeaveTypeChange +
    !!onLeaveApplicationStatusChange +
    !!onClaimApplicationStatusChange +
    !!onWorkGroupChange +
    !!onAttendanceApplicationStatusChange +
    !!onEmployeeStatusesChange;

  const appliedFilterCount = Object.keys(value).filter((key) => {
    if (key === 'searchKey') {
      return false; // search does not count as filter
    }
    if (key in defaultValue) {
      return !isEqual(value[key].sort(), defaultValue[key].sort());
    }
    return value[key].length > 0;
  }).length;

  const setAllFilter = async (val) => {
    if (onSearch) {
      onSearch(val.searchKey);
    }
    if (onCalendarChange) {
      await onCalendarChange(val.calendar);
    }
    if (onDepartmentChange) {
      await onDepartmentChange(val.department);
    }
    if (onLeavePolicyChange) {
      await onLeavePolicyChange(val.leavePolicy);
    }
    if (onLeaveTypeChange) {
      await onLeaveTypeChange(val.leaveType);
    }
    if (onLeaveApplicationStatusChange) {
      await onLeaveApplicationStatusChange(val.leaveApplicationStatus);
    }
    if (onClaimApplicationStatusChange) {
      await onClaimApplicationStatusChange(val.claimApplicationStatus);
    }
    if (onAttendanceApplicationStatusChange) {
      await onAttendanceApplicationStatusChange(
        val.attendanceApplicationStatus
      );
    }
    if (onWorkGroupChange) {
      await onWorkGroupChange(val.workGroup);
    }
    if (onEmployeeStatusesChange) {
      await onEmployeeStatusesChange(val.employeeStatuses);
    }
    setIsOpen(false);
  };

  const reset = async () => {
    if (onSearch) {
      onSearch('');
    }
    if (onCalendarChange) {
      await onCalendarChange(defaultValue?.calendar || []);
    }
    if (onDepartmentChange) {
      await onDepartmentChange([]);
    }
    if (onLeavePolicyChange) {
      await onLeavePolicyChange([]);
    }
    if (onLeaveTypeChange) {
      await onLeaveTypeChange([]);
    }
    if (onLeaveApplicationStatusChange) {
      await onLeaveApplicationStatusChange([]);
    }
    if (onClaimApplicationStatusChange) {
      await onClaimApplicationStatusChange([]);
    }
    if (onAttendanceApplicationStatusChange) {
      await onAttendanceApplicationStatusChange([]);
    }
    if (onWorkGroupChange) {
      await onWorkGroupChange([]);
    }
    if (onEmployeeStatusesChange) {
      await onEmployeeStatusesChange(defaultValue?.employeeStatuses || []);
    }
  };

  return (
    <>
      <FilterWrapper>
        {/* First row contains maximum 3 filters and must include Picker & ActionButtons (if has) */}
        <FilterRow>
          {onSearch && (
            <FilterField label={<FormattedMessage {...messages.search} />}>
              <SearchBox
                value={value.searchKey}
                onSearch={(val) => {
                  onSearch(val);
                }}
                fullWidth
              />
            </FilterField>
          )}

          {onCalendarChange && (
            <FilterField
              label={
                calendarProps.title || (
                  <FormattedMessage {...messages.filterPanelDetailsOn} />
                )
              }
            >
              {calendarProps.type === 'month' ? (
                <MonthPicker
                  value={dayjs(value.calendar[0], 'YYYY-MM-DD')}
                  allowClear={false}
                  inputValue={dayjs(value.calendar[0], 'YYYY-MM-DD').format(
                    monthYearFormat
                  )}
                  onChange={(date) =>
                    onCalendarChange([date.format('YYYY-MM-DD')])
                  }
                  isMonthDisabled={calendarProps.disabledDate}
                  inputElement={<DatePickerInput fullWidth />}
                />
              ) : (
                <RangePicker
                  value={value.calendar.map((x) => dayjs(x, 'YYYY-MM-DD'))}
                  allowClear={false}
                  onChange={(dates) => {
                    const dateRange = dates[1] ? dates : [dates[0], dates[0]];
                    onCalendarChange(
                      dateRange.map((date) => date.format('YYYY-MM-DD'))
                    );
                  }}
                  inputValue={value.calendar.map((x) =>
                    dayjs(x, 'YYYY-MM-DD').format(dateFormat)
                  )}
                  isDateDisabled={calendarProps.disabledDate}
                  inputElement={<RangePickerInput style={{ width: '100%' }} />}
                />
              )}
              {calendarProps.tooltip && (
                <Tooltip placement="right" title={calendarProps.tooltip}>
                  <InfoOIcon
                    style={{ margin: 'auto 0 auto 8px' }}
                    colorType="textSecondary"
                  />
                </Tooltip>
              )}
            </FilterField>
          )}

          {filterCount > 3 ? (
            <>
              <Badge content={appliedFilterCount}>
                <Button
                  variant="outlined"
                  leftIcon={<FilterIcon />}
                  onClick={() => setIsOpen(true)}
                >
                  <FormattedMessage {...messages.filters} />
                </Button>
              </Badge>
              {appliedFilterCount > 0 && (
                <Button variant="text" onClick={reset}>
                  <FormattedMessage {...messages.reset} />
                </Button>
              )}
            </>
          ) : (
            <>
              {onLeaveApplicationStatusChange && (
                <MultiSelectLeaveStatus
                  value={value.leaveApplicationStatus}
                  onChange={onLeaveApplicationStatusChange}
                />
              )}
              {onDepartmentChange && (
                <TreeSelectDepartment
                  value={value.department}
                  onChange={onDepartmentChange}
                  skip={skipRequest}
                  purpose={departmentPurpose}
                />
              )}
              {onWorkGroupChange && (
                <MultiSelectWorkGroup
                  value={value.workGroup}
                  onChange={onWorkGroupChange}
                  skip={skipRequest}
                />
              )}
              {onLeavePolicyChange && (
                <MultiSelectLeavePolicy
                  value={value.leavePolicy}
                  onChange={onLeavePolicyChange}
                  skip={skipRequest}
                />
              )}
              {onLeaveTypeChange && (
                <MultiSelectLeaveType
                  value={value.leaveType}
                  onChange={onLeaveTypeChange}
                  skip={skipRequest}
                />
              )}
              {onAttendanceApplicationStatusChange && (
                <MultiSelectAttendanceApplicationStatus
                  value={value.attendanceApplicationStatus}
                  onChange={onAttendanceApplicationStatusChange}
                />
              )}
              {onClaimApplicationStatusChange && (
                <MultiSelectClaimApplicationStatus
                  value={value.claimApplicationStatus}
                  onChange={onClaimApplicationStatusChange}
                />
              )}
              {onEmployeeStatusesChange && (
                <MultiSelectEmployeeStatus
                  value={value.employeeStatuses}
                  onChange={onEmployeeStatusesChange}
                />
              )}
            </>
          )}

          {children}
        </FilterRow>
        {/* Action buttons (must be on the right) */}
        {(actionButtons || onExport) && (
          <ActionButtons>
            {actionButtons}
            {onExport && (
              <Button
                id="export-report-button"
                variant="outlined"
                onClick={onExport}
              >
                <FormattedMessage {...messages.filterPanelExportReport} />
              </Button>
            )}
          </ActionButtons>
        )}
      </FilterWrapper>

      <Drawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        showOverlay={false}
      >
        <DrawerTitle>
          <FormattedMessage {...messages.searchAndFilters} />
          <DrawerClose />
        </DrawerTitle>
        <AdvanceFilterDrawerContent
          skipRequest={skipRequest}
          initialValue={value}
          calendarProps={calendarProps}
          haveElement={{
            searchKey: !!onSearch,
            calendar: !!onCalendarChange,
            leaveApplicationStatus: !!onLeaveApplicationStatusChange,
            department: !!onDepartmentChange,
            workGroup: !!onWorkGroupChange,
            leavePolicy: !!onLeavePolicyChange,
            leaveType: !!onLeaveTypeChange,
            attendanceApplicationStatus: !!onAttendanceApplicationStatusChange,
            claimApplicationStatus: !!onClaimApplicationStatusChange,
            employeeStatuses: !!onEmployeeStatusesChange,
          }}
          reset={() => {
            reset();
            setIsOpen(false);
          }}
          onConfirm={setAllFilter}
          departmentPurpose={departmentPurpose}
        />
      </Drawer>
    </>
  );
}

FilterPanel.propTypes = {
  skipRequest: PropTypes.bool,
  value: PropTypes.object,
  defaultValue: PropTypes.object,
  calendarProps: PropTypes.object,
  onSearch: PropTypes.func,
  onCalendarChange: PropTypes.func,
  onDepartmentChange: PropTypes.func,
  departmentPurpose: PropTypes.number,
  onLeavePolicyChange: PropTypes.func,
  onLeaveTypeChange: PropTypes.func,
  onLeaveApplicationStatusChange: PropTypes.func,
  onClaimApplicationStatusChange: PropTypes.func,
  onAttendanceApplicationStatusChange: PropTypes.func,
  onWorkGroupChange: PropTypes.func,
  onEmployeeStatusesChange: PropTypes.func,
  onExport: PropTypes.func,
  actionButtons: PropTypes.node,
  children: PropTypes.node,
};

export default FilterPanel;
