export const APPLY_UNITS = {
  HALFDAY: 1,
  FULLDAY: 2,
};

export const LEAVE_APPLICATION_STATUS = {
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
  TERMINATED: 4,
  WITHDRAWN: 5,
};

export const LEAVE_APPROVAL_STATUS = {
  INITIATED: 0,
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
  TERMINATED: 4,
  SKIPPED: 5,
  SUBMITTED: 6,
  WITHDRAWN: 7,
};

export const APPROVAL_TYPE = {
  APPROVAL: 1,
  CARBON_COPY: 2,
  SUBMIT: 3,
  WITHDRAW: 4,
};

export const BALANCE_CHANGE_ACTION = {
  ENTITLE: 1,
  EXPIRED: 2,
  CARRYOVER: 3,
  POLICY_UPDATE: 4,
  CREDIT: 5,
  DEDUCT: 6,
  RULE_UPDATE: 7,
  IMPORT: 8,
  OFFBOARD: 9,
  EMPLOYEE_UPDATE: 10,
  APPLIED: 11,
  WITHDRAW: 12,
  PREFERENCE_UPDATE: 13,
};

export const APPROVER_TYPE = {
  DEPARTMENT_HEAD: 1,
  REPORTING_MANAGER: 2,
  WHITELIST: 3,
};

export const APPROVAL_METHOD = {
  COUNTERSIGN: 1,
  PREEMPT: 2,
};

export const ENTITLEMENT_TYPE = {
  PERIODICAL: 1,
  UPON_APPROVAL: 2,
  UNLIMITED: 3,
};
