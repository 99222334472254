import { type HTMLAttributes } from 'react';
import {
  SortableHandle,
  SortableElement,
  SortableContainer,
  type SortableElementProps,
  type SortableContainerProps,
} from 'react-sortable-hoc';

import SortIcon from 'components/Icons/Sort';

export const DragHandle = SortableHandle(() => (
  <SortIcon style={{ cursor: 'move' }} />
));

export const SortableItem = SortableElement(
  (props: HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />
);

export const SortableBody = SortableContainer(
  (props: HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />
);

export const DraggableContainer = (props: SortableContainerProps) => (
  // @ts-ignore
  <SortableBody
    lockAxis="y"
    useDragHandle
    helperClass="row-dragging"
    {...props}
  />
);

export const DraggableBodyRow = (props: SortableElementProps) => (
  // @ts-ignore
  <SortableItem {...props} />
);
