import CoralTag from '@seaweb/coral/components/Tag';
import Tooltip, { Placements } from '@seaweb/coral/components/Tooltip';
import InfoOIcon from '@seaweb/coral/icons/InfoO';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { THEME } from 'colorTheme';

const StyledTag = styled(CoralTag)`
  margin-left: 8px;
`;

export const Tag = forwardRef((props, ref) => {
  const { children, type, ...otherProps } = props;

  let color;
  switch (type) {
    case 'error':
      color = THEME.colorError;
      break;
    case 'abnormal':
      color = THEME.colorAbnormal;
      break;
    case 'success':
      color = THEME.colorSuccess;
      break;
    case 'inactive':
      color = THEME.colorTextSecondary;
      break;
    case 'warning':
      color = THEME.colorWarning;
      break;
    default:
      color = THEME.colorClick;
  }
  return (
    <StyledTag ref={ref} color={color} type={type} {...otherProps}>
      {children}
    </StyledTag>
  );
});

Tag.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf([
    'error',
    'abnormal',
    'success',
    'inactive',
    'warning',
  ]),
};

export const TagWithTooltip = forwardRef((props, ref) => {
  const { children, tooltip, placement = 'right', ...otherProps } = props;

  return (
    <Tooltip title={tooltip} placement={placement}>
      <StyledTag ref={ref} color={THEME.colorClick} {...otherProps}>
        {children}
        {tooltip && <InfoOIcon size={16} style={{ marginLeft: 2 }} />}
      </StyledTag>
    </Tooltip>
  );
});

TagWithTooltip.propTypes = {
  children: PropTypes.node,
  tooltip: PropTypes.object,
  placement: Placements,
};
