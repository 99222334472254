import { defineMessages } from 'react-intl';

export const scope = 'components.Tutorial';

export default defineMessages({
  skip: {
    id: `${scope}.skip`,
    defaultMessage: 'Skip',
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: 'Next',
  },
  done: {
    id: `${scope}.done`,
    defaultMessage: 'Done!',
  },
});
