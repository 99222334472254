import { defineMessages } from 'react-intl';

export const scope = 'containers.Claim.ClaimReports';

export default defineMessages({
  noClaimCategoryPlaceholderText: {
    id: `${scope}.noClaimCategoryPlaceholderText`,
    defaultMessage: 'You have not added any claim category yet',
  },
  noClaimCategoryPlaceholderDesc: {
    id: `${scope}.noClaimCategoryPlaceholderDesc`,
    defaultMessage: 'Go to "Configuration" to add claim categories',
  },
  noClaimPolicyPlaceholderText: {
    id: `${scope}.noClaimPolicyPlaceholderText`,
    defaultMessage: 'You have not added any claim policy yet',
  },
  noClaimPolicyPlaceholderDesc: {
    id: `${scope}.noClaimPolicyPlaceholderDesc`,
    defaultMessage: 'Go to "Configuration" to add claim policy',
  },
  noClaimReportPlaceholder: {
    id: `${scope}.noClaimReportPlaceholder`,
    defaultMessage: 'There is no claim report yet',
  },
  addClaimCategory: {
    id: `${scope}.addClaimCategory`,
    defaultMessage: 'Add Claim Category',
  },
  addClaimPolicy: {
    id: `${scope}.addClaimPolicy`,
    defaultMessage: 'Add Claim Policy',
  },
  claimReports: {
    id: `${scope}.claimReports`,
    defaultMessage: 'Claim Reports',
  },
  claimReport: {
    id: `${scope}.claimReport`,
    defaultMessage: 'Claim Report',
  },
  batchActions: {
    id: `${scope}.batchActions`,
    defaultMessage: 'Batch Actions',
  },
  export: {
    id: `${scope}.export`,
    defaultMessage: 'Export',
  },
  submissionDate: {
    id: `${scope}.submissionDate`,
    defaultMessage: 'Submission Date',
  },
  withdrawn: {
    id: `${scope}.withdrawn`,
    defaultMessage: 'Withdrawn',
  },
  resubmitted: {
    id: `${scope}.resubmitted`,
    defaultMessage: 'Resubmitted',
  },
  pendingManager: {
    id: `${scope}.pendingManager`,
    defaultMessage: 'Pending Manager',
  },
  pendingFinance: {
    id: `${scope}.pendingFinance`,
    defaultMessage: 'Pending Finance',
  },
  pendingPayment: {
    id: `${scope}.pendingPayment`,
    defaultMessage: 'Pending Payment',
  },
  paidInFull: {
    id: `${scope}.paidInFull`,
    defaultMessage: 'Paid in Full',
  },
  rejectedByFinance: {
    id: `${scope}.rejectedByFinance`,
    defaultMessage: 'Rejected by Finance',
  },
  rejectedByManager: {
    id: `${scope}.rejectedByManager`,
    defaultMessage: 'Rejected by Manager',
  },
  terminated: {
    id: `${scope}.terminated`,
    defaultMessage: 'Terminated',
  },
  reportNo: {
    id: `${scope}.reportNo`,
    defaultMessage: 'Report No.',
  },
  employee: {
    id: `${scope}.employee`,
    defaultMessage: 'Employee',
  },
  back: {
    id: `${scope}.back`,
    defaultMessage: 'Back',
  },
  claimReportDetails: {
    id: `${scope}.claimReportDetails`,
    defaultMessage: 'Claim Report Details',
  },
  totalAmount: {
    id: `${scope}.totalAmount`,
    defaultMessage: 'Total Amount',
  },
  paymentDueDate: {
    id: `${scope}.paymentDueDate`,
    defaultMessage: 'Payment Due Date',
  },
  notSet: {
    id: `${scope}.notSet`,
    defaultMessage: 'Not set',
  },
  notAvailable: {
    id: `${scope}.notAvailable`,
    defaultMessage: 'N/A',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
  editPaymentDueDate: {
    id: `${scope}.editPaymentDueDate`,
    defaultMessage: 'Edit Payment Due Date',
  },
  editPaymentDueDateSuccess: {
    id: `${scope}.editPaymentDueDateSuccess`,
    defaultMessage: 'Payment due date edited successfully',
  },
  markAsPaid: {
    id: `${scope}.markAsPaid`,
    defaultMessage: 'Mark as Paid',
  },
  markAsPaidSuccess: {
    id: `${scope}.markAsPaidSuccess`,
    defaultMessage: 'Claim entry marked as paid',
  },
  changeHistory: {
    id: `${scope}.changeHistory`,
    defaultMessage: 'Change History',
  },
  approveSelected: {
    id: `${scope}.approveSelected`,
    defaultMessage: 'Approve Selected',
  },
  approveSuccess: {
    id: `${scope}.approveSuccess`,
    defaultMessage: 'Claim entry approved',
  },
  rejectSelected: {
    id: `${scope}.rejectSelected`,
    defaultMessage: 'Reject Selected',
  },
  rejectSuccess: {
    id: `${scope}.rejectSuccess`,
    defaultMessage: 'Claim entry rejected',
  },
  markSelectedAsPaid: {
    id: `${scope}.markSelectedAsPaid`,
    defaultMessage: 'Mark Selected as Paid',
  },
  approveClaimReport: {
    id: `${scope}.approveClaimReport`,
    defaultMessage: 'Approve Claim Report',
  },
  actionWarning: {
    id: `${scope}.actionWarning`,
    defaultMessage: 'This action cannot be reverted',
  },
  selectDate: {
    id: `${scope}.selectDate`,
    defaultMessage: 'Select Date',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  approve: {
    id: `${scope}.approve`,
    defaultMessage: 'Approve',
  },
  rejectReason: {
    id: `${scope}.rejectReason`,
    defaultMessage: 'Reject Reason',
  },
  rejectClaimReport: {
    id: `${scope}.rejectClaimReport`,
    defaultMessage: 'Reject Claim Report',
  },
  rejectReasonPlaceholder: {
    id: `${scope}.rejectReasonPlaceholder`,
    defaultMessage: 'Please state your reason (Optional)',
  },
  reject: {
    id: `${scope}.reject`,
    defaultMessage: 'Reject',
  },
  markSingleAsPaidModalTitle: {
    id: `${scope}.markSingleAsPaidModalTitle`,
    defaultMessage: 'Mark as Paid in Full',
  },
  markMultipleAsPaidModalTitle: {
    id: `${scope}.markMultipleAsPaidModalTitle`,
    defaultMessage: 'Mark Claim Reports as Paid',
  },
  print: {
    id: `${scope}.print`,
    defaultMessage: 'Print',
  },

  amount: {
    id: `${scope}.amount`,
    defaultMessage: 'Amount',
  },
  currency: {
    id: `${scope}.currency`,
    defaultMessage: 'Currency',
  },
  remark: {
    id: `${scope}.remark`,
    defaultMessage: 'Remark',
  },
  remarkPlaceholder: {
    id: `${scope}.remarkPlaceholder`,
    defaultMessage: 'Please state your reason(Optional)',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save',
  },
  batchActionTooltip: {
    id: `${scope}.batchActionTooltip`,
    defaultMessage: 'Please select the reports that have common actions',
  },
  multipleApproveClaimDialog: {
    id: `${scope}.multipleApproveClaimDialog`,
    defaultMessage:
      '{numberOfReports} claim reports are selected. Are you sure to approve the selected claim reports? The report status will become "Pending Payment" and cannot be reverted.',
  },
  multipleRejectClaimDialog: {
    id: `${scope}.multipleRejectClaimDialog`,
    defaultMessage:
      '{numberOfReports} claim reports are selected. Are you sure to reject the selected claim reports? This action cannot be reverted.',
  },
  multipleMarkAsPaidClaimDialog: {
    id: `${scope}.multipleMarkAsPaidClaimDialog`,
    defaultMessage:
      '{numberOfReports} claim reports are selected. Are you sure to mark the selected claim reports as Paid In Full with the following Payment Due Date? This action cannot be reverted.',
  },
  multipleEditPaymentDueDateDialog: {
    id: `${scope}.multipleEditPaymentDueDateDialog`,
    defaultMessage: '{numberOfReports} claim reports are selected.',
  },
  multipleApproveClaimSuccess: {
    id: `${scope}.multipleApproveSuccess`,
    defaultMessage: '{num} claim entries approved',
  },
  multipleRejectClaimSuccess: {
    id: `${scope}.multipleRejectSuccess`,
    defaultMessage: '{num} claim entries rejected',
  },
  multipleMarkAsPaidClaimSuccess: {
    id: `${scope}.multipleMarkAsPaidSuccess`,
    defaultMessage: '{num} claim entries marked as paid',
  },
  multipleEditPaymentDueDateSuccess: {
    id: `${scope}.multipleEditDueDateSuccess`,
    defaultMessage: "{num} claim entries' due date edited successfully",
  },
  reportExceedLimit: {
    id: `${scope}.reportExceedLimit`,
    defaultMessage:
      'Report number hits the system upper limit, employees cannot apply claim, please use a new prefix for report number in Basic Settings or contact SeaTalk Team.',
  },
  exportClaimEntries: {
    id: `${scope}.exportClaimEntries`,
    defaultMessage: 'Export Claim Entries',
  },
  exportClaimReports: {
    id: `${scope}.exportClaimReports`,
    defaultMessage: 'Export Claim Reports',
  },
  newEntry: {
    id: `${scope}.newEntry`,
    defaultMessage: 'New Entry',
  },
  removeSelected: {
    id: `${scope}.removeSelected`,
    defaultMessage: 'Remove Selected',
  },
  editEntries: {
    id: `${scope}.editEntries`,
    defaultMessage: 'Edit Entries',
  },
  editEntrySuccess: {
    id: `${scope}.editEntrySuccess`,
    defaultMessage: 'Claim entries successfully edited',
  },
  editNameClaim: {
    id: `${scope}.editNameClaim`,
    defaultMessage: "Edit {name}'s Claim",
  },
  reportEntry: {
    id: `${scope}.reportEntry`,
    defaultMessage: 'Report Entry ({index}/{total})',
  },
  reportEntries: {
    id: `${scope}.reportEntries`,
    defaultMessage: 'Report Entries',
  },
  numberShortForm: {
    id: `${scope}.numberShortForm`,
    defaultMessage: 'No',
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'Category',
  },
  exchangeRate: {
    id: `${scope}.exchangeRate`,
    defaultMessage: 'Exchange Rate',
  },
  baseCurrencyAmount: {
    id: `${scope}.baseCurrencyAmount`,
    defaultMessage: 'Amount ({code})',
  },
  receiptDate: {
    id: `${scope}.receiptDate`,
    defaultMessage: 'Receipt Date',
  },
  otherDetails: {
    id: `${scope}.otherDetails`,
    defaultMessage: 'Other Details',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Descriptions',
  },
  required: {
    id: `${scope}.required`,
    defaultMessage: 'Required',
  },
  invalidRange: {
    id: `${scope}.invalidRange`,
    defaultMessage: 'Invalid Range',
  },
  attachments: {
    id: `${scope}.attachments`,
    defaultMessage: 'Attachments',
  },
  attachmentCount: {
    id: `${scope}.attachmentCount`,
    defaultMessage: '{count} attachment(s)',
  },
  appendAttachment: {
    id: `${scope}.appendAttachment`,
    defaultMessage: 'Append {count} attachment(s)',
  },
  attachmentNumber: {
    id: `${scope}.attachmentNumber`,
    defaultMessage: 'Attachment ({index}/{total})',
  },
  check: {
    id: `${scope}.check`,
    defaultMessage: 'Check',
  },
  previous: {
    id: `${scope}.previous`,
    defaultMessage: 'Previous',
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: 'Next',
  },
  done: {
    id: `${scope}.done`,
    defaultMessage: 'Done',
  },
  warningCategoryExceedLimit: {
    id: `${scope}.warningCategoryExceedLimit`,
    defaultMessage: '1 claim category exceed limit',
  },
  warningCategoriesExceedLimit: {
    id: `${scope}.warningCategoriesExceedLimit`,
    defaultMessage: '{count} claim categories exceed limit',
  },
  categoryExceedLimitInfo: {
    id: `${scope}.categoryExceedLimitInfo`,
    defaultMessage: 'Total amout of this category exceeds limit.',
  },
  yearlyLimit: {
    id: `${scope}.yearlyLimit`,
    defaultMessage:
      'Yearly limit: {amount}, remaining balance({date}): {balance}.',
  },
  monthlyLimit: {
    id: `${scope}.monthlyLimit`,
    defaultMessage:
      'Monthly limit: {amount}, remaining balance({date}): {balance}.',
  },
  dailyLimit: {
    id: `${scope}.dailyLimit`,
    defaultMessage:
      'Daily limit: {amount}, remaining balance({date}): {balance}.',
  },
  transactionLimit: {
    id: `${scope}.transactionLimit`,
    defaultMessage: 'One-time limit: {amount}.',
  },
  warningThisCategoryNotAssigned: {
    id: `${scope}.warningThisCategoryNotAssigned`,
    defaultMessage:
      'This category is not assigned to the applicant after submission',
  },
  warningCategoryNotAssigned: {
    id: `${scope}.warningCategoryNotAssigned`,
    defaultMessage:
      '1 claim category is not assigned to the applicant after submission',
  },
  warningCategoriesNotAssigned: {
    id: `${scope}.warningCategoriesNotAssigned`,
    defaultMessage:
      '{count} claim categories is not assigned to the applicant after submission',
  },
  categoryNotAssignedInfo: {
    id: `${scope}.categoryNotAssignedInfo`,
    defaultMessage:
      'This claim category is not currently assigned to the applicant',
  },
  duplicatedEntryInfo: {
    id: `${scope}.duplicatedEntryInfo`,
    defaultMessage:
      'Repeated entry: {count} entries of the same detailed info is found in Claim Report: {report}',
  },
  currentReport: {
    id: `${scope}.currentReport`,
    defaultMessage: 'current report',
  },
  addedByFinance: {
    id: `${scope}.addedByFinance`,
    defaultMessage: 'Added by Finance',
  },
  editedByFinance: {
    id: `${scope}.editedByFinance`,
    defaultMessage: 'Edited by Finance',
  },
  upload: {
    id: `${scope}.upload`,
    defaultMessage: 'Upload',
  },
  reportHistoryModalTitle: {
    id: `${scope}.reportHistoryModalTitle`,
    defaultMessage: 'Report Change History',
  },
  defaultVersion: {
    id: `${scope}.defaultVersion`,
    defaultMessage: 'Submitted vs. Current',
  },
  editedAt: {
    id: `${scope}.editedAt`,
    defaultMessage: 'Edited at {time}',
  },
  editedBy: {
    id: `${scope}.editedBy`,
    defaultMessage: 'Edited By',
  },
  activity: {
    id: `${scope}.activity`,
    defaultMessage: 'Activity',
  },
  fields: {
    id: `${scope}.fields`,
    defaultMessage: 'Fields',
  },
  changeFrom: {
    id: `${scope}.changeFrom`,
    defaultMessage: 'Change From',
  },
  changeTo: {
    id: `${scope}.changeTo`,
    defaultMessage: 'Change To',
  },
  editEntry: {
    id: `${scope}.editEntry`,
    defaultMessage: 'Edit Entry',
  },
  removeEntry: {
    id: `${scope}.removeEntry`,
    defaultMessage: 'Remove Entry',
  },
  alert: {
    id: `${scope}.alert`,
    defaultMessage: 'Alert',
  },
  reportWithdrawnErrorMessage: {
    id: `${scope}.reportWithdrawnErrorMessage`,
    defaultMessage:
      'Operation failed. This report has been withdrawn by applicant and is no longer available.',
  },
  ok: {
    id: `${scope}.ok`,
    defaultMessage: 'Ok',
  },
  details: {
    id: `${scope}.details`,
    defaultMessage: 'Details',
  },
  approvalHistory: {
    id: `${scope}.approvalHistory`,
    defaultMessage: 'Approval History',
  },
  firstLevel: {
    id: `${scope}.firstLevel`,
    defaultMessage: 'Direct Reporting Manager',
  },
  secondLevel: {
    id: `${scope}.secondLevel`,
    defaultMessage: '2nd Level Reporting Manager',
  },
  thirdLevel: {
    id: `${scope}.thirdLevel`,
    defaultMessage: '3rd Level Reporting Manager',
  },
  topLevel: {
    id: `${scope}.topLevel`,
    defaultMessage: 'Top Reporting Manager',
  },
  applicant: {
    id: `${scope}.applicant`,
    defaultMessage: 'Applicant',
  },
  departmentHead: {
    id: `${scope}.departmentHead`,
    defaultMessage: 'Department Head',
  },
  reportingLine: {
    id: `${scope}.reportingLine`,
    defaultMessage: 'Reporting Line',
  },
  reportingManager: {
    id: `${scope}.reportingManager`,
    defaultMessage: 'Reporting Manager',
  },
  specificPerson: {
    id: `${scope}.specificPerson`,
    defaultMessage: 'Specific Person',
  },
  finance: {
    id: `${scope}.finance`,
    defaultMessage: 'Finance',
  },
  remarks: {
    id: `${scope}.remarks`,
    defaultMessage: 'Remarks: {reason}',
  },
  countersign: {
    id: `${scope}.countersign`,
    defaultMessage: 'Countersign',
  },
  preempt: {
    id: `${scope}.preempt`,
    defaultMessage: 'Pre-empt',
  },
  pending: {
    id: `${scope}.pending`,
    defaultMessage: 'Pending',
  },
  submitted: {
    id: `${scope}.submitted`,
    defaultMessage: 'Submitted',
  },
  approved: {
    id: `${scope}.approved`,
    defaultMessage: 'Approved',
  },
  approvedBy: {
    id: `${scope}.approvedBy`,
    defaultMessage: 'Approved by {name}',
  },
  rejected: {
    id: `${scope}.rejected`,
    defaultMessage: 'Rejected',
  },
  rejectedBy: {
    id: `${scope}.rejectedBy`,
    defaultMessage: 'Rejected by {name}',
  },
  partiallyApproved: {
    id: `${scope}.partiallyApproved`,
    defaultMessage: 'Partially Approved',
  },
  autoApproved: {
    id: `${scope}.autoApproved`,
    defaultMessage: 'Auto Approved',
  },
  financeEdit: {
    id: `${scope}.financeEdit`,
    defaultMessage: 'Finance Edit: {numberOfEntries} entries >',
  },
  repeatedApprover: {
    id: `${scope}.repeatedApprover`,
    defaultMessage: 'Approver repeated from the previous step',
  },
  approverSameAsApplicant: {
    id: `${scope}.approverSameAsApplicant`,
    defaultMessage: 'Approver is the same as applicant',
  },
  reportingManagerNotAssigned: {
    id: `${scope}.reportingManagerNotAssigned`,
    defaultMessage: 'Not assigned',
  },
  departmentHeadIsNotAssigned: {
    id: `${scope}.departmentHeadIsNotAssigned`,
    defaultMessage: 'Not assigned',
  },
  approverOffBoarding: {
    id: `${scope}.approverOffBoarding`,
    defaultMessage: 'Approver is off boarding',
  },
  totalAmountChanged: {
    id: `${scope}.totalAmountChanged`,
    defaultMessage:
      'Total amount changed from {currency} {prev} to {currency} {current}',
  },
  errorEmptyReport: {
    id: `${scope}.errorEmptyReport`,
    defaultMessage: 'The claim report must contain at least one entry.',
  },
  notice: {
    id: `${scope}.notice`,
    defaultMessage: 'Notice',
  },
  systemValue: {
    id: `${scope}.systemValue`,
    defaultMessage: 'System value: {exchangeRate}',
  },
  system: {
    id: `${scope}.system`,
    defaultMessage: 'System',
  },
});
