import Button from '@seaweb/coral/components/Button';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { THEME } from 'colorTheme';

import { useUpdateTooltip } from './utils';

const ContentWrapper = styled.div`
  z-index: 102;
  position: fixed;
`;

const StyledButton = styled(Button)`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

const StyledBox = styled.div`
  position: absolute;
  padding: 10px 16px;
  background: rgba(32, 35, 37, 0.9);
  border-radius: 4px;
  width: max-content;
  max-width: 280px;

  ${({ placement }) => {
    switch (placement) {
      case ClickContentPlacement.LeftBottom:
        return css`
          right: 24px;
          top: 24px;
        `;
      case ClickContentPlacement.LeftTop:
        return css`
          right: 24px;
          bottom: 24px;
        `;
      case ClickContentPlacement.RightBottom:
        return css`
          left: 24px;
          top: 24px;
        `;
      case ClickContentPlacement.RightTop:
        return css`
          left: 24px;
          bottom: 24px;
        `;
      default:
        return null;
    }
  }}
`;

const Circle = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: ${THEME.colorClick};
  border-radius: 50%;

  :before,
  :after {
    background-color: ${THEME.colorClick};
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -8px;
    margin-top: -8px;
    border-radius: 50%;
    animation: warn 1s ease-out 0s infinite;
  }

  @keyframes warn {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }

  ${({ placement }) => {
    switch (placement) {
      case ClickContentPlacement.LeftBottom:
        return css`
          left: -8px;
          bottom: -8px;
        `;
      case ClickContentPlacement.LeftTop:
        return css`
          left: -8px;
          top: -8px;
        `;
      case ClickContentPlacement.RightBottom:
        return css`
          right: -8px;
          bottom: -8px;
        `;
      case ClickContentPlacement.RightTop:
        return css`
          right: -8px;
          top: -8px;
        `;
      default:
        return null;
    }
  }}
`;

const Body = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: white;
  white-space: pre-wrap;
`;

export const ClickContentPlacement = {
  LeftBottom: 'left-bottom',
  LeftTop: 'left-top',
  RightBottom: 'right-bottom',
  RightTop: 'right-top',
};

function ClickContent({
  content,
  placement = ClickContentPlacement.RightBottom,
  selector,
  centerStyle,
  contentStyle,
  onClick,
  immediate,
}) {
  const clickRef = useUpdateTooltip(selector, immediate, placement);

  return (
    <ContentWrapper ref={clickRef}>
      <Circle placement={placement} style={centerStyle}>
        <StyledBox
          id="tooltip-content"
          placement={placement}
          style={contentStyle}
        >
          <Body>{content}</Body>
        </StyledBox>
      </Circle>
      <StyledButton onClick={onClick} />
    </ContentWrapper>
  );
}

ClickContent.propTypes = {
  content: PropTypes.node.isRequired,
  placement: PropTypes.string,
  selector: PropTypes.string.isRequired,
  centerStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  onClick: PropTypes.func,
  immediate: PropTypes.bool,
};

export default ClickContent;
