/* Antd Icon: https://ant.design/components/icon/ */
/* Notice: svg file get from designer might need some edit */

import { Icon as AntdIcon } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import svgs from './svgs';

import { STYLE } from '../constants';

const size12Group = [
  'add_small',
  'arrow_left',
  'arrow_right',
  'blacklist',
  'cancel_small',
  'dropdown',
  'filter_left',
  'filter_right',
  'info_nofill',
  'seen',
  'user',
  'whitelist',
];

const size14Group = ['tips', 'info'];

const size16Group = ['footerseatalk'];

const size40Group = ['alert', 'error', 'success'];

const size48Group = [
  'claim',
  'department',
  'add_employee',
  'invite',
  'company',
  'import',
  'configure',
  'leave_attendance',
  'public_account',
  'check_in',
  'app_center',
  'admin_list',
  'role',
];

const StyledIcon = styled(AntdIcon)`
  && {
    color: ${(props) => props.color || STYLE.colorIcon};
    font-size: ${(props) => {
      if (props.size) return `${props.size}px`;
      if (size12Group.includes(props.name)) return '12px';
      if (size14Group.includes(props.name)) return '14px';
      if (size16Group.includes(props.name)) return '16px';
      if (size40Group.includes(props.name)) return '40px';
      if (size48Group.includes(props.name)) return '48px';
      if (props.name.includes('large')) return '76px';
      if (props.name === 'video') return '28px';
      if (props.name === 'camera') return '30px';
      if (props.name === 'whitelist_big') return '34px';
      return '20px';
    }};
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
`;

function Icon(props) {
  return <StyledIcon component={svgs[props.name]} {...props} />;
}

Icon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.number,
};

export default Icon;
