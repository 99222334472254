import { THEME } from 'colorTheme';

export const STYLE = {
  ...THEME,
  styleBorder: `1px solid ${THEME.colorBorder}`,
  styleTableBorder: `1px solid ${THEME.colorTableBorder}`,
  subNavWidth: 260,
  subNavLayoutStyle: {
    position: 'absolute',
    height: 'calc(100vh - 114px)',
    borderRight: `1px solid ${THEME.colorBorder}`,
    backgroundColor: THEME.colorTableBg,
    overflowY: 'auto',
    zIndex: 3,
  } as React.CSSProperties,
};

export const MIXIN = {
  setBorderRadius: 'border-radius: 2px;',
};
