import Select, {
  SelectButton,
  SelectList,
  SelectOption,
} from '@seaweb/coral/components/Select';
import GlobeIcon from '@seaweb/coral/icons/Globe';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { STYLE } from 'components/Common/constants';
import { makeSelectLanguageList } from 'containers/App/selectors';

const StyledSelectButton = styled(SelectButton)`
  width: fit-content;
  height: 28px;
  margin-left: 20px;
  background-color: ${STYLE.colorNavBg};
  border-color: transparent;
  padding: 4px 28px 4px 8px;

  [data-coral-select-button-arrow-wrapper] {
    margin: 0 8px 0 4px;
  }

  & > div {
    color: #fff;
  }

  &:hover {
    background-color: #00000026;
    border-color: transparent;
    box-shadow: none;
  }
  &:focus {
    background-color: #0000004c;
    border-color: transparent;
    box-shadow: none;
  }
`;

const StyledSelectOption = styled(SelectOption)`
  min-width: fit-content;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  svg {
    display: none;
  }
`;

function SelectLanguage() {
  const languages = useSelector(makeSelectLanguageList());

  const currentLanguage = useMemo(() => {
    const locale = localStorage.getItem('locale');

    if (locale === 'zh') return 'zh-Hans';
    if (locale === 'yue') return 'zh-Hant';
    return locale;
  }, []);

  const setLanguage = (lang) => {
    let locale;
    switch (lang) {
      case 'zh-Hans':
        locale = 'zh';
        break;
      case 'zh-Hant':
        locale = 'yue';
        break;

      default:
        locale = lang;
        break;
    }
    localStorage.setItem('locale', locale);
    window.location.reload();
  };

  return (
    <Select initialValue={currentLanguage} onChange={setLanguage}>
      <StyledSelectButton />
      {languages && (
        <SelectList width={200} placement="bottom-start">
          {languages.map((lang) => (
            <StyledSelectOption key={lang.id} value={lang.code}>
              <GlobeIcon
                color="white"
                style={{ marginRight: 4, verticalAlign: 'top' }}
              />
              {lang.name}
            </StyledSelectOption>
          ))}
        </SelectList>
      )}
    </Select>
  );
}

export default SelectLanguage;
