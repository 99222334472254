import humps from 'humps';

import store from 'configureStore';
import { SETTING, GENERAL_ERROR } from 'consts';

import history from './history';
import { LOGIN_COOKIE } from './storage';

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 500) {
    return response;
  }

  const error = new Error(response.statusText);
  // @ts-ignore
  error.response = response;
  throw error;
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  const isJsonReturn =
    response.headers.get('Content-Type').indexOf('application/json') > -1;
  return isJsonReturn ? response.json() : response;
}

export class ResponseError extends Error {
  code: number;

  message: string;

  originalResponse: any;

  constructor(code: number, message: string, originalResponse: any) {
    super();
    this.code = code;
    this.message = message;
    this.originalResponse = originalResponse;
  }
}

export function isResponseError(err: unknown): err is ResponseError {
  return err instanceof ResponseError;
}

/**
 * Check if JSON returned has an error
 *
 * @param  {object} response A response from a network request
 *
 * @return {object|undefined} The parsed JSON from the request, or throws an error
 */
function checkJsonError(response) {
  if (response.error) {
    if (
      response.error === GENERAL_ERROR.UNAUTHENTICATED &&
      history.location.pathname !== '/'
    ) {
      document.cookie = `${LOGIN_COOKIE}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      history.push('/');
    }

    const error = new ResponseError(
      response.error,
      response.error_message,
      response
    );
    throw error;
  }
  return response;
}

/**
 * Convert response from snake case to camel case
 *
 * @param  {object} response A response from a network request
 *
 * @return {object} The parsed JSON from the request
 */
export function camelizeKeys(response) {
  return humps.camelizeKeys(response);
}

/**
 * Ensure order is stable, so it can be used for useEffect.
 */
export function joinParams(params) {
  return Object.keys(params)
    .sort()
    .map(
      (k) =>
        `${encodeURIComponent(humps.decamelize(k))}=${encodeURIComponent(
          params[k]
        )}`
    )
    .join('&');
}

/**
 * Replace '?&' to '?' and cleanup multiple '?'.
 *
 * For example: 'abc?def?&ghij?&klm?abc' => 'abc?def&ghij&klm&abc'
 *
 * @param {string} url
 */
function cleanParams(url) {
  let once = false;
  return url
    .replace(/\?&/g, '?')
    .split('')
    .map((char) => {
      if (char !== '?') return char;
      if (once) return '&';
      once = true;
      return '?';
    })
    .join('');
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */

export default function request(url, method, queryParams) {
  const cid = store.getState().getIn(['global', 'organizationCode']);

  let data = queryParams;
  let param = '';
  const options = {
    method,
    credentials: 'include',
  };

  if (data && typeof data === 'object') {
    data = { ...data };
    Object.keys(data).forEach(
      (key) =>
        (data[key] === undefined || data[key] === null) &&
        // eslint-disable-next-line no-param-reassign
        delete data[key]
    );
  }

  if (method) {
    const methodUpperCase = method.toUpperCase();
    if (['POST', 'DELETE', 'PUT'].includes(methodUpperCase)) {
      // @ts-ignore
      options.headers = {
        'Content-type': 'application/json',
      };
      // @ts-ignore
      options.body = JSON.stringify(humps.decamelizeKeys(data));
    } else if (methodUpperCase === 'GET') {
      // @ts-ignore
      options.body = null;
      Object.keys(data).forEach(
        (key) =>
          data[key] === '' &&
          // eslint-disable-next-line no-param-reassign
          delete data[key]
      );
      const parsedData = humps.decamelizeKeys(data);
      param = joinParams(parsedData);
    }
  }

  const urlQuery = cleanParams(
    `${url}?language=${localStorage.getItem('locale') || 'en'}${
      param && `&${param}`
    }${
      param?.startsWith('organization_code=') ||
      param?.includes('&organization_code=')
        ? ''
        : `&organization_code=${cid}`
    }`
  );
  // @ts-ignore
  return fetch(`${SETTING.apiUrlPrefix}${urlQuery}`, options)
    .then(checkStatus)
    .then(parseJSON)
    .then(checkJsonError);
}

export function requestCamel<T = any>(url, method, data = {}) {
  return request(url, method, data).then(camelizeKeys) as Promise<T>;
}
