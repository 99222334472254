import useEventCallback from '@seaweb/coral/hooks/useEventCallback';
import { useState, useRef } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { useCacheContext } from 'components/CacheProvider';

import { useBaseSafeRequest } from './useSafeRequest';

const Cancelled = Symbol('cancelled');

export default function useGetCache(url, params = {}, { skip } = {}) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const request = useBaseSafeRequest();
  const { getCache, clearCache } = useCacheContext();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const cancelOngoingRef = useRef(() => {});

  const get = useEventCallback(async () => {
    if (!skip) {
      cancelOngoingRef.current();
      try {
        const res = await Promise.race([
          getCache(url, params, () => request(url, 'GET', params)),
          new Promise((resolve, reject) => {
            cancelOngoingRef.current = () => reject(Cancelled);
          }),
        ]);
        setResponse(res);
      } catch (e) {
        if (e !== Cancelled) {
          setError(e);
        }
      }
    }
  });

  useDeepCompareEffect(() => {
    get();
  }, [params, url]);

  const refresh = useEventCallback(() => {
    clearCache(url);
    get();
  });

  return [response, refresh, error];
}
