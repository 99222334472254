import { useLayoutEffect, useRef } from 'react';

export function checkElementsOverlap(selector1, selector2) {
  const element1 = document.querySelector(selector1);
  const element2 = document.querySelector(selector2);
  if (element1 && element2) {
    const rect1 = element1.getBoundingClientRect();
    const rect2 = element2.getBoundingClientRect();
    if (
      rect1.right >= rect2.left &&
      rect1.left <= rect2.right &&
      rect1.bottom >= rect2.top &&
      rect1.top <= rect2.bottom
    ) {
      return true;
    }
  }
  return false;
}

/**
 * Scroll event listener with callback
 */
const customScrollIntoView = (container, component, immediate, callback) => {
  component.scrollIntoView({
    behavior: immediate ? 'auto' : 'smooth',
    block: 'center',
  });
  if (immediate) {
    callback();
  } else {
    // Custom onscrollend event
    let timer = setTimeout(callback, 100);
    container.addEventListener('scroll', () => {
      clearTimeout(timer);
      timer = setTimeout(callback, 100);
    });
  }
};

export const useUpdateTooltip = (selector, immediate, placement) => {
  const clickRef = useRef();

  useLayoutEffect(() => {
    const container = document.querySelector("[class*='Company__Content']");
    const tooltipContent = document.querySelector('#tooltip-content');

    // Update dialog tutorial position
    const handleTooltipPos = () => {
      const targetComponent = document.querySelector(selector);

      if (!targetComponent) return;
      if (!clickRef.current?.style) return;

      // Calculate positions
      const elementRect = targetComponent.getBoundingClientRect();
      const containerTop = container.scrollTop;
      const dialogOffset = placement.includes('top')
        ? 0
        : tooltipContent.clientHeight; // Include dialog height if needed
      const containerBottom = containerTop + window.innerHeight - dialogOffset;
      const elementTop = containerTop + elementRect.top;
      const elementBottom = elementTop + elementRect.height;

      // If target component (include dialog) is out of screen
      if (elementTop < containerTop || elementBottom > containerBottom) {
        customScrollIntoView(container, targetComponent, immediate, () => {
          // Update position again after scrolling
          if (clickRef.current?.style) {
            clickRef.current.style.top = `${
              targetComponent.getBoundingClientRect().top
            }px`;
          }
        });
      } else {
        // Will update top position after scrolling later
        clickRef.current.style.top = `${elementRect.top}px`;
      }

      clickRef.current.style.left = `${elementRect.left}px`;
      clickRef.current.style.width = `${elementRect.width}px`;
      clickRef.current.style.height = `${elementRect.height}px`;

      document.activeElement.blur();
    };

    const handleMutation = () => {
      handleTooltipPos();
      const imgs = document.getElementsByTagName('img');
      Array.prototype.forEach.call(imgs, (img) => {
        img.addEventListener('load', handleTooltipPos, false);
      });
    };
    const observer = new MutationObserver(handleMutation);

    handleMutation();
    observer.observe(document, {
      attributes: true,
      childList: true,
      characterData: true,
      subtree: true,
    });
    window.addEventListener('resize', handleTooltipPos);
    window.addEventListener('scroll', handleTooltipPos);

    return () => {
      observer.disconnect();
      window.removeEventListener('resize', handleMutation);
      window.removeEventListener('scroll', handleMutation);
    };
  });

  return clickRef;
};
