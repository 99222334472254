/* eslint-disable no-console */
const isNode =
  typeof process !== 'undefined' &&
  process.versions != null &&
  process.versions.node != null;

const DEFAULT_LOCALE = 'en';
const LANGUAGE_KEY_NAME = 'lang';
const SUPPORTED_LANGUAGE_CODES = ['en', 'id', 'vi', 'th', 'zh'];

if (!isNode) {
  try {
    // const locale = new URL(window.location.href).searchParams.get('lang');
    const cookieList = document.cookie.split('; ');

    let locale;

    // If language cookie existed (either from official website or previous visit)
    if (cookieList && cookieList.length) {
      const langCookie = cookieList.find((cookie) =>
        cookie.startsWith(`${LANGUAGE_KEY_NAME}=`)
      );
      if (langCookie) {
        const [, langCode] = langCookie.split('=');

        switch (langCode) {
          case 'zh-tw':
            locale = 'yue';
            break;
          case 'zh-cn':
            locale = 'zh';
            break;

          default:
            locale = langCode;
            break;
        }
        if (window.location.host.startsWith('oa.')) {
          const cookieDomain = window.location.host.slice(2);
          document.cookie = `lang=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${cookieDomain}`;
        } else {
          document.cookie =
            'lang=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        }

        console.log('Locale retrieved from cookie: ', locale);
      }

      // If cookie does not exist, check user browser's language
      if (!langCookie) {
        const localeInLocalStorage = localStorage.getItem('locale');
        if (localeInLocalStorage) {
          locale = localeInLocalStorage;
          console.log('Locale retrieved from local storage: ', locale);
        } else {
          const browserLang = navigator.language || window.navigator.language;
          if (browserLang) {
            const [lang, region] = browserLang.split('-');

            if (SUPPORTED_LANGUAGE_CODES.includes(lang)) {
              // Check if language is Chinese
              if (lang === 'zh') {
                // Check if should display Traditional Chinese or Simplified Chinese
                if (['hk', 'tw'].includes(region)) {
                  locale = 'yue';
                } else {
                  locale = 'zh';
                }
              } else {
                locale = lang;
              }
              console.log('Locale retrieved from browser language: ', locale);
            } else {
              // If browser language not supported, use host's region suffix (.io, .sg, .tw, etc..)
              const suffix = window.location.host.split('.').pop();

              switch (suffix) {
                case 'tw':
                  locale = 'yue';
                  break;
                case 'id':
                  locale = 'id';
                  break;
                case 'vn':
                  locale = 'vi';
                  break;

                // Fallback to English for other cases not covered
                default:
                  locale = DEFAULT_LOCALE;
                  break;
              }

              console.log('Locale retrieved from region suffix: ', locale);
            }
          }
        }
      }
    }

    localStorage.setItem('locale', locale);
  } catch (err) {
    console.log('Failed to get language settings: ', err);
  }
}

const appLocales = ['en', 'hans', 'hant', 'id', 'th', 'vi'];

const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, messages)
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

export { appLocales, formatTranslationMessages, DEFAULT_LOCALE };
